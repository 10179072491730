import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { curry } from '@divvy-web/utils'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { FormContext } from '@divvy-web/skylab.form'
import Icon from '@divvy-web/skylab.icon'
import Modal from '@divvy-web/skylab.modal'
import Spinner from '@divvy-web/skylab.spinner'
import { css } from '@emotion/core'
import { func, number } from 'prop-types'
import { useParams } from 'react-router-dom'
import useRemainingCorrections from '../../hooks/useRemainingCorrections'
import { useUpdateCompanyOwners } from '../../hooks/useUpdateCompanyOwners'
import { CreditApplicationStatus } from '../../resources/constants'
import { logInfo } from '../../utils/loggerUtils'
import { getEmptyCompanyOwner } from '../../utils/dataUtils'
import { formStyles } from '../styles/applicationPageSharedStyles'
import { regularPages } from '../FormPages/pages'
import { RawCreditAppContext } from '../../utils/RawCreditAppContext'
import { isAuthorizedSignerComplete } from '../../utils/completionCheckUtils'
import CompanyOwnerForm from './CompanyOwnerForm'
import OwnerCard from './OwnerCard'

const CompanyOwnersList = ({ selectedOwnerIndex, setSelectedOwner }) => {
  const { appId } = useParams()
  const rawCreditApp = useContext(RawCreditAppContext)
  const isAuthSignerComplete = useMemo(() => isAuthorizedSignerComplete(rawCreditApp), [rawCreditApp])
  const { loading: updatingCompanyOwners, updateCompanyOwners } = useUpdateCompanyOwners()
  const { getFormValue, resetForm, setFormValue } = useContext(FormContext)
  const [nextAvailableOwnerIndex, setNextAvailableOwnerIndex] = useState(null)
  const [confirmingDelete, setConfirmingDelete] = useState(null)
  const isAuthorizedSignerAnOwner = getFormValue('isAuthorizedSignerAnOwner')
  const [toggledAuthSigner, setToggledAuthSigner] = useState(false)
  const [resettingForm, setResettingForm] = useState(false)
  const isMobileView = window?.innerWidth && window.innerWidth < 450
  const correctionSection = regularPages().find((page) => page.name === 'companyOwners').correctionSection
  const requestingCorrection = getFormValue('status') === CreditApplicationStatus.CORRECTION_REQUESTED
  const { remainingCorrections, removeFromRemainingCorrections } = useRemainingCorrections(
    appId,
    getFormValue('sectionsToCorrect'),
    requestingCorrection,
  )

  const canToggleAuthSignerOwner =
    (isAuthSignerComplete && !isAuthorizedSignerAnOwner && nextAvailableOwnerIndex !== null) ||
    isAuthorizedSignerAnOwner

  const isEditing = selectedOwnerIndex !== null
  const bo0Created = getFormValue('owner0_isCreated')
  const bo1Created = getFormValue('owner1_isCreated')
  const bo2Created = getFormValue('owner2_isCreated')
  const bo3Created = getFormValue('owner3_isCreated')

  useEffect(() => {
    const ownersCreated = [bo0Created, bo1Created, bo2Created, bo3Created]
    const index = ownersCreated.indexOf(false)
    const nextIndex = index < 0 ? null : index

    setNextAvailableOwnerIndex(nextIndex)
  }, [bo0Created, bo1Created, bo2Created, bo3Created])

  useEffect(() => {
    if (resettingForm) {
      resetForm()
      setResettingForm(false)
    }
  }, [resettingForm, resetForm])

  useEffect(() => {
    setToggledAuthSigner(false)

    if (toggledAuthSigner) {
      updateCompanyOwners().then(() => {
        if (requestingCorrection && remainingCorrections.includes(correctionSection)) {
          removeFromRemainingCorrections(correctionSection)
        }

        logInfo({
          attributes: {
            action: 'Success',
            message: `Credit App ID: ${appId}`,
          },
          eventName: 'UpdateCompanyOwners',
        })
      })
    }
  }, [
    appId,
    toggledAuthSigner,
    updateCompanyOwners,
    remainingCorrections,
    removeFromRemainingCorrections,
    requestingCorrection,
    correctionSection,
  ])

  const handleOwnerDelete = (index) => {
    setFormValue(`owner${index}_isCreated`, false)
    setConfirmingDelete(index)
  }

  const handleOwnerDeleteCancel = (index) => {
    setFormValue(`owner${index}_isCreated`, true)
    setConfirmingDelete(null)
  }

  const handleOwnerDeleteConfirm = () => {
    updateCompanyOwners().then(() => {
      if (requestingCorrection && remainingCorrections.includes(correctionSection)) {
        removeFromRemainingCorrections(correctionSection)
      }

      logInfo({
        attributes: {
          action: 'Success',
          message: `Credit App ID: ${appId}`,
          result: 'Deleted company owner',
        },
        eventName: 'UpdateCompanyOwners',
      })
      setResettingForm(true)
    })
    setConfirmingDelete(null)
  }

  const getOwnerName = (ownerIdx) => {
    const firstName = getFormValue(`owner${confirmingDelete}_firstName`)
    const lastName = getFormValue(`owner${confirmingDelete}_lastName`)
    const fullName = [firstName, lastName].join(' ')

    return fullName !== ' ' ? fullName : `Owner ${ownerIdx + 1}`
  }

  const toggleAuthSignerAsOwner = (_) => {
    if (!canToggleAuthSignerOwner) return

    if (isAuthorizedSignerAnOwner) {
      let authSignerOwnerIdx = [0, 1, 2, 3].find((i) => getFormValue(`owner${i}_isAuthorizedSigner`))
      setFormValue(`owner${authSignerOwnerIdx}_isCreated`, false)
      setFormValue(`owner${authSignerOwnerIdx}_isAuthorizedSigner`, false)
      setFormValue('isAuthorizedSignerAnOwner', false)
      setToggledAuthSigner(true)
      return
    }

    const newOwnerPrefix = `owner${nextAvailableOwnerIndex}_`
    const newOwner = getEmptyCompanyOwner()

    for (const key of Reflect.ownKeys(newOwner)) {
      setFormValue(`${newOwnerPrefix}${key}`, getFormValue(key))
    }

    setFormValue(`${newOwnerPrefix}isCreated`, true)
    setFormValue(`${newOwnerPrefix}isAuthorizedSigner`, true)
    setFormValue('isAuthorizedSignerAnOwner', true)
    setToggledAuthSigner(true)
  }

  const modalActionButtons = [
    <BasicButton
      key='logout'
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={() => handleOwnerDeleteCancel(confirmingDelete)}
    >
      <FormattedMessage
        defaultMessage='Cancel'
        id='sputnik.CompanyOwnersList__47FYwb'
      />
    </BasicButton>,
    <BasicButton
      key='remove'
      onClick={handleOwnerDeleteConfirm}
    >
      <FormattedMessage
        defaultMessage='Yes Delete'
        id='sputnik.CompanyOwnersList__qLGwuY'
      />
    </BasicButton>,
  ]

  return (
    <div
      css={(theme) => [
        formStyles({ isReview: false, readOnly: false }, theme),
        companyOwnersListCss({ isMobileView }, theme),
      ]}
    >
      <div className='field-row owners-list-header-section fs-unmask'>
        <div className='owners-header-text'>
          <FormattedMessage
            defaultMessage='Add owners'
            id='sputnik.CompanyOwnersList__YS7FSI'
          />
        </div>
        <div>
          {updatingCompanyOwners ? (
            <Spinner />
          ) : (
            <BasicButton
              dataTestId='add-owner'
              disabled={isEditing || nextAvailableOwnerIndex === null}
              type={BASIC_BUTTON_TYPE_OUTLINED}
              onClick={(_) => setSelectedOwner(nextAvailableOwnerIndex)}
            >
              {!isMobileView ? (
                <FormattedMessage
                  defaultMessage='Add'
                  id='sputnik.CompanyOwnersList__2/2yg+'
                />
              ) : (
                <Icon
                  name='plus'
                  size='regular'
                />
              )}
            </BasicButton>
          )}
        </div>
      </div>
      {updatingCompanyOwners || toggledAuthSigner ? null : isEditing ? (
        <CompanyOwnerForm
          ownerIndex={selectedOwnerIndex}
          setSelectedOwner={setSelectedOwner}
        />
      ) : (
        <>
          {canToggleAuthSignerOwner && !isAuthorizedSignerAnOwner && (
            <div
              key='$company-owner-card-auth-signer'
              className='field-row'
            >
              <div className='field-container-full'>
                <OwnerCard
                  isNonOwnerAuthorizedSigner
                  toggleAuthSignerAsOwner={toggleAuthSignerAsOwner}
                />
              </div>
            </div>
          )}
          {[0, 1, 2, 3].map((ownerIdx) => (
            <div
              key={`$company-owner-card-${ownerIdx}`}
              className='field-row'
            >
              <div className='field-container-full'>
                <OwnerCard
                  deleteOwner={handleOwnerDelete}
                  ownerIdx={ownerIdx}
                  setSelectedOwner={setSelectedOwner}
                  toggleAuthSignerAsOwner={toggleAuthSignerAsOwner}
                />
              </div>
            </div>
          ))}
        </>
      )}
      <Modal
        actions={modalActionButtons}
        isShowing={confirmingDelete !== null}
        subTitle={
          <FormattedMessage
            defaultMessage="You can't undo this action"
            id='sputnik.CompanyOwnersList__3A4UiG'
          />
        }
        title={
          <FormattedMessage
            defaultMessage='Delete company owner?'
            id='sputnik.CompanyOwnersList__Jj+KR9'
          />
        }
      >
        <div>
          <FormattedMessage
            defaultMessage='Are you sure you want to delete {ownerName} as a company owner?'
            id='sputnik.CompanyOwnersList__2MJRbx'
            values={{ ownerName: getOwnerName(confirmingDelete) }}
          />
        </div>
      </Modal>
    </div>
  )
}

CompanyOwnersList.propTypes = {
  selectedOwnerIndex: number,
  setSelectedOwner: func.isRequired,
}

const companyOwnersListCss = curry(
  ({ isMobileView }, { type }) => css`
    user-select: none;

    .owners-list-header-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--tri-space-900);
    }

    .owners-header-text {
      ${type.triFontDesktopBodyMediumEmphasis}
    }

    ${isMobileView &&
    `
  .owners-list-header-section button {
    padding: 0;
  }
  `}
  `,
)

export default CompanyOwnersList
