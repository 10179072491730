import { css } from '@emotion/core'
import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import TextLink from '@divvy-web/skylab.textlink'

const peopleBirdWatching = 'https://app.divvy.co/assets/illustrations/people_bird_watchers2'
const peopleBirdWatchingSrcSet = `${peopleBirdWatching}@2x.png, ${peopleBirdWatching}@1x.png, ${peopleBirdWatching}@3x.png`
const peopleBirdWatchingMobile = 'https://app.divvy.co/assets/illustrations/people-bird-watchers-mobile'
const peopleBirdWatchingMobileSrcSet = `${peopleBirdWatchingMobile}@2x.png, ${peopleBirdWatchingMobile}@1x.png, ${peopleBirdWatchingMobile}@3x.png`

const NotFound = () => {
  return (
    <div css={pageStyle}>
      <div css={mainContent}>
        <img
          alt='binoculars'
          className='not-found-image'
          data-testid='image-404'
          srcSet={peopleBirdWatchingSrcSet}
        />
        <img
          alt='people-with-binoculars'
          className='not-found-mobile-image'
          data-testid='image-404-mobile'
          srcSet={peopleBirdWatchingMobileSrcSet}
        />
        <div
          css={msg}
          data-testid='message-404'
        >
          <FormattedMessage
            defaultMessage="We couldn't find the page page you were looking for."
            id='sputnik.NotFound__MtrRDG'
          />
        </div>
        <TextLink
          css={link}
          data-testid='link-to-dashboard'
          href='/'
        >
          <FormattedMessage
            defaultMessage='Back to Application dashboard'
            id='sputnik.NotFound__MgGyu8'
          />
        </TextLink>
      </div>
    </div>
  )
}

const msg = ({ type }) => css`
  ${type.triFontDesktopTitleSmallEmphasis}
  width: 90%;
  text-align: center;
`

const mainContent = ({ mq }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .not-found-image {
    ${mq.xxSmallMaxWidth({
      display: 'none',
    })}
  }

  .not-found-mobile-image {
    max-width: 247px;
    padding-bottom: 3rem;
    ${mq.xSmallMinWidth({
      display: 'none',
    })}
  }
`

const pageStyle = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const link = css`
  margin-top: var(--tri-space-100);
  text-decoration: none;
`

export default NotFound
