import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import { RE_CAPTCHA_SITE_KEY } from './signUpConstants'
import SignUpView from './SignUpView'

const SignUp = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RE_CAPTCHA_SITE_KEY}
      scriptProps={{ appendTo: 'body', defer: true }}
    >
      <PageViewWrapper pageName='SignUp'>
        <SignUpView />
      </PageViewWrapper>
    </GoogleReCaptchaProvider>
  )
}

export default SignUp
