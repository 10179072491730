import { LOCAL_STORAGE_KEYS } from '../resources/constants'

// clearing localStorage
export const clearAllLocalStorageValues = () => {
  return window.localStorage.clear()
}

// getting localStorage
export const getAuthIdFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICANT_AUTH_ID)
}

export const getEmailFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.APPLICANT_EMAIL_KEY)
}

export const getTokenFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_STORAGE_TOKEN_KEY)
}

export const getSalesforceCreditIdsFromStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.SALESFORCE_CREDIT_IDS)
}
