import React from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import Icon from '@divvy-web/skylab.icon'
import { array, string } from 'prop-types'
import ImageWrapper from '../ImageWrapper'

const ReviewCard = ({ headerText, reviewImageName, signature, stars }) => {
  const makeTestId = useMakeTestId('review-card')
  return (
    <div className='review-wrap'>
      <div
        className='review'
        data-testid={makeTestId('review-content')}
      >
        {stars > 0 && (
          <div className='stars'>
            {[...Array(stars).keys()].map((i) => (
              <Icon
                key={'star-' + i}
                name='starFilled'
              />
            ))}
          </div>
        )}
        <div className='review-header'>{headerText}</div>
        <div className='review-signature'>
          {signature?.map((substring, i) => (
            <div key={'substring' + i}>{substring}</div>
          ))}
        </div>
      </div>
      {reviewImageName && (
        <div
          className='review-image'
          data-testid={makeTestId('image')}
        >
          <ImageWrapper imageName={reviewImageName} />
        </div>
      )}
    </div>
  )
}

ReviewCard.propTypes = {
  headerText: string,
  reviewImageName: string,
  signature: array,
  stars: Number,
}

export default ReviewCard
