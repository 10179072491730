import { useEffect, useState } from 'react'
import { prop } from '@divvy-web/utils'
import { logError, logInfo } from '../utils/loggerUtils'

/*
  Get configuration from LD
*/

const useGetLaunchDarklyConfig = ({ launchDarklyRestToken }) => {
  const [launchDarklyFlags, setLaunchDarklyFlags] = useState([])

  useEffect(() => {
    if (!launchDarklyRestToken) return
    fetch('https://app.launchdarkly.com/api/v2/flags/spend-and-expense', {
      headers: {
        Authorization: launchDarklyRestToken,
        'LD-API-Version': '20220603',
      },
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        const launchDarklyConfigItems = data?.items || []
        const ldFlags = launchDarklyConfigItems.reduce((accum, configItem) => {
          const { archived, clientSideAvailability, deprecated, description, key, variations } = configItem

          if (!clientSideAvailability.usingEnvironmentId || deprecated || archived) return accum
          accum.push({
            description,
            name: key,
            variants: variations?.map(prop('value')),
          })

          return accum
        }, [])

        setLaunchDarklyFlags(ldFlags)

        logInfo({
          attributes: {
            action: 'useLaunchDarklyRest',
            message: 'successfully retrieved Launch Darkly flags',
            result: ldFlags,
          },
          eventName: 'launch-darkly-config',
        })
      })
      .catch((err) => {
        logError({
          attributes: {
            action: 'useLaunchDarklyRest',
            message: err?.message,
            result: 'unable to retrieve Launch Darkly flags',
          },
          eventName: 'launch-darkly-config',
        })
      })
  }, [launchDarklyRestToken])

  return launchDarklyFlags
}

export default useGetLaunchDarklyConfig
