/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as _Types from '_gql'
const defaultOptions = {} as const
export type GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_authorizedSigner_CreditApplicationPerson =
  {
    readonly __typename: 'CreditApplicationPerson'
    readonly id?: string | null
    readonly email?: string | null
    readonly cacheRole?: string | null
  }

export type GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo_bankInfo_BankInfo =
  { readonly __typename: 'BankInfo'; readonly accountNumberLastFour?: string | null }

export type GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo =
  {
    readonly __typename: 'CreditApplicationFinanceInfo'
    readonly bankInfo?: GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo_bankInfo_BankInfo | null
  }

export type GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta =
  {
    readonly __typename: 'SignableCreditApplicationDocumentMeta'
    readonly signedAt?: any | null
    readonly underwritingDecisionUuid?: string | null
    readonly underwritingOfferType?: _Types.UnderwritingOffer | null
  }

export type GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument =
  {
    readonly __typename: 'SignableCreditApplicationDocument'
    readonly type: _Types.SignableCreditApplicationDocumentType
    readonly content: string
    readonly meta: GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta
  }

export type GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication = {
  readonly __typename: 'CreditApplication'
  readonly salesforceCreditId: string
  readonly dsaVersion?: string | null
  readonly recordType?: string | null
  readonly appVersion?: number | null
  readonly authorizedSigner?: GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_authorizedSigner_CreditApplicationPerson | null
  readonly financeInfo?: GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo | null
  readonly tila?: GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument | null
}

export type GetCreditApplicationTilaInfoQuery_Query = {
  readonly __typename: 'Query'
  readonly creditApplication?: GetCreditApplicationTilaInfoQuery_creditApplication_CreditApplication | null
}

export type GetCreditApplicationTilaInfoQueryVariables = _Types.Exact<{
  creditApplicationId: _Types.Scalars['ID']['input']
  underwritingOfferType: _Types.UnderwritingOffer
  underwritingDecisionUuid: _Types.Scalars['ID']['input']
}>

export type GetCreditApplicationTilaInfoQuery = GetCreditApplicationTilaInfoQuery_Query

export const GetCreditApplicationTilaInfoDocument = gql`
  query GetCreditApplicationTilaInfo(
    $creditApplicationId: ID!
    $underwritingOfferType: UnderwritingOffer!
    $underwritingDecisionUuid: ID!
  ) {
    creditApplication(creditApplicationId: $creditApplicationId) {
      salesforceCreditId
      authorizedSigner {
        id
        email
        cacheRole
      }
      dsaVersion
      recordType
      appVersion
      financeInfo {
        bankInfo {
          accountNumberLastFour
        }
      }
      tila(underwritingOfferType: $underwritingOfferType, underwritingDecisionUuid: $underwritingDecisionUuid) {
        type
        content
        meta {
          signedAt
          underwritingDecisionUuid
          underwritingOfferType
        }
      }
    }
  }
`

/**
 * __useGetCreditApplicationTilaInfoQuery__
 *
 * To run a query within a React component, call `useGetCreditApplicationTilaInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditApplicationTilaInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditApplicationTilaInfoQuery({
 *   variables: {
 *      creditApplicationId: // value for 'creditApplicationId'
 *      underwritingOfferType: // value for 'underwritingOfferType'
 *      underwritingDecisionUuid: // value for 'underwritingDecisionUuid'
 *   },
 * });
 */
export function useGetCreditApplicationTilaInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetCreditApplicationTilaInfoQuery, GetCreditApplicationTilaInfoQueryVariables> &
    ({ variables: GetCreditApplicationTilaInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCreditApplicationTilaInfoQuery, GetCreditApplicationTilaInfoQueryVariables>(
    GetCreditApplicationTilaInfoDocument,
    options,
  )
}
export function useGetCreditApplicationTilaInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreditApplicationTilaInfoQuery,
    GetCreditApplicationTilaInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCreditApplicationTilaInfoQuery, GetCreditApplicationTilaInfoQueryVariables>(
    GetCreditApplicationTilaInfoDocument,
    options,
  )
}
export function useGetCreditApplicationTilaInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCreditApplicationTilaInfoQuery,
    GetCreditApplicationTilaInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCreditApplicationTilaInfoQuery, GetCreditApplicationTilaInfoQueryVariables>(
    GetCreditApplicationTilaInfoDocument,
    options,
  )
}
export type GetCreditApplicationTilaInfoQueryHookResult = ReturnType<typeof useGetCreditApplicationTilaInfoQuery>
export type GetCreditApplicationTilaInfoLazyQueryHookResult = ReturnType<
  typeof useGetCreditApplicationTilaInfoLazyQuery
>
export type GetCreditApplicationTilaInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetCreditApplicationTilaInfoSuspenseQuery
>
export type GetCreditApplicationTilaInfoQueryResult = Apollo.QueryResult<
  GetCreditApplicationTilaInfoQuery,
  GetCreditApplicationTilaInfoQueryVariables
>
