import React, { useState } from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Drawer, { DrawerView } from '@divvy-web/skylab.drawer'
import Scrim from '@divvy-web/skylab.scrim'
import { css } from '@emotion/core'
import { arrayOf, bool, func, object, shape, string } from 'prop-types'
import { useCanary } from '@divvy-web/canary'
import { useAuth } from '../../../auth'
import { getMarketoValues } from '../../../marketo'
import { removeNonNumericCharacters } from '../../../utils/dataUtils'
import { logError, logInfo } from '../../../utils/loggerUtils'
import ExistingAccountDrawer from './ExistingAccountDrawer'

const ExistingAccountDrawerContainer = ({
  accounts,
  contactId,
  formValues,
  isShowingDrawer,
  onComplete,
  verifyReCaptcha,
}) => {
  const makeTestId = useMakeTestId('ExistingAccountDrawerContainer')
  const [selectedAccount, setSelectedAccount] = useState('')
  const [isResumingApp, setIsResumingApp] = useState(false)
  const [isStartingNewApp, setIsStartingNewApp] = useState(false)
  const { initCreditApplicationFromExistingAccountsContext } = useAuth()
  const shouldShowStateBasedDisclosures = useCanary('state-based-disclosures')

  const billMarketoLeadParams = { marketoBillLeadInput: getMarketoValues() }

  const leadParams = billMarketoLeadParams?.marketoBillLeadInput
  const pact = leadParams?.pact
  const affiliateIdString = leadParams?.ltAffiliateId || leadParams?.ltAffiliateId
  const affiliateId = (affiliateIdString && Number.parseInt(affiliateIdString)) || null
  const afidField = affiliateId ? { affiliateId } : {}
  const pactField = pact ? { partnerCode: pact } : {}

  const {
    email,
    entityType,
    firstName,
    industry,
    lastName,
    legalBusinessName,
    naicsCode,
    numOfEmployees,
    phoneNumber: formattedPhoneNumber,
  } = formValues

  const phoneNumber = removeNonNumericCharacters(formattedPhoneNumber)

  const newCreditAppValues = async () => {
    return {
      applicant: {
        email,
        firstName,
        lastName,
        phoneNumber,
      },
      businessInfo: {
        entityType,
        industry,
        legalBusinessName,
        naicsCode,
        numOfEmployees: parseInt(numOfEmployees),
      },
      contactId,
      recaptchaToken: await verifyReCaptcha('newApplicationOnExistingAccount'),
      ...afidField,
      ...pactField,
      ...billMarketoLeadParams,
      appVersion: shouldShowStateBasedDisclosures ? 2 : 1,
    }
  }

  const resumeCreditAppValues = async () => {
    return {
      accountId: selectedAccount,
      applicant: {
        email,
        firstName,
        lastName,
        phoneNumber,
      },
      businessInfo: {
        entityType,
        industry,
        legalBusinessName,
        naicsCode,
        numOfEmployees: parseInt(numOfEmployees),
      },
      contactId,
      recaptchaToken: await verifyReCaptcha('resumeApplication'),
      ...afidField,
      ...pactField,
      ...billMarketoLeadParams,
      appVersion: shouldShowStateBasedDisclosures ? 2 : 1,
    }
  }

  const handleResumeApplication = async () => {
    setIsResumingApp(true)
    logInfo({
      attributes: {
        action: 'handleResumeApplication',
        result: 'Resume Application button clicked in existing account drawer',
      },
      eventName: 'SignUp',
    })

    initCreditApplicationFromExistingAccountsContext({
      initCreditAppValues: await resumeCreditAppValues(),
      onFailure: () => {
        setIsResumingApp(false)

        logError({
          attributes: {
            action: 'handleResumeApplication',
            result: 'Error resuming application in existing account drawer',
          },
          eventName: 'SignUp',
        })
      },
      onSuccess: () => {
        onComplete()

        logInfo({
          attributes: {
            action: 'handleResumeApplication',
            result: 'Completed resume application flow in existing account drawer',
          },
          eventName: 'SignUp',
        })
      },
    })
  }

  const handleNewApplication = async () => {
    setIsStartingNewApp(true)

    logInfo({
      attributes: {
        action: 'handleNewApplication',
        result: 'New application button clicked in existing account drawer',
      },
      eventName: 'SignUp',
    })
    initCreditApplicationFromExistingAccountsContext({
      initCreditAppValues: await newCreditAppValues(),
      onFailure: () => {
        setIsStartingNewApp(false)

        logError({
          attributes: {
            action: 'handleNewApplication',
            result: 'Error starting a new application in existing account drawer',
          },
          eventName: 'SignUp',
        })
      },
      onSuccess: () => {
        onComplete()

        logInfo({
          attributes: {
            action: 'handleNewApplication',
            result: 'Completed new application flow in existing account drawer',
          },
          eventName: 'SignUp',
        })
      },
    })
  }

  const disableNewAppButton = isStartingNewApp || isResumingApp
  const disableResumeButton = !selectedAccount || disableNewAppButton

  const actionButtons = (
    <div css={actionButtonsStyles}>
      <BasicButton
        color='neutral'
        dataTestId={makeTestId('newapp')}
        disabled={disableNewAppButton}
        showSpinner={isStartingNewApp}
        type={BASIC_BUTTON_TYPE_FLAT}
        onClick={handleNewApplication}
      >
        <FormattedMessage
          defaultMessage='New application'
          id='sputnik.ExistingAccountDrawerContainer__HOulGI'
        />
      </BasicButton>
      <BasicButton
        dataTestId={makeTestId('resume')}
        disabled={disableResumeButton}
        showSpinner={isResumingApp}
        onClick={handleResumeApplication}
      >
        <FormattedMessage
          defaultMessage='Resume Application'
          id='sputnik.ExistingAccountDrawerContainer__G3vVVe'
        />
      </BasicButton>
    </div>
  )

  const drawerViews = {
    default: (
      <DrawerView
        footerActions={actionButtons}
        title={
          <FormattedMessage
            defaultMessage='Existing accounts found'
            id='sputnik.ExistingAccountDrawerContainer__7TpN0z'
          />
        }
      >
        <ExistingAccountDrawer
          accounts={accounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      </DrawerView>
    ),
  }

  return (
    <>
      <Scrim isShowing={isShowingDrawer}>
        <Drawer
          activeViewKey='default'
          className='fs-unmask'
          css={drawerStyles}
          dataTestId={makeTestId('drawer')}
          isShowing={isShowingDrawer}
          views={drawerViews}
        />
      </Scrim>
    </>
  )
}

const drawerStyles = ({ mq }) => css`
  width: 50%;
  ${mq.xxSmallMaxWidth({ width: '85%' })}
`

const actionButtonsStyles = css`
  display: flex;
  flex-direction: row;

  .BasicButton {
    flex: 1;
    border-radius: 0;
    min-height: 88px;
  }
`

ExistingAccountDrawerContainer.propTypes = {
  accounts: arrayOf(
    shape({
      id: string,
      name: string,
    }),
  ),
  contactId: string,
  formValues: object,
  isShowingDrawer: bool,
  onComplete: func,
  verifyReCaptcha: func.isRequired,
}

export default ExistingAccountDrawerContainer
