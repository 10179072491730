import React, { useState } from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import { bool, func, object, string } from 'prop-types'
import { useCanary } from '@divvy-web/canary'
import { useAuth } from '../../../auth'
import { getMarketoValues } from '../../../marketo'
import { logError, logInfo } from '../../../utils/loggerUtils'

const modalIconPath = 'https://app.divvy.co/assets/illustrations/binoculars'
const mainModalIconSrc = `${modalIconPath}@1x.png`
const modalIconSrcSet = `${modalIconPath}@3x.png, ${modalIconPath}@2x.png, ${modalIconPath}@1x.png`
const headerImage = (
  <img
    alt='post-it'
    src={mainModalIconSrc}
    srcSet={modalIconSrcSet}
  />
)

/*
  Displays when >50 accounts are associated to the email on the sign up form
*/

const ExistingAccountsModal = ({ closeModal, contactId, formValues, isModalShowing, onComplete, verifyReCaptcha }) => {
  const [getClassName, makeTestId] = useNameFormatter('existingaccountsmodal')
  const [isStartingNewApp, setIsStartingNewApp] = useState(false)
  const { initCreditApplicationFromExistingAccountsContext } = useAuth()
  const shouldShowStateBasedDisclosures = useCanary('state-based-disclosures')

  const billMarketoLeadParams = { marketoBillLeadInput: getMarketoValues() }

  const leadParams = billMarketoLeadParams?.marketoBillLeadInput
  const pact = leadParams?.pact
  const affiliateIdString = leadParams?.ltAffiliateId || leadParams?.ltAffiliateId
  const affiliateId = (affiliateIdString && Number.parseInt(affiliateIdString)) || null
  const afidField = affiliateId ? { affiliateId } : {}
  const pactField = pact ? { partnerCode: pact } : {}

  const {
    email,
    entityType,
    firstName,
    industry,
    lastName,
    legalBusinessName,
    naicsCode,
    numOfEmployees,
    phoneNumber,
  } = formValues

  const newCreditAppValues = async () => {
    return {
      applicant: {
        email,
        firstName,
        lastName,
        phoneNumber,
      },
      businessInfo: {
        entityType,
        industry,
        legalBusinessName,
        naicsCode,
        numOfEmployees: parseInt(numOfEmployees),
      },
      contactId,
      recaptchaToken: await verifyReCaptcha('newApplicationOnExistingAccount'),
      ...afidField,
      ...pactField,
      ...billMarketoLeadParams,
      appVersion: shouldShowStateBasedDisclosures ? 2 : 1,
    }
  }

  const handleNewApplicationClick = async () => {
    setIsStartingNewApp(true)
    logInfo({
      attributes: {
        action: 'handleNewApplicationClick',
        result: 'New application button clicked in existing accounts modal',
      },
      eventName: 'SignUp',
    })

    initCreditApplicationFromExistingAccountsContext({
      initCreditAppValues: await newCreditAppValues(),
      onFailure: () => {
        setIsStartingNewApp(false)

        logError({
          attributes: {
            action: 'handleNewApplicationClick',
            result: 'Error creating new application in existing accounts modal',
          },
          eventName: 'SignUp',
        })
      },
      onSuccess: () => {
        onComplete()
        logInfo({
          attributes: {
            action: 'handleNewApplicationClick',
            result: 'Completed creating new application in existing accounts modal',
          },
          eventName: 'SignUp',
        })
      },
    })
  }

  const newAppButton = (
    <BasicButton
      className={getClassName('new-app-button')}
      dataTestId={makeTestId('new-app-button')}
      disabled={isStartingNewApp}
      showSpinner={isStartingNewApp}
      onClick={handleNewApplicationClick}
    >
      <FormattedMessage
        defaultMessage='New Application'
        id='sputnik.ExistingAccountsModal__rH2lef'
      />
    </BasicButton>
  )

  const modalTitle = (
    <FormattedMessage
      defaultMessage='Have we met before?'
      id='sputnik.ExistingAccountsModal__4xOl3Y'
    />
  )

  return (
    <>
      <Modal
        actions={newAppButton}
        className={getClassName('modal')}
        dataTestId={makeTestId('modal')}
        headerColor='info'
        headerImage={headerImage}
        isShowing={isModalShowing}
        title={modalTitle}
        onCloseClick={closeModal}
      >
        <FormattedMessage
          defaultMessage='It looks like we found more than 50 accounts associated with this email address. Please call our application support team at <phoneNumber>(385) 352-0374</phoneNumber> or start a new application.'
          id='sputnik.ExistingAccountsModal__I+fiNf'
          values={{
            phoneNumber: (number) => <b>{number}</b>,
          }}
        />
      </Modal>
    </>
  )
}

ExistingAccountsModal.defaultProps = {
  closeModal: () => {},
}

ExistingAccountsModal.propTypes = {
  closeModal: func.isRequired,
  contactId: string,
  formValues: object,
  isModalShowing: bool,
  onComplete: func,
  verifyReCaptcha: func.isRequired,
}

export default ExistingAccountsModal
