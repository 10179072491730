/* global __DivvyEnvironment */
import { useCallback, useEffect, useState } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useAuth } from '../auth'
import { getCompanyUuidFromToken, getCreditAppIdFromToken } from '../auth/jwt'
import { logInfo } from '../utils/loggerUtils'
import useGetLaunchDarklyConfig from './useGetLaunchDarklyConfig'

/*
  Get flags and set the flag values from LD
*/

const useGetLaunchDarklyFlags = () => {
  const launchDarklyRestToken = __DivvyEnvironment.LAUNCH_DARKLY_REST_TOKEN
  const ldFlagConfigs = useGetLaunchDarklyConfig({ launchDarklyRestToken })
  const [flags, setFlags] = useState([])
  const { getTokenFromStorage } = useAuth()

  const token = getTokenFromStorage()
  const companyUuid = getCompanyUuidFromToken(token) ?? ''
  const creditAppId = getCreditAppIdFromToken(token) ?? ''

  const launchDarklyClient = useLDClient()

  const setRawFlags = useCallback(
    (flagValues) => {
      const updatedFlagsWithValues = ldFlagConfigs.map((flag) => ({
        ...flag,
        value: flagValues?.[flag?.name],
      }))

      setFlags(updatedFlagsWithValues)
    },
    [ldFlagConfigs],
  )

  useEffect(() => {
    if (!launchDarklyRestToken) return
    launchDarklyClient
      .identify({
        custom: {
          companyUuid,
          creditAppId,
        },
        key: creditAppId,
      })
      .then(() => {
        if (ldFlagConfigs?.length <= 0) return
        const flagValues = launchDarklyClient?.allFlags()
        const updatedFlagsWithValues = setRawFlags(flagValues)

        logInfo({
          attributes: {
            action: 'useLaunchDarkly',
            message: `successfully updated flag values for credit app ID: ${creditAppId}`,
            result: updatedFlagsWithValues,
          },
          eventName: 'launch-darkly-config',
        })
      })
  }, [companyUuid, creditAppId, launchDarklyClient, launchDarklyRestToken, ldFlagConfigs, setRawFlags])

  return [flags, setRawFlags]
}

export default useGetLaunchDarklyFlags
