import { useMutation } from '@apollo/client'
import useDidMount from '@divvy-web/hooks.usedidmount'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import Form from '@divvy-web/skylab.form'
import Toast, { TOAST_TYPE_DANGER } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import React, { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'
import { useCanary } from '@divvy-web/canary'
import { useAuth } from '../../auth'
import InitCreditApplication from '../../auth/gql/InitCreditApplication.gql'
import { AutoCompleteDisable } from '../../components/FormInputs'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import { getMarketoValues } from '../../marketo'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { removeNonNumericCharacters } from '../../utils/dataUtils'
import { logError, logInfo } from '../../utils/loggerUtils'
import { getClientTimezone } from '../../utils/timezoneUtil'
import ExistingAccountDrawerContainer from './ExistingAccountDrawerContainer'
import ExistingAccountsModal from './ExistingAccountsModal'
import SignUpForm from './SignUpForm'
import signupValidations from './signupValidations'

const SignUpView = () => {
  const [showToast, setShowToast] = useState(false)
  const [getClassName, makeTestId] = useNameFormatter('SignUp')
  const [submitting, setSubmitting] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [contactId, setContactId] = useState('')
  // COM-7939: remove modal and drawer state
  const [isShowingDrawer, setIsShowingDrawer] = useState(false)
  const [isExistingAccountsModalShowing, setIsExistingAccountsModalShowing] = useState(false)
  const [drawerFormValues, setDrawerFormValues] = useState({})
  const [duplicateLeadFound, setDuplicateLeadFound] = useState(false)
  const shouldShowStateBasedDisclosures = useCanary('state-based-disclosures')
  const errorMsg = (
    <FormattedMessage
      defaultMessage='Error while checking for existing accounts.'
      id='sputnik.SignUpView__ItScGn'
    />
  )
  const handleStopSubmitting = () => setSubmitting(false)
  const { email, setEmail, requestApplicantToken } = useAuth()
  const [initCreditAppMutation, { loading }] = useMutation(InitCreditApplication)
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const verifyReCaptcha = useCallback(
    async (action) => {
      if (!executeRecaptcha) return

      const token = await executeRecaptcha(action)

      logInfo({
        attributes: {
          action,
          result: 'Token retrieved',
        },
        eventName: 'ReCaptcha',
      })

      return token
    },
    [executeRecaptcha],
  )

  useDidMount(() => {
    logInfo({
      attributes: {
        result: 'Sign Up page loaded',
      },
      eventName: 'PageLoad',
    })
  })

  const billMarketoLeadParams = { marketoBillLeadInput: getMarketoValues() }

  const leadParams = billMarketoLeadParams?.marketoBillLeadInput
  const pact = leadParams?.pact
  const affiliateIdString = leadParams?.ltAffiliateId || leadParams?.ltAffiliateId
  const affiliateId = (affiliateIdString && Number.parseInt(affiliateIdString)) || null
  const afidField = affiliateId ? { affiliateId } : {}
  const pactField = pact ? { partnerCode: pact } : {}

  // COM-7939: clean up this function to possibly remove or replace logic for checking for salesforceContactExists
  const checkForExistingSalesforceAccountsOrCreate = async (values) => {
    const {
      email,
      entityType,
      firstName,
      industry,
      lastName,
      legalBusinessName,
      naicsCode,
      numOfEmployees,
      phoneNumber: formattedPhoneNumber,
    } = values

    const phoneNumber = removeNonNumericCharacters(formattedPhoneNumber)

    const mutationVariables = {
      applicant: {
        email,
        firstName,
        lastName,
        phoneNumber,
      },
      businessInfo: {
        entityType,
        industry,
        legalBusinessName,
        naicsCode,
        numOfEmployees: parseInt(numOfEmployees),
      },
      recaptchaToken: await verifyReCaptcha('initApplication'),
      ...afidField,
      ...pactField,
      clientTimeZone: getClientTimezone(),
      ...billMarketoLeadParams,
      appVersion: shouldShowStateBasedDisclosures ? 2 : 1,
    }

    initCreditAppMutation({ variables: mutationVariables })
      .then((result) => {
        const { accounts, companyDivvyUuid, contactId, salesforceCreditId } = result?.data?.initCreditApplication || {}
        setAccounts(accounts)
        setContactId(contactId)
        setEmail(email)

        // COM-7939: remove modal and drawer logic
        if (accounts?.length > 50) {
          setIsExistingAccountsModalShowing(true)
          setDrawerFormValues(values)
          handleStopSubmitting()

          logInfo({
            attributes: {
              action: 'checkForExistingSalesforceAccounts',
              companyUUID: companyDivvyUuid,
              result: 'There are more than 50 existing accounts',
            },
            eventName: 'SignUp',
          })

          return
        }

        // COM-7939:
        if (accounts?.length <= 50) {
          setIsShowingDrawer(true)
          setDrawerFormValues(values)
          handleStopSubmitting()

          logInfo({
            attributes: {
              action: 'checkForExistingSalesforceAccounts',
              companyUUID: companyDivvyUuid,
              result: 'There are less than 50 existing accounts',
            },
            eventName: 'SignUp',
          })

          return
        }

        handleInitCreditAppComplete(salesforceCreditId)
      })
      .catch((error) => {
        // COM-7939: check to make sure this is the error we want after removing modal and drawer logic since this function probably won't be checking for existing accounts
        const exception = error.graphQLErrors?.[0]?.extensions?.exception
        const hasNewDuplicateLeadError =
          exception?.code === 409 && exception?.message?.startsWith('A self-serve lead already exists for this email')
        const hasDeprecatedDuplicateLeadError = exception?.code === 'duplicate_lead_by_email'

        if (hasNewDuplicateLeadError || hasDeprecatedDuplicateLeadError) {
          setDuplicateLeadFound(true)
          logError({
            attributes: {
              action: 'checkForExistingSalesforceAccounts',
              message: error?.message,
              result: 'Duplicate lead found while checking for existing accounts',
            },
            eventName: 'SignUp',
          })
        } else {
          setShowToast(true)
          logError({
            attributes: {
              action: 'checkForExistingSalesforceAccounts',
              message: error?.message,
              result: errorMsg,
            },
            eventName: 'SignUp',
          })
        }

        handleStopSubmitting()
      })
  }

  /**
   * Handles the completion of initializing a credit application.
   *
   * @param {string} salesforceCreditId - The Salesforce Credit ID of the credit application.
   * @returns {Promise<void>} - A promise that resolves when the function completes.
   */
  // COM-7939: since we will be making the function above to just create a new app, we can probably include this logic in that function
  const handleInitCreditAppComplete = async (salesforceCreditId) => {
    await requestApplicantToken(salesforceCreditId)
    handleStopSubmitting()
    navigate(
      { pathname: PATHNAME_CONSTS.DASHBOARD_PATH, search: '' },
      { state: { referrer: salesforceCreditId ? PATHNAME_CONSTS.AUTH_PATH : null } },
    )
  }

  const handleSubmit = (values) => {
    logInfo({
      attributes: {
        action: 'handleSubmit',
        result: 'Sign up button clicked',
      },
      eventName: 'SignUp',
    })

    setSubmitting(true)
    checkForExistingSalesforceAccountsOrCreate(values)
  }

  const initialValues = Object.assign({}, { email }, drawerFormValues)

  return (
    <MiscPageWrapper
      hideHeading={duplicateLeadFound}
      pageName='signup'
      pageSubTitle={
        <FormattedMessage
          defaultMessage='Let’s get to know you'
          id='sputnik.SignUpView__1TF+wp'
        />
      }
      pageTitle={
        <FormattedMessage
          defaultMessage='Start spending smarter'
          id='sputnik.SignUpView__TbiUGv'
        />
      }
    >
      <Form
        className={getClassName('')}
        css={css`
          height: 100%;
        `}
        dataTestId={makeTestId('')}
        initialValues={initialValues}
        validation={signupValidations}
      >
        <SignUpForm
          duplicateLeadFound={duplicateLeadFound}
          submitting={loading || submitting}
          verifyReCaptcha={verifyReCaptcha}
          onNextClick={handleSubmit}
        />
        <AutoCompleteDisable />
      </Form>
      {/* COM-7939: remove drawer component here and delete actual component */}
      <ExistingAccountDrawerContainer
        accounts={accounts}
        contactId={contactId}
        formValues={drawerFormValues}
        isShowingDrawer={isShowingDrawer}
        verifyReCaptcha={verifyReCaptcha}
        onComplete={handleStopSubmitting}
      />
      {/* COM-7939: remove modal component here and delete actual component */}
      <ExistingAccountsModal
        closeModal={() => setIsExistingAccountsModalShowing(false)}
        contactId={contactId}
        formValues={drawerFormValues}
        isModalShowing={isExistingAccountsModalShowing}
        verifyReCaptcha={verifyReCaptcha}
        onComplete={handleStopSubmitting}
      />
      {/* COM-7939: refactor this toast to not be shown by state, but in the mutation call itself */}
      {showToast && (
        <div className='toast-wrapper'>
          <Toast
            autoHideDelay={5000}
            dataTestId='resend-code-failure-toast'
            shouldShow={showToast}
            type={TOAST_TYPE_DANGER}
            onExitCallback={() => setShowToast(false)}
          >
            <FormattedMessage
              defaultMessage='Error while checking for existing accounts.'
              id='sputnik.SignUpView__ItScGn'
            />
          </Toast>
        </div>
      )}
    </MiscPageWrapper>
  )
}

export default SignUpView
