import { useMutation } from '@apollo/client'
import Modal from '@divvy-web/skylab.modal'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import { bool, func, shape, string } from 'prop-types'
import { QRCodeCanvas } from 'qrcode.react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from '@divvy-web/i18n'
import ImageButtonTile, { IMAGE_BUTTON_TILE_COLOR_NEUTRAL } from '@divvy-web/skylab.imagebuttontile'
import Image from '@divvy-web/skylab.image'
import Spinner from '@divvy-web/skylab.spinner'
import { logError, logInfo } from '../../utils/loggerUtils'
import GenerateRequestedDocUploadUrl from '../UploaderTileList/GenerateRequestedDocUploadUrl.gql'
import { uploadToS3 } from '../UploaderTileList/s3Uploader'
import { documentUploadTypeModalStyles } from './documentUploadTypeModalStyles'

const DocumentUploadTypeModal = ({ appId, isShowing, selectedDocument, setIsModalShowing, token }) => {
  const [isUploading, setIsUploading] = useState(false)
  const makeTestId = useMakeTestId('DocumentUploadTypeModal')
  const showDangerToast = useToast(TOAST_TYPE_DANGER)
  const hostName = window.location.hostname
  const { selectedDocumentType: documentType, selectedDocumentUuid: documentUuid } = selectedDocument

  useEffect(() => {
    if (!isShowing) {
      setIsUploading(false)
    }
  }, [isShowing])

  const handleError = (action) => (e) => {
    logError({
      attributes: {
        action,
        result: e,
      },
      eventName: 'UploadDocument',
    })

    showDangerToast(
      <FormattedMessage
        defaultMessage='There was an error uploading this document'
        id='sputnik.DocumentUploadTypeModal__7NF4bS'
      />,
      { autoHideDelay: 5000 },
    )

    setIsUploading(false)
  }

  const [getSignedUrl, { loading }] = useMutation(GenerateRequestedDocUploadUrl, {
    onError: handleError('GenerateRequestedDocUploadUrl'),
  })

  const isLoading = loading || isUploading

  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles?.[0]

    if (acceptedFileTypes.length > 0) {
      setIsUploading(true)

      await getSignedUrl({
        variables: {
          documentUuid,
          fileName: selectedFile.name,
        },
      })
        .then(({ data }) => {
          const uploadUrl = data?.docUploadInfo?.url

          if (uploadUrl) {
            return uploadToS3(selectedFile, uploadUrl)
          }

          throw new Error('No Upload URL returned from the server')
        })
        .then((_) => {
          logInfo({
            attributes: {
              action: 'UploadToS3',
              result: `Document uploaded to S3: ${documentType}`,
            },
            eventName: 'UploadDocument',
          })
        })
        .catch(handleError('UploadToS3'))
    }
  }

  const acceptedFileTypes = 'image/jpeg, application/pdf'

  const { getInputProps, open } = useDropzone({
    accept: acceptedFileTypes,
    noClick: true,
    noKeyboard: true,
    onDrop,
  })

  return (
    <Modal
      css={documentUploadTypeModalStyles}
      dataTestId={makeTestId('')}
      isShowing={isShowing}
      title={
        <FormattedMessage
          defaultMessage='How would you like to upload?'
          id='sputnik.DocumentUploadTypeModal__PCONMC'
        />
      }
      onCloseClick={(_) => setIsModalShowing(false)}
    >
      <div className='tiles-wrapper'>
        <input {...getInputProps()} />
        <ImageButtonTile
          bodyText={
            <FormattedMessage
              defaultMessage='Scan this code with your mobile phone to upload.'
              id='sputnik.DocumentUploadTypeModal__CWac43'
            />
          }
          className='qrcode-tile'
          color={IMAGE_BUTTON_TILE_COLOR_NEUTRAL}
          dataTestId={makeTestId('qrcode')}
          title={
            <FormattedMessage
              defaultMessage='Your mobile phone'
              id='sputnik.DocumentUploadTypeModal__hAvsjh'
            />
          }
          onClick={() => {}}
        >
          <div className='document-source-img-wrapper'>
            <div data-testid={makeTestId('qrcode-div')}>
              <QRCodeCanvas
                imageSettings={{
                  height: '35',
                  src: `https://app.divvy.co/assets/logos/document-upload-type-modal-qrcode@3x.png`,
                  width: '35',
                }}
                value={`https://${hostName}/auth?referrer=mobile-doc-upload&authToken=${token}`}
              />
            </div>
          </div>
        </ImageButtonTile>
        <ImageButtonTile
          bodyText={
            <FormattedMessage
              defaultMessage='Select files from your computer to upload.'
              id='sputnik.DocumentUploadTypeModal__B+Q//T'
            />
          }
          className='your-computer-image'
          color={IMAGE_BUTTON_TILE_COLOR_NEUTRAL}
          dataTestId={makeTestId('upload-from-computer')}
          disabled={isLoading}
          title={
            <FormattedMessage
              defaultMessage='Your computer'
              id='sputnik.DocumentUploadTypeModal__MMpdPP'
            />
          }
          onClick={() => {
            logInfo({
              attributes: {
                action: 'Initiate document upload',
                creditApplicationId: appId,
                result: 'User initiated a document upload on the current machine',
              },
              eventName: 'SameDeviceDocumentUpload',
            })
            open()
          }}
        >
          {!isLoading && (
            <Image
              alt='person working on computer'
              assetName='document-upload-type-modal-computer'
            />
          )}
          {isLoading && <Spinner centered />}
        </ImageButtonTile>
      </div>
    </Modal>
  )
}

DocumentUploadTypeModal.propTypes = {
  appId: string,
  isShowing: bool,
  selectedDocument: shape({
    selectedDocumentType: string,
    selectedDocumentUuid: string,
  }),
  setIsModalShowing: func,
  token: string,
}

export default DocumentUploadTypeModal
