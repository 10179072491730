import { css } from '@emotion/core'

export const documentUploadTypeModalStyles = ({ mq }) => css`
  .Modal-main {
    max-width: 760px;
    ${mq.xxSmallMaxWidth({ maxWidth: '90vw' })}
    background: var(--tri-color-fill-accent-tertiary);
  }

  .tiles-wrapper {
    display: grid;
    ${mq.xSmallMinWidth({ gridTemplateColumns: 'repeat(2, 1fr)' })}
    grid-gap: var(--tri-space-200);

    .qrcode-tile:hover {
        background: var(--tri-color-fill-primary);
    }

    .your-computer-image-image-container {
        .Image {
          object-fit: none;
        }
      }

      .ImageButtonTile-content {
        gap: 25px;
      }
    }
  }

  .spinner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .document-source-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`
