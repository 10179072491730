/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as _Types from '_gql'
const defaultOptions = {} as const
export type AcceptTilaAgreementMutation_acceptTila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta =
  {
    readonly __typename: 'SignableCreditApplicationDocumentMeta'
    readonly signedAt?: any | null
    readonly underwritingDecisionUuid?: string | null
    readonly underwritingOfferType?: _Types.UnderwritingOffer | null
  }

export type AcceptTilaAgreementMutation_acceptTila_SignableCreditApplicationDocument = {
  readonly __typename: 'SignableCreditApplicationDocument'
  readonly content: string
  readonly type: _Types.SignableCreditApplicationDocumentType
  readonly meta: AcceptTilaAgreementMutation_acceptTila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta
}

export type AcceptTilaAgreementMutation_Mutation = {
  readonly __typename: 'Mutation'
  readonly acceptTila?: AcceptTilaAgreementMutation_acceptTila_SignableCreditApplicationDocument | null
}

export type AcceptTilaAgreementMutationVariables = _Types.Exact<{
  salesforceCreditId: _Types.Scalars['ID']['input']
  underwritingDecisionUuid: _Types.Scalars['ID']['input']
  underwritingOfferType: _Types.UnderwritingOffer
}>

export type AcceptTilaAgreementMutation = AcceptTilaAgreementMutation_Mutation

export const AcceptTilaAgreementDocument = gql`
  mutation AcceptTilaAgreement(
    $salesforceCreditId: ID!
    $underwritingDecisionUuid: ID!
    $underwritingOfferType: UnderwritingOffer!
  ) {
    acceptTila(
      salesforceCreditId: $salesforceCreditId
      underwritingDecisionUuid: $underwritingDecisionUuid
      underwritingOfferType: $underwritingOfferType
    ) {
      content
      meta {
        signedAt
        underwritingDecisionUuid
        underwritingOfferType
      }
      type
    }
  }
`
export type AcceptTilaAgreementMutationFn = Apollo.MutationFunction<
  AcceptTilaAgreementMutation,
  AcceptTilaAgreementMutationVariables
>

/**
 * __useAcceptTilaAgreementMutation__
 *
 * To run a mutation, you first call `useAcceptTilaAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTilaAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTilaAgreementMutation, { data, loading, error }] = useAcceptTilaAgreementMutation({
 *   variables: {
 *      salesforceCreditId: // value for 'salesforceCreditId'
 *      underwritingDecisionUuid: // value for 'underwritingDecisionUuid'
 *      underwritingOfferType: // value for 'underwritingOfferType'
 *   },
 * });
 */
export function useAcceptTilaAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptTilaAgreementMutation, AcceptTilaAgreementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptTilaAgreementMutation, AcceptTilaAgreementMutationVariables>(
    AcceptTilaAgreementDocument,
    options,
  )
}
export type AcceptTilaAgreementMutationHookResult = ReturnType<typeof useAcceptTilaAgreementMutation>
export type AcceptTilaAgreementMutationResult = Apollo.MutationResult<AcceptTilaAgreementMutation>
export type AcceptTilaAgreementMutationOptions = Apollo.BaseMutationOptions<
  AcceptTilaAgreementMutation,
  AcceptTilaAgreementMutationVariables
>
