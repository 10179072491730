import React from 'react'
import { FM } from '@divvy-web/i18n'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { css } from '@emotion/core'
import { object } from 'prop-types'

const TipCard = ({ reviewOrTip }) => {
  const makeTestId = useMakeTestId('tip-card')

  const { bodyText, fmValueIdentifier, headerText, url } = reviewOrTip

  const uniqueId = headerText.split(' ').join('-').replace('?', '').toLowerCase()

  return (
    <div
      className='pro-tip fs-unmask'
      data-testid={makeTestId(uniqueId)}
    >
      <div className='tip-header'>
        <FM msg={headerText} />
      </div>
      <div className='tip-body'>
        <FM
          msg={bodyText}
          values={
            fmValueIdentifier && {
              [fmValueIdentifier]: (textValue) => (
                <a
                  css={css`
                    color: var(--tri-color-fill-info-inverse);
                  `}
                  href={url}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {textValue}
                </a>
              ),
            }
          }
        />
      </div>
    </div>
  )
}

TipCard.propTypes = {
  reviewOrTip: object,
}

export default TipCard
