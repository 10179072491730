import useDidMount from '@divvy-web/hooks.usedidmount'
import Spinner from '@divvy-web/skylab.spinner'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../auth'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import { logInfo } from '../../utils/loggerUtils'
import VerifyCodeBlock from './VerifyCodeBlock'

const VerifyCode = () => {
  const [loading, setLoading] = useState(true)
  const { email, goToAuth, requestAuthCode, setReferrer, verifyAuthCode } = useAuth()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const params = {
    accessCode: queryParams.get('access_code')?.trim(),
    email: queryParams.get('email')?.trim().replace(/ /g, '+'),
    referrer: queryParams.get('referrer'),
  }

  useDidMount(() => {
    if (params.email && params.accessCode) {
      verifyAuthCode(params.accessCode, params.email, params?.referrer || null, search).catch(() => {
        requestAuthCodeFromQueryParams()
      })
    } else if (params.email) {
      requestAuthCodeFromQueryParams()
      if (params.referrer) {
        setReferrer(params.referrer)
      }
    } else if (!email) {
      goToAuth(true)
    } else {
      setLoading(false)
    }

    logInfo({
      attributes: {
        result: 'Verify access code page loaded',
      },
      eventName: 'PageLoad',
    })
  })

  const requestAuthCodeFromQueryParams = () => {
    requestAuthCode(params.email)
      .catch(() => {
        goToAuth(true)
      })
      .then(() => {
        setLoading(false)
      })
  }

  if (loading) return <Spinner fullPage />

  return (
    <PageViewWrapper pageName='verify-code'>
      <VerifyCodeBlock loading={loading} />
    </PageViewWrapper>
  )
}

export default VerifyCode
