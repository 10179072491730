import TextLink, { TEXT_LINK_SMALL } from '@divvy-web/skylab.textlink'
import { MXWidget } from '@divvy/odin.bank-manager'
import { css } from '@emotion/core'
import { bool, func, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import { FormattedMessage } from '@divvy-web/i18n'
import { useParams } from 'react-router-dom'
import Modal from '@divvy-web/skylab.modal'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { logError } from '../../utils/loggerUtils'
import GetMXWidgetUrl from '../gql/GetMXWidgetUrl.gql'
import MXInstitutionSearch from './MXInstitutionSearch'

const MoneyMoverMXWidget = ({
  companyId,
  isShowing,
  onClose,
  onMXWidgetManualSelection,
  onReady,
  onSyncError,
  setInstitutionName,
}) => {
  const { appId } = useParams()
  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [isConnecting, setIsConnecting] = useState(false)
  const makeTestId = useMakeTestId('MoneyMoverMXWidget')
  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(GetMXWidgetUrl, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !selectedInstitution,
    variables: {
      companyId,
      institutionCode: selectedInstitution?.code,
      isMobile: false,
      mode: selectedInstitution?.supportsAccountVerification ? 'verification' : 'aggregation',
    },
  })
  const widgetUrl = data?.bankIntegrations?.widgetUrl

  const searchPageContent = (
    <div css={searchPageCss}>
      <span className='cant-find-bank'>
        <FormattedMessage
          defaultMessage="Can't find your bank? "
          id='sputnik.MoneyMoverMXWidget__2m+TJS'
        />
      </span>
      <div>
        <TextLink
          size={TEXT_LINK_SMALL}
          onClick={onMXWidgetManualSelection}
        >
          <FormattedMessage
            defaultMessage='Link your bank manually'
            id='sputnik.MoneyMoverMXWidget__IBN8EO'
          />
        </TextLink>
      </div>
    </div>
  )

  useEffect(() => {
    if (error && !isLoading) {
      logError({
        attributes: {
          action: 'Failure',
          message: `Credit App ID: ${appId}`,
          result: error,
        },
        eventName: 'GetMXWidgetUrl',
      })
      onSyncError()
    }
  }, [error, isLoading, appId, onSyncError])

  const handleMXWidgetError = (widgetError, widgetInfo) => {
    logError({
      attributes: {
        action: 'Failure',
        info: widgetInfo,
        message: `Credit App ID: ${appId}`,
        result: widgetError,
      },
      eventName: 'LoadMXWidget',
    })
    onSyncError()
  }

  if (error && !isLoading) return null

  return (
    <ErrorBoundary
      FallbackComponent={() => <br />}
      onError={handleMXWidgetError}
    >
      <Modal
        css={modalCss}
        dataTestId={makeTestId('Modal')}
        isShowing={isShowing}
        title=' '
        onCloseClick={isConnecting ? null : onClose}
      >
        {selectedInstitution === null ? (
          <MXInstitutionSearch
            bottomContent={searchPageContent}
            setSelectedInstitution={setSelectedInstitution}
          />
        ) : (
          <MXWidget
            isLoadingWidgetUrl={isLoading}
            renderInsideModal={false}
            searchPageContent={searchPageContent}
            setInstitutionName={setInstitutionName}
            setIsConnecting={setIsConnecting}
            widgetUrl={widgetUrl}
            widgetUrlError={error}
            onBankAccountConnected={onReady}
            onClose={onClose}
          />
        )}
      </Modal>
    </ErrorBoundary>
  )
}

MoneyMoverMXWidget.propTypes = {
  companyId: string,
  isShowing: bool.isRequired,
  onClose: func.isRequired,
  onMXWidgetManualSelection: func,
  onReady: func.isRequired,
  onSyncError: func.isRequired,
  setInstitutionName: func.isRequired,
}

const modalCss = ({ mq }) => css`
  .Modal-main {
    width: 500px;
    min-height: 700px;
  }

  .Modal-content {
    ${mq.xSmallMaxWidth({
      paddingLeft: 'var(--tri-space-200)',
      paddingRight: 'var(--tri-space-200)',
    })}
  }
`

const searchPageCss = ({ type }) => css`
  text-align: center;

  .cant-find-bank {
    ${type.triFontDesktopBodySmall}
  }
`

export default MoneyMoverMXWidget
