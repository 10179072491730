import React from 'react'
import { useQuery } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import Spinner from '@divvy-web/skylab.spinner'
import { TOAST_TYPE_BASIC, useToast } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../auth'
import { FormButtons } from '../../components'
import ErrorPage from '../../components/ErrorPage'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import GetCreditApplication from '../../pages/gql/GetCreditApplication.gql'
import { MIGRATION, PATHNAME_CONSTS } from '../../resources/constants'
import { matchEmail } from '../../utils/dataUtils'
import { logInfo } from '../../utils/loggerUtils'
import MigrationSuccess from '../MigrationSuccess/MigrationSuccess'

const ApplicationSubmitted = () => {
  const { appId } = useParams()
  const { email } = useAuth()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const showToast = useToast()
  const { data, error, loading } = useQuery(GetCreditApplication, {
    variables: { creditApplicationId: appId },
  })

  if (error) return <ErrorPage />
  if (loading) return <Spinner fullPage />

  const { authorizedSigner, name, recordType } = data?.creditApplication || {}
  const isMigration = recordType === MIGRATION
  const { email: authSignerEmail, firstName: authSignerFirst } = authorizedSigner
  const loggedInUserIsAuthorizedSigner = matchEmail(email, authSignerEmail)

  const handleNextClick = () => {
    logInfo({
      attributes: {
        action: 'go to dashboard',
        message: `Credit App ID: ${appId}`,
        result: 'Sending user to path: /dashboard',
      },
      eventName: 'NavigationButtonClick',
    })

    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  const handleLogoutClick = () => {
    logout()
    showToast(
      TOAST_TYPE_BASIC,
      <FormattedMessage
        defaultMessage='Log out successful!'
        id='sputnik.ApplicationSubmitted__VckeyC'
      />,
      {
        autoHideDelay: 5000,
        dataTestId: 'logout-success-toast',
      },
    )
    logInfo({
      attributes: {
        action: 'handleOnClick',
        result: 'logging user out of the application',
      },
      eventName: 'logout',
    })
  }

  return (
    <PageViewWrapper
      inviteCollaborators
      pageName='application-submitted'
    >
      <MiscPageWrapper
        pageName='application-submitted'
        pageStyles={thankYouPageStyles}
        pageSubTitle={loggedInUserIsAuthorizedSigner ? '' : 'We appreciate you'}
        pageTitle={
          loggedInUserIsAuthorizedSigner ? (
            <FormattedMessage
              defaultMessage='Your application has been submitted!'
              id='sputnik.ApplicationSubmitted__E69tAC'
            />
          ) : (
            <FormattedMessage
              defaultMessage='Thank you!'
              id='sputnik.ApplicationSubmitted__aYIUar'
            />
          )
        }
      >
        <div className='body-container'>
          {loggedInUserIsAuthorizedSigner ? (
            <div className='body-text fs-unmask'>
              <FormattedMessage
                defaultMessage="You'll hear from us soon via email on next steps. Until then, <bold>you can check the status of your application by visiting apply.divvy.co.</bold>"
                id='sputnik.ApplicationSubmitted__CoVWas'
                values={{
                  bold: (textValue) => <b>{textValue}</b>,
                }}
              />
            </div>
          ) : (
            <>
              <div
                className='body-text'
                data-testid='signed-text'
              >
                <FormattedMessage
                  defaultMessage='An email has been sent to {email} for {name} to log in to apply.divvy.co to review and sign the application.'
                  id='sputnik.ApplicationSubmitted__xKRA0q'
                  values={{ email: authSignerEmail, name: authSignerFirst }}
                />
              </div>

              <div className='body-text'>
                <FormattedMessage
                  defaultMessage='Check out your Dashboard now to know where to get updates on your application in the future.'
                  id='sputnik.ApplicationSubmitted__KTtYWp'
                />
              </div>
            </>
          )}
          <nav className='nav-buttons'>
            <FormButtons
              hideExit
              isCentered
              handleNextClick={handleNextClick}
              handlePreviousClick={handleLogoutClick}
              id='thank-you-submit'
              nextButtonText={
                loggedInUserIsAuthorizedSigner ? (
                  <FormattedMessage
                    defaultMessage='Check application status'
                    id='sputnik.ApplicationSubmitted__3hBRrt'
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage='Go to my dashboard'
                    id='sputnik.ApplicationSubmitted__FL5BVp'
                  />
                )
              }
              previousButtonText={
                <FormattedMessage
                  defaultMessage='Logout'
                  id='sputnik.ApplicationSubmitted__C81/uG'
                />
              }
            />
          </nav>
        </div>
      </MiscPageWrapper>
    </PageViewWrapper>
  )
}

const thankYouPageStyles = ({ mq, type }) => css`
  margin-left: var(--tri-space-2500);
  margin-right: var(--tri-space-1500);
  ${mq.largeMaxWidth({ marginLeft: 'var(--tri-space-1500)', marginRight: 'var(--tri-space-700)' })}
  ${mq.xSmallMaxWidth({ margin: 0 })}

  .application-submitted-page-heading {
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${mq.xSmallMaxWidth({ height: 'auto' })}

    &-header {
      ${type.triFontDesktopBodyLargeEmphasis};
      ${mq.xSmallMaxWidth({
        display: 'flex',
        flexDirection: 'column',
        height: 'var(--tri-space-1300)',
        justifyContent: 'center',
        paddingLeft: 'var(--tri-space-300)',
        width: 'calc(100% - 55px)',
      })}
    }

    &-subtitle {
      display: none;
      ${mq.xSmallMaxWidth({ display: 'block' })}
    }
  }

  .application-submitted-page-heading > header {
    padding: 0;
    margin: 0;
  }

  .body-container {
    margin-top: var(--tri-space-900);
    max-width: 520px;
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-600);

    ${mq.xSmallMaxWidth({
      marginTop: 'var(--tri-space-500)',
      maxWidth: '100%',
      padding: '0 var(--tri-space-400) var(--tri-space-1000) var(--tri-space-300)',
    })}
  }

  .body-text {
    ${type.triFontDesktopBodyMedium}
    color: var(--tri-color-text-tertiary);
  }

  .nav-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .FormButtons-container {
    position: relative;
    padding-right: 0;
    ${mq.xSmallMaxWidth({ position: 'fixed' })}
  }

  [class*='BasicButton next-button'] {
    min-width: 250px;
    ${mq.xSmallMaxWidth({ minWidth: 'auto' })}
  }
`

export default ApplicationSubmitted
