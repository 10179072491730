import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { PageMutationKey } from '../../resources/constants'
import AuthorizedSignerForm from '../AuthorizedSigner/AuthorizedSignerForm'
import CompanyOwnersForm from '../CompanyOwners/CompanyOwnersForm'
import BusinessInfoForm from '../BusinessInfo/BusinessInfoForm'
import FinanceInfoForm from '../FinanceInfo/FinanceInfoForm'
import ReviewAndSign from '../ReviewAndSign/ReviewAndSign'
import { pageSubTitles } from './pageSubTitles'

export const regularPages = (isMigration = false, isRequestingCorrection = false, isMajorAccountChange = false) => {
  return [
    {
      component: (props) => <BusinessInfoForm {...props} />,
      correctionSection: 'BUSINESS',
      description: (businessName) => {
        if (!businessName)
          return (
            <FormattedMessage
              defaultMessage='Welcome to the first page of the credit application'
              id='sputnik.pages__5A6opC'
            />
          )
        return (
          <FormattedMessage
            defaultMessage='Welcome to the first page of the credit application for {businessName}'
            id='sputnik.pages__clCDv0'
            values={{ businessName }}
          />
        )
      },
      hidePrevious: true,
      mutationKey: PageMutationKey.PAGE_BUSINESS_INFO,
      name: 'businessInfo',
      route: 'business-info',
      showInviteCollaboratorsLink: true,
      title: (
        <FormattedMessage
          defaultMessage='Business information'
          id='sputnik.pages__jMVWGg'
        />
      ),
    },
    {
      component: (props) => <FinanceInfoForm {...props} />,
      correctionSection: 'FINANCIAL',
      description: (
        <FormattedMessage
          defaultMessage='Let’s talk money.'
          id='sputnik.pages__DwoEG5'
        />
      ),
      exclude: isMigration || isMajorAccountChange,
      hidePrevious: false,
      mutationKey: PageMutationKey.PAGE_FINANCE_INFO,
      name: 'financeInfo',
      route: 'finance-info',
      showInviteCollaboratorsLink: true,
      subTitle: pageSubTitles['financeInfo'],
      title: (
        <FormattedMessage
          defaultMessage='Financial information'
          id='sputnik.pages__ayfPbb'
        />
      ),
    },
    {
      component: (props) => <AuthorizedSignerForm {...props} />,
      correctionSection: 'AS',
      description: (
        <FormattedMessage
          defaultMessage='We’re going to need a signature soon.'
          id='sputnik.pages__BZUE0K'
        />
      ),
      hidePrevious: isRequestingCorrection,
      mutationKey: PageMutationKey.PAGE_AUTHORIZED_SIGNER,
      name: 'authorizedSigner',
      route: 'authorized-signer',
      showInviteCollaboratorsLink: true,
      subTitle: pageSubTitles['authorizedSigner'],
      title: (
        <FormattedMessage
          defaultMessage='Authorized signer'
          id='sputnik.pages__2DRSsN'
        />
      ),
    },
    {
      component: (props) => <CompanyOwnersForm {...props} />,
      correctionSection: 'BO',
      description: (
        <FormattedMessage
          defaultMessage='This is anyone who owns at least 25% of the company.'
          id='sputnik.pages__bh8hel'
        />
      ),
      hidePrevious: isRequestingCorrection,
      mutationKey: PageMutationKey.PAGE_COMPANY_OWNERS,
      name: 'companyOwners',
      route: 'company-owners',
      showInviteCollaboratorsLink: true,
      title: (
        <FormattedMessage
          defaultMessage='Company owners'
          id='sputnik.pages__FvqLu2'
        />
      ),
    },
    {
      component: (props) => <ReviewAndSign {...props} />,
      description: isRequestingCorrection ? (
        ''
      ) : (
        <FormattedMessage
          defaultMessage='You made it!'
          id='sputnik.pages__Kxz1FB'
        />
      ),
      hidePrevious: isMigration || isRequestingCorrection || isMajorAccountChange,
      mutationKey: isMigration ? PageMutationKey.PAGE_REVIEW_AND_VALIDATE : PageMutationKey.PAGE_REVIEW_AND_SIGN,
      name: 'reviewAndSign',
      route: 'review-and-sign',
      showInviteCollaboratorsLink: false,
      title: isMigration ? (
        <FormattedMessage
          defaultMessage='Review and validate'
          id='sputnik.pages__Y9JQyE'
        />
      ) : (
        <FormattedMessage
          defaultMessage='Review and sign'
          id='sputnik.pages__sedqFw'
        />
      ),
    },
  ]
    .filter(({ exclude }) => !exclude)
    .map((page, index) => ({ ...page, index }))
}
