import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import TextLink from '@divvy-web/skylab.textlink'
import { launchIntercom } from '../utils'
import FormattedList from './FormattedList'

export const rejectionReasons = {
  BLURRY_UNREADABLE: 'BLURRY_UNREADABLE',
  EXPIRED: 'EXPIRED',
  INFO_NOT_MATCHED: 'INFO_NOT_MATCHED',
  INVALID: 'INVALID',
  OTHER: 'OTHER',
  SCREENSHOT: 'SCREENSHOT',
}

const requiredDocuments = {
  BANK_STATEMENT: 'BANK_STATEMENT',
  BANK_VERIFICATION: 'BANK_VERIFICATION',
  BO1_DRIVERS_LICENSE: 'BO1_DRIVERS_LICENSE',
  BO1_GREEN_CARD: 'BO1_GREEN_CARD',
  BO1_PASSPORT: 'BO1_PASSPORT',
  BO1_PHYSICAL_ADDRESS_VERIFICATION: 'BO1_PHYSICAL_ADDRESS_VERIFICATION',
  BO1_SSN_CARD: 'BO1_SSN_CARD',
  BO1_US_VISA: 'BO1_US_VISA',
  BO2_DRIVERS_LICENSE: 'BO2_DRIVERS_LICENSE',
  BO2_GREEN_CARD: 'BO2_GREEN_CARD',
  BO2_PASSPORT: 'BO2_PASSPORT',
  BO2_PHYSICAL_ADDRESS_VERIFICATION: 'BO2_PHYSICAL_ADDRESS_VERIFICATION',
  BO2_SSN_CARD: 'BO2_SSN_CARD',
  BO2_US_VISA: 'BO2_US_VISA',
  BO3_DRIVERS_LICENSE: 'BO3_DRIVERS_LICENSE',
  BO3_GREEN_CARD: 'BO3_GREEN_CARD',
  BO3_PASSPORT: 'BO3_PASSPORT',
  BO3_PHYSICAL_ADDRESS_VERIFICATION: 'BO3_PHYSICAL_ADDRESS_VERIFICATION',
  BO3_SSN_CARD: 'BO3_SSN_CARD',
  BO3_US_VISA: 'BO3_US_VISA',
  BO4_DRIVERS_LICENSE: 'BO4_DRIVERS_LICENSE',
  BO4_GREEN_CARD: 'BO4_GREEN_CARD',
  BO4_PASSPORT: 'BO4_PASSPORT',
  BO4_PHYSICAL_ADDRESS_VERIFICATION: 'BO4_PHYSICAL_ADDRESS_VERIFICATION',
  BO4_SSN_CARD: 'BO4_SSN_CARD',
  BO4_US_VISA: 'BO4_US_VISA',
  BPAV: 'BPAV',
  BUSINESS_REGISTRATION: 'BUSINESS_REGISTRATION',
  BUSINESS_STATUS: 'BUSINESS_STATUS',
  CP_DRIVERS_LICENSE: 'CP_DRIVERS_LICENSE',
  CP_GREEN_CARD: 'CP_GREEN_CARD',
  CP_PASSPORT: 'CP_PASSPORT',
  CP_PHYSICAL_ADDRESS_VERIFICATION: 'CP_PHYSICAL_ADDRESS_VERIFICATION',
  CP_SSN_CARD: 'CP_SSN_CARD',
  CP_US_VISA: 'CP_US_VISA',
  FINANCIAL_DOCUMENTS: 'FINANCIAL_DOCUMENTS',
  INTERIM_FINANCIALS: 'INTERIM_FINANCIALS',
  TAX_ID: 'TAX_ID',
}

const driversLicenseInfo = (name, isAuthorizedSigner = false) => ({
  descriptionFooter: (
    <FormattedMessage
      defaultMessage='License cannot be expired.'
      id='sputnik.requiredDocumentMapper__3HQ0KI'
    />
  ),
  descriptionSubheader: (
    <FormattedMessage
      defaultMessage='Please provide a copy of {name}’s valid U.S. driver’s license.'
      id='sputnik.requiredDocumentMapper__ezHZpK'
      values={{
        name,
      }}
    />
  ),
  image: 'uploader-tile-drivers-license',
  title: (
    <FormattedMessage
      defaultMessage='{name}’s driver’s license'
      id='sputnik.requiredDocumentMapper__E1JpQ+'
      values={{ name: isAuthorizedSigner ? 'Authorized Signer' : name }}
    />
  ),
})

const passportInfo = (name) => ({
  descriptionFooter: (
    <FormattedMessage
      defaultMessage='Passport cannot be expired.'
      id='sputnik.requiredDocumentMapper__lYTYoC'
    />
  ),
  descriptionSubheader: (
    <FormattedMessage
      defaultMessage='Please provide a copy of {name}’s valid passport.'
      id='sputnik.requiredDocumentMapper__khsnVf'
      values={{
        name,
      }}
    />
  ),
  image: 'uploader-tile-passport',
  title: (
    <FormattedMessage
      defaultMessage='Passport | {name}'
      id='sputnik.requiredDocumentMapper__soswgO'
      values={{ name }}
    />
  ),
})

const greenCardInfo = (name) => ({
  descriptionSubheader: (
    <FormattedMessage
      defaultMessage='Please provide a copy of {name}’s green card.'
      id='sputnik.requiredDocumentMapper__zgfnQh'
      values={{
        name,
      }}
    />
  ),
  image: 'uploader-tile-green-card',
  title: (
    <FormattedMessage
      defaultMessage='Green card | {name}'
      id='sputnik.requiredDocumentMapper__bYevoo'
      values={{ name }}
    />
  ),
})

const visaInfo = (name) => ({
  descriptionFooter: (
    <FormattedMessage
      defaultMessage='Visa cannot be expired.'
      id='sputnik.requiredDocumentMapper__xEccEA'
    />
  ),
  descriptionSubheader: (
    <FormattedMessage
      defaultMessage='Please provide a copy of {name}’s valid U.S. visa.'
      id='sputnik.requiredDocumentMapper__Ogv3f7'
      values={{
        name,
      }}
    />
  ),
  image: 'uploader-tile-us-visa',
  title: (
    <FormattedMessage
      defaultMessage='US visa | {name}'
      id='sputnik.requiredDocumentMapper__P3RA0l'
      values={{ name }}
    />
  ),
})

const addressVerificationInfo = (name, isAuthorizedSigner = false) => ({
  description: (
    <FormattedMessage
      defaultMessage={`
        <list>
          * A lease agreement
          * Utility/phone bill
          * Insurance plan
          * Bank statement
        </list>
      `}
      id='sputnik.requiredDocumentMapper__6IFeUf'
      values={{ list: (content) => <FormattedList items={content[0]} /> }}
    />
  ),
  descriptionSubheader: (
    <FormattedMessage
      defaultMessage={`
        Please provide a third-party bill, agreement, or account statement that displays {name}’s name and physical address. Please provide one of the following:
      `}
      id='sputnik.requiredDocumentMapper__QPmRX8'
      values={{
        name,
      }}
    />
  ),
  image: 'uploader-tile-residential-address',
  title: (
    <FormattedMessage
      defaultMessage='{name}’s residential address'
      id='sputnik.requiredDocumentMapper__hsELVE'
      values={{ name: isAuthorizedSigner ? 'Authorized Signer' : name }}
    />
  ),
})

const ssnInfo = (name) => ({
  descriptionSubheader: (
    <FormattedMessage
      defaultMessage='Please provide a copy of {name}’s SSN card.'
      id='sputnik.requiredDocumentMapper__9OTiyV'
      values={{
        name,
      }}
    />
  ),
  image: 'uploader-tile-green-card',
  title: (
    <FormattedMessage
      defaultMessage='SSN card | {name}'
      id='sputnik.requiredDocumentMapper__zZUmnk'
      values={{ name }}
    />
  ),
})

export const requiredDocumentMapper = (documentName, formattedNames, accountNumberLastFour, desiredCredit) => {
  switch (documentName) {
    case requiredDocuments.BANK_STATEMENT: {
      return {
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage='Please provide a bank statement for account ending in ••••{accountNumberLastFour}. Must be dated within the last 90 days and include transaction history.'
            id='sputnik.requiredDocumentMapper__aLt+T6'
            values={{
              accountNumberLastFour,
            }}
          />
        ),
        image: 'uploader-tile-bank-statement',
        title: (
          <FormattedMessage
            defaultMessage='Bank statement'
            id='sputnik.requiredDocumentMapper__Ga2LEW'
          />
        ),
      }
    }
    case requiredDocuments.BANK_VERIFICATION: {
      return {
        description: (
          <FormattedMessage
            defaultMessage={`
              <list>
                * Include the physical address of your business
                * Include the full routing and account number provided in your application
                * Be dated within the last 90 days
              </list>
            `}
            id='sputnik.requiredDocumentMapper__U9UUV1'
            values={{ list: (content) => <FormattedList items={content[0]} /> }}
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage={`
              Please provide a voided check or account statement for the business account ending in ••••{accountNumberLastFour}. This must include the following:
            `}
            id='sputnik.requiredDocumentMapper__3jTB7Z'
            values={{
              accountNumberLastFour,
            }}
          />
        ),
        image: 'uploader-tile-business-bank-verification',
        title: (
          <FormattedMessage
            defaultMessage='Business bank verification'
            id='sputnik.requiredDocumentMapper__gLD4RV'
          />
        ),
      }
    }
    case requiredDocuments.FINANCIAL_DOCUMENTS: {
      const desiredCreditThousands = (desiredCredit || 0) / 100000
      let variableText
      if (desiredCreditThousands <= 250) {
        variableText = (
          <FormattedMessage
            defaultMessage={`
              <list>
                * Company prepared financial statements for the last two full years or
                * Business tax returns or tax transcripts from the last two years
              </list>
          `}
            id='sputnik.requiredDocumentMapper__4+iR36'
            values={{ list: (content) => <FormattedList items={content[0]} /> }}
          />
        )
      } else if (desiredCreditThousands <= 750) {
        variableText = (
          <FormattedMessage
            defaultMessage={`
              <list>
                * Business tax returns or tax transcripts for the last two years or
                * CPA reviewed or prepared statements for the last two full years
              </list>
          `}
            id='sputnik.requiredDocumentMapper__lFpG1d'
            values={{ list: (content) => <FormattedList items={content[0]} /> }}
          />
        )
      } else if (desiredCreditThousands <= 1500) {
        variableText = (
          <FormattedMessage
            defaultMessage={`
              <list>
                * CPA reviewed or prepared statements for the last two full years
              </list>
          `}
            id='sputnik.requiredDocumentMapper__n0jf4T'
            values={{ list: (content) => <FormattedList items={content[0]} /> }}
          />
        )
      } else {
        variableText = (
          <FormattedMessage
            defaultMessage={`
              <list>
                * Audited financial statements for the last two full years
              </list>
          `}
            id='sputnik.requiredDocumentMapper__obswEv'
            values={{ list: (content) => <FormattedList items={content[0]} /> }}
          />
        )
      }

      return {
        description: (
          <FormattedMessage
            defaultMessage={`
              {variableText}
            `}
            id='sputnik.requiredDocumentMapper__GbCZUW'
            values={{ variableText }}
          />
        ),
        descriptionFooter: (
          <FormattedMessage
            defaultMessage={`Your company name must be clearly visible in all documents.`}
            id='sputnik.requiredDocumentMapper__+tfGY0'
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage='Please provide one of the following financial documents:'
            id='sputnik.requiredDocumentMapper__F4nS8w'
          />
        ),
        image: 'uploader-tile-financial-documents',
        title: (
          <FormattedMessage
            defaultMessage='Financial documents'
            id='sputnik.requiredDocumentMapper__Wte4+P'
          />
        ),
      }
    }
    case requiredDocuments.INTERIM_FINANCIALS: {
      return {
        description: (
          <FormattedMessage
            defaultMessage={`
              <list>
                * Interim financials for the current year
              </list>
            `}
            id='sputnik.requiredDocumentMapper__Q/EGrL'
            values={{
              list: (content) => <FormattedList items={content[0]} />,
            }}
          />
        ),
        descriptionFooter: (
          <FormattedMessage
            defaultMessage={`
              Your company name must be clearly visible in all documents.
            `}
            id='sputnik.requiredDocumentMapper__7rbs7V'
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage='Please provide the following financial document:'
            id='sputnik.requiredDocumentMapper__s4yQF+'
          />
        ),
        image: 'uploader-tile-interim-financials',
        title: (
          <FormattedMessage
            defaultMessage='Interim financials'
            id='sputnik.requiredDocumentMapper__zaa3fA'
          />
        ),
      }
    }
    case requiredDocuments.BUSINESS_REGISTRATION:
      return {
        description: (
          <FormattedMessage
            defaultMessage={`
              <list>
                * A copy of your Certificate of Good Standing,
                * An unexpired business license,
                * Articles of Incorporation/Organization, 
                * Business Registration Partnership Agreement, or
                * Trust Agreement
              </list>
            `}
            id='sputnik.requiredDocumentMapper__kVDD5G'
            values={{
              list: (content) => <FormattedList items={content[0]} />,
            }}
          />
        ),
        descriptionFooter: (
          <FormattedMessage
            defaultMessage={`
              Documents must be dated within the last year and cannot be expired.
            `}
            id='sputnik.requiredDocumentMapper__uCjeet'
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage={`
              Please provide one of the following:
            `}
            id='sputnik.requiredDocumentMapper__bgldbi'
          />
        ),
        image: 'uploader-tile-business-registration',
        title: (
          <FormattedMessage
            defaultMessage='Business registration'
            id='sputnik.requiredDocumentMapper__DfaxEL'
          />
        ),
      }
    case requiredDocuments.BUSINESS_STATUS: {
      return {
        description: (
          <FormattedMessage
            defaultMessage={`
              <list>
                * A copy of your Certificate of Good Standing,
                * Partnership Agreement (for trusts, partnerships or sole proprietorships), or
                * Trust Agreement (for trusts, partnerships or sole proprietorships)
              </list>
            `}
            id='sputnik.requiredDocumentMapper__5MTa6/'
            values={{
              list: (content) => <FormattedList items={content[0]} />,
            }}
          />
        ),
        descriptionFooter: (
          <FormattedMessage
            defaultMessage={`
              Documents must be dated within the last year and cannot be expired.
            `}
            id='sputnik.requiredDocumentMapper__uCjeet'
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage={`
              Please provide one of the following:
            `}
            id='sputnik.requiredDocumentMapper__bgldbi'
          />
        ),
        image: 'uploader-tile-business-status',
        title: (
          <FormattedMessage
            defaultMessage='Business status'
            id='sputnik.requiredDocumentMapper__oK381n'
          />
        ),
      }
    }
    case requiredDocuments.TAX_ID: {
      return {
        description: (
          <FormattedMessage
            defaultMessage={`
            <list>
              * A letter from the IRS 
              * Your tax return
              * A W-9 form
            </list>
            `}
            id='sputnik.requiredDocumentMapper__n7qhZ3'
            values={{
              list: (content) => <FormattedList items={content[0]} />,
            }}
          />
        ),
        descriptionFooter: (
          <FormattedMessage
            defaultMessage={`
              Documents must be dated with the last year and cannot be expired.
            `}
            id='sputnik.requiredDocumentMapper__Oh2NeQ'
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage={`
            Please provide documentation that clearly displays your business's legal name, tax ID number, and preferably also includes your business physical address. You may provide one of the following:
            `}
            id='sputnik.requiredDocumentMapper__1lZYkk'
          />
        ),
        image: 'uploader-tile-tax-id',
        title: (
          <FormattedMessage
            defaultMessage='Tax ID'
            id='sputnik.requiredDocumentMapper__8OiKhP'
          />
        ),
      }
    }
    case requiredDocuments.BPAV: {
      return {
        description: (
          <FormattedMessage
            defaultMessage={`
              <list>
                * A utility bill
                * Insurance plan
                * Bank statement dated within 90 days
                * A fully executed lease agreement
              </list>
            `}
            id='sputnik.requiredDocumentMapper__8kmNn/'
            values={{ list: (content) => <FormattedList items={content[0]} /> }}
          />
        ),
        descriptionSubheader: (
          <FormattedMessage
            defaultMessage={`
              Please provide a third party bill, agreement, or account statement that displays both the business name and the address. Please provide one of the following:
            `}
            id='sputnik.requiredDocumentMapper__t0GwgE'
          />
        ),
        image: 'uploader-tile-business-physical-address',
        title: (
          <FormattedMessage
            defaultMessage='Business physical address'
            id='sputnik.requiredDocumentMapper__4IvIJZ'
          />
        ),
      }
    }
    case requiredDocuments.BO1_DRIVERS_LICENSE: {
      return driversLicenseInfo(formattedNames['beneficialOwner1Name'])
    }
    case requiredDocuments.BO2_DRIVERS_LICENSE: {
      return driversLicenseInfo(formattedNames['beneficialOwner2Name'])
    }
    case requiredDocuments.BO3_DRIVERS_LICENSE: {
      return driversLicenseInfo(formattedNames['beneficialOwner3Name'])
    }
    case requiredDocuments.BO4_DRIVERS_LICENSE: {
      return driversLicenseInfo(formattedNames['beneficialOwner4Name'])
    }
    case requiredDocuments.CP_DRIVERS_LICENSE: {
      return driversLicenseInfo(formattedNames['authorizedSignerName'], true)
    }
    case requiredDocuments.BO1_PASSPORT: {
      return passportInfo(formattedNames['beneficialOwner1Name'])
    }
    case requiredDocuments.BO2_PASSPORT: {
      return passportInfo(formattedNames['beneficialOwner2Name'])
    }
    case requiredDocuments.BO3_PASSPORT: {
      return passportInfo(formattedNames['beneficialOwner3Name'])
    }
    case requiredDocuments.BO4_PASSPORT: {
      return passportInfo(formattedNames['beneficialOwner4Name'])
    }
    case requiredDocuments.CP_PASSPORT: {
      return passportInfo(formattedNames['authorizedSignerName'])
    }
    case requiredDocuments.BO1_GREEN_CARD: {
      return greenCardInfo(formattedNames['beneficialOwner1Name'])
    }
    case requiredDocuments.BO2_GREEN_CARD: {
      return greenCardInfo(formattedNames['beneficialOwner2Name'])
    }
    case requiredDocuments.BO3_GREEN_CARD: {
      return greenCardInfo(formattedNames['beneficialOwner3Name'])
    }
    case requiredDocuments.BO4_GREEN_CARD: {
      return greenCardInfo(formattedNames['beneficialOwner4Name'])
    }
    case requiredDocuments.CP_GREEN_CARD: {
      return greenCardInfo(formattedNames['authorizedSignerName'])
    }
    case requiredDocuments.BO1_US_VISA: {
      return visaInfo(formattedNames['beneficialOwner1Name'])
    }
    case requiredDocuments.BO2_US_VISA: {
      return visaInfo(formattedNames['beneficialOwner2Name'])
    }
    case requiredDocuments.BO3_US_VISA: {
      return visaInfo(formattedNames['beneficialOwner3Name'])
    }
    case requiredDocuments.BO4_US_VISA: {
      return visaInfo(formattedNames['beneficialOwner4Name'])
    }
    case requiredDocuments.CP_US_VISA: {
      return visaInfo(formattedNames['authorizedSignerName'])
    }
    case requiredDocuments.BO1_PHYSICAL_ADDRESS_VERIFICATION: {
      return addressVerificationInfo(formattedNames['beneficialOwner1Name'])
    }
    case requiredDocuments.BO2_PHYSICAL_ADDRESS_VERIFICATION: {
      return addressVerificationInfo(formattedNames['beneficialOwner2Name'])
    }
    case requiredDocuments.BO3_PHYSICAL_ADDRESS_VERIFICATION: {
      return addressVerificationInfo(formattedNames['beneficialOwner3Name'])
    }
    case requiredDocuments.BO4_PHYSICAL_ADDRESS_VERIFICATION: {
      return addressVerificationInfo(formattedNames['beneficialOwner4Name'])
    }
    case requiredDocuments.CP_PHYSICAL_ADDRESS_VERIFICATION: {
      return addressVerificationInfo(formattedNames['authorizedSignerName'], true)
    }
    case requiredDocuments.BO1_SSN_CARD: {
      return ssnInfo(formattedNames['beneficialOwner1Name'])
    }
    case requiredDocuments.BO2_SSN_CARD: {
      return ssnInfo(formattedNames['beneficialOwner2Name'])
    }
    case requiredDocuments.BO3_SSN_CARD: {
      return ssnInfo(formattedNames['beneficialOwner3Name'])
    }
    case requiredDocuments.BO4_SSN_CARD: {
      return ssnInfo(formattedNames['beneficialOwner4Name'])
    }
    case requiredDocuments.CP_SSN_CARD: {
      return ssnInfo(formattedNames['authorizedSignerName'])
    }
  }
}

export const getDateString = (dayDiff = 0, monthDiff = 0, yearDiff = 0, originalDate) => {
  const date = originalDate ? new Date(originalDate) : new Date()
  date.setDate(date.getDate() + dayDiff)
  date.setMonth(date.getMonth() + monthDiff)
  date.setFullYear(date.getFullYear() + yearDiff)
  return date.toLocaleDateString()
}

export const getRejectionMessage = (rejectionReason) => {
  switch (rejectionReason) {
    case rejectionReasons.INFO_NOT_MATCHED: {
      return (
        <FormattedMessage
          defaultMessage='Unfortunately, the information in the document provided doesn’t match the information in your application. Please upload a document that matches the information provided in your application so we can continue our review process.'
          id='sputnik.requiredDocumentMapper__OoxL4b'
        />
      )
    }
    case rejectionReasons.BLURRY_UNREADABLE: {
      return (
        <FormattedMessage
          defaultMessage='Unfortunately, we weren’t able to accept your document due to the image being blurry or unreadable. Please upload a clearer image so we can continue our review process.'
          id='sputnik.requiredDocumentMapper__ioZ9O7'
        />
      )
    }
    case rejectionReasons.INVALID: {
      return (
        <FormattedMessage
          defaultMessage='Unfortunately, the document type provided is not valid for the verification needed. Please upload a valid document so we can continue our review process.'
          id='sputnik.requiredDocumentMapper__c5UAYB'
        />
      )
    }
    case rejectionReasons.EXPIRED: {
      return (
        <FormattedMessage
          defaultMessage='Unfortunately, the document presented exceeds the 90 day expiration limit. Please upload a document with a date of {date} or later.'
          id='sputnik.requiredDocumentMapper__15nL7/'
          values={{ date: getDateString(-90) }}
        />
      )
    }
    case rejectionReasons.SCREENSHOT: {
      return (
        <FormattedMessage
          defaultMessage='Unfortunately, BILL Spend & Expense is unable to accept screenshots. Please upload a file or picture of the document so we can continue our review process.'
          id='sputnik.requiredDocumentMapper__88MgvZ'
        />
      )
    }
    case rejectionReasons.OTHER: {
      return (
        <FormattedMessage
          defaultMessage='Unfortunately, there was an issue with the document provided. Please email us at {emailLink} or <intercomLink>start a chat with us</intercomLink> to resolve this matter.'
          id='sputnik.requiredDocumentMapper__CukI16'
          values={{
            emailLink: <TextLink href='mailto:help@getdivvy.com'>help@getdivvy.com</TextLink>,
            intercomLink: (...content) => <TextLink onClick={launchIntercom}>{content}</TextLink>,
          }}
        />
      )
    }
  }
}

export const getFormattedOwnerNames = (beneficialOwners = [], authorizedSigner = {}) => ({
  authorizedSignerName:
    authorizedSigner?.firstName && authorizedSigner?.lastName
      ? authorizedSigner?.firstName + ' ' + authorizedSigner?.lastName
      : 'authorized signer',
  beneficialOwner1Name: beneficialOwners?.[0]?.firstName + ' ' + beneficialOwners?.[0]?.lastName,
  beneficialOwner2Name: beneficialOwners?.[1]?.firstName + ' ' + beneficialOwners?.[1]?.lastName,
  beneficialOwner3Name: beneficialOwners?.[2]?.firstName + ' ' + beneficialOwners?.[2]?.lastName,
  beneficialOwner4Name: beneficialOwners?.[3]?.firstName + ' ' + beneficialOwners?.[3]?.lastName,
})
