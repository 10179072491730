import React from 'react'
import { useGetClassName } from '@divvy-web/hooks.usenameformatter'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/core'
import BasicButton from '@divvy-web/skylab.basicbutton'
import TextLink, { TEXT_LINK_SMALL } from '@divvy-web/skylab.textlink'
import { FormattedMessage } from '@divvy-web/i18n'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { replaceBreakableSpaces } from '../../components/utils'
import { PATHNAME_CONSTS } from '../../resources/constants'

const NotGoodFit = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const getClassName = useGetClassName('NotGoodFit')
  const { product } = useGetBillProduct()

  return (
    <>
      <div
        className='fs-unmask'
        css={mobileStyles}
      >
        <img
          alt='lady-at-desk'
          className={getClassName('lady-at-desk-img')}
          src='https://app.divvy.co/assets/illustrations/office_desk_10.svg'
        />
        <PageViewWrapper pageName='NotGoodFit'>
          <MiscPageWrapper
            headingStyles={headingStyles}
            pageName='NotGoodFit'
            pageSubTitle={
              <FormattedMessage
                defaultMessage='Based on your responses, we likely cannot offer a credit line to you at this time. We hope you return and re-apply {unbreakableString}.'
                id='sputnik.NotGoodFit__OO3PFn'
                values={{
                  unbreakableString: replaceBreakableSpaces('in the future'),
                }}
              />
            }
            pageTitle={
              <FormattedMessage
                defaultMessage='{product} may not be {unbreakableString}.'
                id='sputnik.NotGoodFit__n5V0Tj'
                values={{
                  product,
                  unbreakableString: replaceBreakableSpaces('a good fit for you right now'),
                }}
              />
            }
          >
            <TextLink
              className={getClassName('signup-link')}
              css={divvyLinkstyles}
              size={TEXT_LINK_SMALL}
              onClick={() =>
                navigate(
                  { pathname: PATHNAME_CONSTS.SIGN_UP_PATH, search },
                  { state: { referrer: PATHNAME_CONSTS.VERIFY_CODE_PATH } },
                )
              }
            >
              <FormattedMessage
                defaultMessage='Apply anyway'
                id='sputnik.NotGoodFit__F6+ppL'
              />
            </TextLink>
            <BasicButton
              className={getClassName('learn-divvy-btn')}
              css={buttonStyles}
            >
              <a
                href='https://help.getdivvy.com/en/articles/6282738-what-are-the-eligibility-requirements-for-divvy-s-credit-application'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FormattedMessage
                  defaultMessage='Learn About Eligibility'
                  id='sputnik.NotGoodFit__W4z/VU'
                />
              </a>
            </BasicButton>
          </MiscPageWrapper>
        </PageViewWrapper>
      </div>
    </>
  )
}

const mobileStyles = ({ mq, type }) => css`
  ${mq.xxSmallMaxWidth({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  })};

  .NotGoodFit-lady-at-desk-img {
    ${mq.xSmallMinWidth({ display: 'none' })};
    width: 260px;
    margin-top: var(--tri-space-600);
    margin-bottom: var(--tri-space-500);
  }

  .NotGoodFit-page-heading .heading-wrapper {
    ${mq.xxSmallMaxWidth({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0',
      marginTop: '0',
    })};

    ${mq.xxSmallMaxWidth({
      padding: 'var(--tri-space-300) var(--tri-space-300)',
    })}
  }

  .NotGoodFit-page-heading-title {
    ${mq.xxSmallMaxWidth(type.triFontDesktopTitleLargeEmphasis)};
  }

  .NotGoodFit-page-heading-subtitle {
    ${mq.xxSmallMaxWidth(type.triFontDesktopTitleSmall)};
  }

  .page-wrapper-content {
    ${mq.xxSmallMaxWidth({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 'var(--tri-space-300)',
    })};

    .NotGoodFit-signup-link {
      ${mq.xxSmallMaxWidth({
        marginLeft: '0',
        marginTop: 'calc(var(--tri-space-200) * -1)',
      })}
    }

    .NotGoodFit-learn-divvy-btn {
      ${mq.xxSmallMaxWidth({
        borderRadius: '0',
        bottom: '0',
        height: '88px',
        left: '0',
        marginLeft: '0',
        position: 'fixed',
        width: '100vw',
      })}
  }
`

const headingStyles = ({ mq, type }) => css`
  margin-top: 34vh;
  margin-left: var(--tri-space-2400);
  max-width: 720px;

  .NotGoodFit-page-heading-header {
    ${mq.xSmallMaxWidth({ padding: '0 var(--tri-space-100)' })}
  }

  .NotGoodFit-page-heading-title {
    ${type.triFontDesktopTitleLargeEmphasis}

    ${mq.xxSmallMaxWidth(type.triFontDesktopTitleMediumEmphasis)}
  }

  .NotGoodFit-page-heading-subtitle {
    margin-top: var(--tri-space-300);
    max-width: 550px;
    ${type.triFontDesktopTitleSmall}

    ${mq.xxSmallMaxWidth(type.triFontDesktopBodyMedium)}
  }
`
const divvyLinkstyles = css`
  margin-left: var(--tri-space-900);
  margin-top: var(--tri-space-200);
`

const buttonStyles = css`
  margin-left: var(--tri-space-900);
  margin-top: var(--tri-space-500);

  a {
    color: var(--tri-color-fill-primary);
  }

  a:link,
  a:hover,
  a:visited {
    text-decoration: none;
  }
`

export default NotGoodFit
