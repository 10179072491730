import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import { curry } from '@divvy-web/utils'
import { bool, func, node, string } from 'prop-types'
import { useAuth } from '../../auth'
import { GlobalRecaptchaStyle } from '../Page/PageGlobalStyles'
import PageHeading from '../PageHeading/PageHeading'

const MiscPageWrapper = ({ children, headingStyles, hideHeading, pageName, pageStyles, pageSubTitle, pageTitle }) => {
  const isApplicationSubmitted = pageName === 'application-submitted'
  const isNotGoodFit = pageName.toLowerCase() === 'notgoodfit'
  const { email: loginEmail } = useAuth()

  return (
    <div
      css={
        pageStyles
          ? (theme) => [pageStyles(theme), defaultStyles(theme)]
          : (theme) => [pageWrapperStyles({ isNotGoodFit }, theme), defaultStyles(theme)]
      }
    >
      <GlobalRecaptchaStyle />
      {hideHeading || (
        <PageHeading
          headingStyles={headingStyles}
          page={pageName}
          pageSubTitle={pageSubTitle}
          pageTitle={pageTitle}
        />
      )}
      <div className='page-wrapper-content'>{children}</div>
      {isApplicationSubmitted && (
        <div
          className='signed-in-info-tag'
          css={signinInfoTag}
        >
          <FormattedMessage
            defaultMessage='You are signed in as {loginEmail}'
            id='sputnik.MiscPageWrapper__HbqUP7'
            values={{ loginEmail }}
          />
        </div>
      )}
    </div>
  )
}

MiscPageWrapper.propTypes = {
  children: node.isRequired,
  headingStyles: func,
  hideHeading: bool,
  pageName: string.isRequired,
  pageStyles: func,
  pageSubTitle: node,
  pageTitle: node.isRequired,
}

MiscPageWrapper.defaultProps = {
  headingStyles: null,
  hideHeading: false,
  pageStyles: null,
  pageSubTitle: '',
}

const pageWrapperStyles = curry(
  ({ isNotGoodFit }, { mq }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${mq.xSmallMaxWidth({ height: 'auto' })}

    .field-container {
      max-width: 560px;
      width: calc(50% - 32px);
      ${mq.xSmallMaxWidth({ maxWidth: '100%', width: '100%' })}
    }

    .page-wrapper-content {
      flex-grow: 2;
      width: 100%;
      padding: 0 var(--tri-space-600) 0 var(--tri-space-1500);

      ${!isNotGoodFit &&
      mq.xSmallMaxWidth({
        height: 'auto',
        padding: 'var(--tri-space-600) var(--tri-space-400) 0 var(--tri-space-300)',
      })}

      ${isNotGoodFit &&
      mq.xxSmallMaxWidth({
        padding: 'var(--tri-space-600) var(--tri-space-400) 0 var(--tri-space-400)',
      })}
    }
  `,
)

const defaultStyles = ({ mq }) => css`
  .spinner-wrapper-form {
    flex-grow: 2;
    padding-bottom: var(--tri-space-2600);
    display: flex;
    justify-content: center;
    padding-right: var(--tri-space-900);
    ${mq.xSmallMaxWidth({ paddingRight: 0 })}
  }

  .toast-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: var(--tri-space-900);
    width: 75%;
    display: flex;
    justify-content: center;
    z-index: 2;
    ${mq.largeMaxWidth({ width: '100%' })}
  }

  .Toast {
    max-width: 480px;
    width: 100%;
    margin: 0;
    position: static;
    ${mq.xSmallMaxWidth({ marginBottom: 'var(--tri-space-1100)' })}
  }

  .Toast > .Toast-container {
    left: 0;
    right: 0;
    @media (max-width: 440px) {
      margin-left: var(--tri-space-250);
      margin-right: var(--tri-space-250);
    }
  }
`

const signinInfoTag = ({ mq, type }) => css`
  position: fixed;
  bottom: 112px;
  width: 100%;
  text-align: center;
  ${type.triFontDesktopBodySmall}
  ${mq.smallMinWidth({ display: 'none' })}
`

export default MiscPageWrapper
