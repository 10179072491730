/* global Intercom */

import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'

export const getFormattedZipcode = (value) => {
  if (value.length < 6) {
    return value.replace(/[^0-9]/g, '')
  } else {
    return value.slice(0, 5) + '-' + value.substring(5, 10).replace(/[^0-9]/g, '')
  }
}

export const getFormattedNumOfEmployees = (value) => {
  return value.replace(/[^0-9]/g, '')
}

export const getFormattedSsn = (value = '', masked = false) => {
  let v = value.replace(/[^0-9*]/g, '').slice(0, 9)
  if (masked && v.length > 4) {
    v = '*'.repeat(v.length - 4) + v.slice(-4)
  }
  if (v.length >= 6) {
    return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5, 9)}`
  } else if (v.length >= 4) {
    return `${v.slice(0, 3)}-${v.slice(3)}`
  }

  return v
}

export const formatDateString = (value, masked = false) => {
  let v = value.replace(/[^0-9*]/g, '').slice(0, 9)

  if (masked && v.length > 4) {
    v = '*'.repeat(v.length - 4) + v.slice(-4)
  }
  if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4, 8)}`
  } else if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`
  }

  return v
}

export const formatPhoneNumberFormField = (phoneNumber, isUsNumber = true) => {
  if (isUsNumber) return new AsYouType('US').input(phoneNumber)
  return new AsYouType().input(phoneNumber)
}

export const getE164FormattedPhoneNumber = (countryCode = 'US', phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }

  try {
    const number = parsePhoneNumber(phoneNumber, countryCode)
    if (number) {
      return number.format('E.164')
    } else {
      return phoneNumber
    }
  } catch (error) {
    return phoneNumber
  }
}

export const getFormattedPhoneNumber = (countryCode = 'US', phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }

  try {
    const number = parsePhoneNumber(phoneNumber, countryCode)
    if (number) {
      return countryCode === 'US' ? number.formatNational() : number.formatInternational()
    } else {
      return phoneNumber
    }
  } catch (error) {
    return phoneNumber
  }
}

export const getFormattedUSPhoneNumber = (value) => {
  if (!value) return ''
  const v = value.replace(/[^0-9*]/g, '').slice(0, 10)
  if (v.length > 6) {
    return `(${v.slice(0, 3)}) ${v.slice(3, 6)}-${v.slice(6, 10)}`
  }
  if (v.length > 3) {
    return `(${v.slice(0, 3)}) ${v.slice(3)}`
  }
  return v
}

export const getFormattedDate = (epoch, separator = '/') => {
  if (typeof epoch === 'number') {
    const date = new Date(epoch)
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear()
    return [month, day, year].join(separator)
  }
  return ''
}

export const formatDateToLocalString = (dateString) => {
  if (dateString) {
    return new Date(dateString).toLocaleString('default', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }
  return ''
}

export const dateStringToEpoch = (value) => {
  if (!value) return ''
  if (isNaN(Date.parse(value))) return value

  const initialValueDate = new Date(value)

  return initialValueDate.getTime()
}

export const getFormattedMaskedPassword = (value) => {
  return '*'.repeat(value.length)
}

export const getFormattedEIN = (value, masked = false, isSoleProprietorship = false) => {
  if (isSoleProprietorship) {
    return getFormattedSsn(value, masked)
  }
  let v = value.replace(/[^0-9*]/g, '')
  if (masked && v.length >= 3) {
    v = '*'.repeat(v.length - 4) + v.slice(-4)
  }
  if (v.length >= 3) {
    return `${v.slice(0, 2)}-${v.slice(2)}`
  }

  return v
}

export const getFormattedMaskableNumericString = (value) => {
  if (value.includes('*')) {
    return value
  }
  return value.replace(/\D/g, '')
}

export const epochMinutesFromNow = (minutes) => Math.floor(new Date().getTime() / 1000) + minutes * 60

export const launchIntercom = () => {
  if (Intercom) {
    Intercom('showNewMessage')
  }
}

const currencyFormatter = new Intl.NumberFormat('us-en', {
  currency: 'USD',
  minimumFractionDigits: 0,
  style: 'currency',
})

export const normalizeCurrency = (value) => {
  if (value === '$' || !value.length || !value) return ''

  const numRegex = /[^0-9]/g
  const newValue = Number(value.replace(numRegex, ''))
  return currencyFormatter.format(newValue)
}

export const normalizeSmartQuotesAndEmdashes = (value) => {
  if (value) {
    return value.replace('’', "'").replace('—', '-')
  }
  return value
}

export const getNestedValue = (data, nestedKey) => data && nestedKey?.split('.')?.reduce((acc, key) => acc[key], data)

// This will be moved out later. there is an architecture ticket out already -- AR-701
export const getUSStates = () => [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
]

/**
 *
 * @param {string} countryCode 2-letter country code e.g. 'US', 'CA', 'MX'
 * @return {string} Unicode country flag e.g. 🇺🇸, 🇨🇦, 🇲🇽
 */
export const getCountryFlag = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt())
  return String.fromCodePoint(...codePoints)
}

export const replaceBreakableSpaces = (string) => string.replace(/ /g, '\u00a0')
