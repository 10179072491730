import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Icon, { ICON_SIZE_REGULAR } from '@divvy-web/skylab.icon'
import TextLink, { TEXT_LINK_REGULAR } from '@divvy-web/skylab.textlink'
import React from 'react'
import { css } from '@emotion/core'

interface AgreementLinkProps {
  hasAgreedToOfferSummary: boolean
  onClick: (value: boolean) => void
}

const AgreementLink = ({ hasAgreedToOfferSummary, onClick }: AgreementLinkProps) => {
  const [getClassName, makeTestId] = useNameFormatter('AgreementLink')

  if (!hasAgreedToOfferSummary) {
    return (
      <div
        css={agreementLinkCss}
        data-testId={makeTestId('')}
      >
        <Icon
          className={getClassName('icon')}
          name='feedbackInProcess'
          size={ICON_SIZE_REGULAR}
        />
        <TextLink
          dataTestId={makeTestId('not-accepted')}
          disabled={false}
          isDark={false}
          size={TEXT_LINK_REGULAR}
          target='_blank'
          onClick={onClick}
        >
          <FormattedMessage
            defaultMessage='Review and agree to the offer summary'
            id='sputnik.AgreementLink__kVthTf'
          />
        </TextLink>
      </div>
    )
  }

  return (
    <div
      css={agreementLinkCss}
      data-testId={makeTestId('')}
    >
      <Icon
        className={getClassName('icon')}
        name='feedbackSuccess'
        size={ICON_SIZE_REGULAR}
      />
      <TextLink
        dataTestId={makeTestId('accepted')}
        disabled={false}
        isDark={false}
        size={TEXT_LINK_REGULAR}
        target='_blank'
        onClick={onClick}
      >
        <FormattedMessage
          defaultMessage='View offer summary'
          id='sputnik.AgreementLink__TM3TcF'
        />
      </TextLink>
    </div>
  )
}

const agreementLinkCss = css`
  .AgreementLink-icon {
    margin-right: var(--tri-space-150);
    margin-bottom: var(--tri-space-50);
  }
`

export default AgreementLink
