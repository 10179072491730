import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'

export const pageSubTitles = {
  authorizedSigner: (
    <>
      <div>
        <FormattedMessage
          defaultMessage='This individual is either a proprietor, general partner, or officer of the business with authority to enter into the agreement.'
          id='sputnik.pageSubTitles__dVAWf+'
        />
      </div>
      <br />
      <div>
        <FormattedMessage
          defaultMessage="Once you submit your application, Divvy will perform a soft inquiry of the business and the authorized signer's credit to determine the best credit limit for your business."
          id='sputnik.pageSubTitles__vafiIT'
        />
      </div>
    </>
  ),
  financeInfo: (
    <div>
      <FormattedMessage
        defaultMessage='Help us understand your company finances. The more we know, the better we can help you. Connect multiple accounts if needed to show your company’s financial situation. {LearnMoreLink}'
        id='sputnik.pageSubTitles__uvxaUT'
        values={{
          LearnMoreLink: (
            <a
              href='https://apply.divvy.co/'
              rel='noopener noreferrer'
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Learn More.'
                id='sputnik.pageSubTitles__FcLgSc'
              />
            </a>
          ),
        }}
      />
    </div>
  ),
}
