import React from 'react'
import { css } from '@emotion/core'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from '@divvy-web/i18n'
import { bool, string } from 'prop-types'
import { useAuth } from '../../auth'
import { LogoutButton } from '../../components'
import { PATHNAME_CONSTS } from '../../resources/constants'

const DashboardHeading = ({ bdcOrgId, canStartNewApplication, isMigrationSuccess }) => {
  const { email } = useAuth()
  const navigate = useNavigate()
  const goToSignup = () => navigate(PATHNAME_CONSTS.SIGN_UP_PATH)

  return (
    <div css={(theme) => headingCss({ isMigrationSuccess }, theme)}>
      <div className='titles fs-unmask'>
        <div className='main-title'>
          {isMigrationSuccess ? (
            <FormattedMessage
              defaultMessage='Success!'
              id='sputnik.DashboardHeading__UIDXdt'
            />
          ) : (
            <FormattedMessage
              defaultMessage='Your dashboard'
              id='sputnik.DashboardHeading__wfOvue'
            />
          )}
        </div>
        <div className='sub-title'>
          {isMigrationSuccess ? (
            <FormattedMessage
              defaultMessage="What's next?"
              id='sputnik.DashboardHeading__1G5vLM'
            />
          ) : (
            <FormattedMessage
              defaultMessage='Below are all of the applications submitted by {email}.'
              id='sputnik.DashboardHeading__LlpT6K'
              values={{ email }}
            />
          )}
        </div>
      </div>
      <div className='heading-buttons'>
        <LogoutButton
          bdcOrgId={bdcOrgId}
          type={BASIC_BUTTON_TYPE_FLAT}
        />
        {canStartNewApplication && (
          <BasicButton
            dataTestId='start-new-application'
            onClick={goToSignup}
          >
            <FormattedMessage
              defaultMessage='Begin new Application'
              id='sputnik.DashboardHeading__wWrngG'
            />
          </BasicButton>
        )}
      </div>
    </div>
  )
}

const headingCss = ({ isMigrationSuccess }, { mq, type }) => css`
  width: 100%;
  height: 216px;
  background-color: var(--tri-color-fill-accent-primary);
  display: flex;
  justify-content: space-between;
  padding: var(--tri-space-1100) var(--tri-space-1500) var(--tri-space-500) var(--tri-space-1500);

  ${mq.mediumMaxWidth({
    flexDirection: 'column',
    height: 'fit-content',
    padding: 'var(--tri-space-300) var(--tri-space-500)',
  })}

  .heading-buttons {
    display: flex;
    gap: var(--tri-space-400);
    max-height: 24px;

    ${mq.mediumMaxWidth({
      alignItems: 'center',
      backgroundColor: 'var(--tri-color-fill-primary)',
      bottom: 0,
      flexDirection: 'column',
      gap: 2,
      height: 'var(--tri-space-2000)',
      justifyContent: 'center',
      left: 0,
      padding: 'var(--tri-space-700) var(--tri-space-500)',
      position: 'fixed',
      width: '100%',
      zIndex: 2,
    })}
  }

  .heading-buttons > button {
    ${mq.mediumMaxWidth(type.triFontDesktopBodyMedium)}
    ${mq.mediumMaxWidth({
      background: 'none',
      color: 'var(--tri-color-fill-info-inverse)',
      textDecoration: 'underline',
      textTransform: 'none',
    })}
  }

  .heading-buttons > .logout-btn {
    min-width: fit-content;
  }

  .titles {
    width: fit-content;

    ${mq.mediumMaxWidth({ width: '100%' })}
  }

  .main-title {
    ${type.triFontDesktopTitleLargeEmphasis}
    ${mq.xSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
  }

  .sub-title {
    margin-top: ${isMigrationSuccess ? '0' : 'var(--tri-space-100)'};
    ${type.triFontDesktopBodyMedium}
    ${mq.xxSmallMaxWidth(type.triFontDesktopLabelXSmall)}
  }
`

export default DashboardHeading

DashboardHeading.defaultProps = { canStartNewApplication: false, isMigrationSuccess: false }

DashboardHeading.propTypes = {
  bdcOrgId: string,
  canStartNewApplication: bool,
  isMigrationSuccess: bool,
}
