import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { css } from '@emotion/core'
import ImageWrapper from '../../../components/ImageWrapper'
import useGetBillProduct from '../../../hooks/useGetBillProduct'
import useDeviceDetect from '../../../hooks/useDeviceDetect'

const TypesOfCreditInfoBox = () => {
  const [makeTestId, getClassName] = useNameFormatter('TypesOfCreditInfoBox')
  const { sideBarIcon } = useGetBillProduct(false)
  const { isMobile } = useDeviceDetect()
  return (
    <div css={wrapperCss}>
      {!isMobile && sideBarIcon}
      <div
        className={getClassName('wrapper')}
        data-testid={makeTestId('wrapper')}
      >
        <div className={getClassName('info-wrap')}>
          <div className={getClassName('title')}>
            <FormattedMessage
              defaultMessage='BILL Spend & Expense offers two different types of credit:'
              id='sputnik.TypesOfCreditInfoBox__3ULLF4'
            />
          </div>
          <div
            css={infoBlockCss}
            data-testid={makeTestId('standard-credit-container')}
          >
            <div className={getClassName('image-container')}>
              <ImageWrapper imageName='good-fit-page-standard-credit-line' />
            </div>
            <div className={getClassName('title-and-info-container')}>
              <div className={getClassName('subtitle')}>
                <FormattedMessage
                  defaultMessage='Standard credit line'
                  id='sputnik.TypesOfCreditInfoBox__WQCeHH'
                />
              </div>
              <div className={getClassName('info')}>
                <FormattedMessage
                  defaultMessage='We’ll determine your credit limit based on the info from your credit application.'
                  id='sputnik.TypesOfCreditInfoBox__1J9Z2H'
                />
              </div>
            </div>
          </div>
          <div
            css={infoBlockCss}
            data-testid={makeTestId('secured-line-container')}
          >
            <div className={getClassName('image-container')}>
              <ImageWrapper imageName='good-fit-page-secured-credit-line' />
            </div>
            <div className={getClassName('title-and-info-container')}>
              <div className={getClassName('subtitle')}>
                <FormattedMessage
                  defaultMessage='Secured credit line'
                  id='sputnik.TypesOfCreditInfoBox__Zm8wJX'
                />
              </div>
              <div className={getClassName('info')}>
                <FormattedMessage
                  defaultMessage='Choose your own credit limit via a cash security deposit which you can adjust at any time. '
                  id='sputnik.TypesOfCreditInfoBox__JWYBC1'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const wrapperCss = ({ mq }) => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  max-width: 498px;
  max-height: 100%;
  flex-shrink: 0;
  gap: var(--tri-space-1800);
  background: var(--tri-color-fill-accent-primary);
  ${mq.smallMaxWidth({ maxWidth: '100%' })};
  ${mq.xSmallMaxWidth({ background: 'none' })};

  .TypesOfCreditInfoBox-wrapper {
    ${mq.xSmallMaxWidth({ margin: '0 0 var(--tri-space-1100) 0' })};
  }

  .bill-logo {
    margin: var(--tri-space-500) var(--tri-space-500) 0 0;
    ${mq.smallMaxWidth({ display: 'none' })};
  }

  .TypesOfCreditInfoBox-info-wrap {
    display: flex;
    width: 418px;
    height: 468px;
    padding: var(--tri-space-500);
    margin: 0 var(--tri-space-500);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--tri-space-400);
    background: var(--tri-color-text-primary-inverse);
    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-accent-primary)',
      width: '100%',
      padding: 'var(--tri-space-300)',
      height: 'auto',
      gap: 'var(--tri-space-300)',
      margin: 'var(--tri-space-300) 0',
    })};
  }
`

const infoBlockCss = ({ mq, type }) => css`
  display: flex;

  .TypesOfCreditInfoBox-image-container {
    img {
      max-width: 80px;
      ${mq.xSmallMaxWidth({ maxWidth: '48px' })};
    }
  }

  .TypesOfCreditInfoBox-title {
    ${type.triFontDesktopTitleSmall};
    ${mq.xSmallMaxWidth(type.triFontDesktopBodyMediumEmphasis)};
  }

  .TypesOfCreditInfoBox-title-and-info-container {
    .TypesOfCreditInfoBox-subtitle {
      display: flex;
      height: 48px;
      flex-direction: column;
      justify-content: center;
      ${type.triFontDesktopBodyMediumEmphasis};
      ${(mq.xSmallMaxWidth(type.triFontDesktopBodySmallEmphasis), { height: '19px' })};
    }

    .TypesOfCreditInfoBox-info {
      ${type.triFontDesktopBodyMedium};
      ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)};
    }
  }
`

export default TypesOfCreditInfoBox
