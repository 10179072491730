import { css } from '@emotion/core'
import React from 'react'

const RecaptchaDisclaimer = () => (
  <p
    className='recaptcha-container fs-unmask'
    css={recaptchaDisclaimerCss}
  >
    This site is protected by reCAPTCHA and the Google{' '}
    <a
      className='link'
      href='https://policies.google.com/privacy'
      rel='noopener noreferrer'
      tabIndex='-1'
      target='_blank'
    >
      Privacy Policy
    </a>{' '}
    and{' '}
    <a
      className='link'
      href='https://policies.google.com/terms'
      rel='noopener noreferrer'
      tabIndex='-1'
      target='_blank'
    >
      Terms of Service
    </a>{' '}
    apply.
  </p>
)

const recaptchaDisclaimerCss = ({ type }) => css`
  color: var(--tri-color-text-tertiary);
  margin-top: var(--tri-space-500);
  ${type.triFontDesktopCaptionSmall}

  .link {
    color: var(--tri-color-fill-info-inverse);
  }
`

export default RecaptchaDisclaimer
