import { dissoc, find, pathEq, pipe } from '@divvy-web/utils'
import { JUNO_DATE_TYPE_UNIX_TIME, toJunoDate } from '@divvy-web/utils.date'
import { dateStringToEpoch } from '../components/utils'
import { MANUAL_ID, MANUAL_SOURCE } from '../pages/FinanceInfo/financeInfoConstants'
import { entityTypeOptions } from '../pages/SignUp/signUpConstants'
import { CreditApplicationStatus } from '../resources/constants'
import { epochToDate } from './mutationUtils'

export const getEntityTypeLabel = (entityTypeValue) => {
  return entityTypeOptions.reduce((accum, entityType) => {
    if (entityType?.value === entityTypeValue) {
      accum = entityType?.label
    }

    return accum
  }, '')
}

export const passportToggled = (person) => !!person?._passportToggle

export const getSelectedExternalId = (bankInfo) => {
  if (!bankInfo) return undefined
  const { accountHolderName, accountNumberLastFour, bankName, id, routingNumber, source } = bankInfo
  if (source === MANUAL_SOURCE && (!!bankName || !!accountNumberLastFour || !!routingNumber || !!accountHolderName)) {
    return MANUAL_ID
  } else if (source === MANUAL_SOURCE) {
    return undefined
  } else {
    return id
  }
}

export const bankInfoWithMaskedNumber = (bankInfo, allowFullyMasked = false) => {
  if (!bankInfo) return bankInfo

  const {
    accountAvailableBalance,
    accountHolderName,
    accountNickname,
    accountNumberLastFour,
    accountNumberLength,
    accountType,
    bankName,
    externalConnectionGuid,
    id,
    logoUrl,
    routingNumber,
    source,
  } = bankInfo
  const accountNumberMaskedString = accountNumberLength >= 4 ? '*'.repeat(accountNumberLength - 4) : ''
  const maskedAccountNumber = accountNumberLastFour
    ? `${accountNumberMaskedString}${accountNumberLastFour}`
    : allowFullyMasked
    ? '*'.repeat(10)
    : ''

  return {
    accountAvailableBalance,
    accountHolderName: accountHolderName ?? '',
    accountNickname,
    accountNumber: '',
    accountNumberDisplay: maskedAccountNumber,
    accountNumberLastFour: accountNumberLastFour ?? '',
    accountType,
    bankName: bankName ?? '',
    externalConnectionGuid: externalConnectionGuid ?? '',
    id,
    logoUrl: logoUrl ?? '',
    routingNumber: routingNumber ?? '',
    source,
  }
}

const cleanPersonForCompleteCheck = (person) => {
  const cleanedPerson = {
    address: {
      city: person?.address?.city,
      countryCode: person?.address?.countryCode,
      postalCode: person?.address?.postalCode,
      state: person?.address?.state,
      street: person?.address?.street,
    },
    dob: person?.dob,
    email: person?.email,
    firstName: person?.firstName,
    lastName: person?.lastName,
    phoneNumber: person?.phoneNumber,
  }

  if (person?.passportNumber) {
    return Object.assign(cleanedPerson, {
      passportCountry: person?.passportCountry,
      passportExpiration: person?.passportExpiration,
      passportNumber: person?.passportNumber,
    })
  } else {
    return Object.assign(cleanedPerson, {
      ssn: person?.ssn,
    })
  }
}

export const getIsPersonCompleted = (person) => {
  const cleanedPerson = cleanPersonForCompleteCheck(person)
  const flattenedAuthorizedSigner = flattenObject(cleanedPerson)
  const arrayedPerson = Object.values(flattenedAuthorizedSigner)
  const personComplete = !arrayedPerson.includes(null)
  return personComplete
}

const getEmptyAddress = () => ({
  city: '',
  countryCode: '',
  postalCode: '',
  state: '',
  street: '',
  streetAdditional: '',
})

export const getEmptyCompanyOwner = () => ({
  _passportToggle: false,
  addressCity: '',
  addressCountryCode: 'US',
  addressPostalCode: '',
  addressState: '',
  addressStreet: '',
  addressStreetAdditional: '',
  dob: null,
  dobDisplay: '',
  email: '',
  firstName: '',
  id: '',
  isAuthorizedSigner: false,
  lastName: '',
  passportCountry: '',
  passportExpiration: null,
  passportNumber: '',
  passportNumberDisplay: '',
  phoneNumber: '',
  ssn: '',
  ssnDisplay: '',
  title: '',
})

export const generateCompanyOwnersList = (formValues) => {
  return Array.from({ length: 4 }, getEmptyCompanyOwner).reduce((acc, owner, idx) => {
    if (!formValues[`owner${idx}_isCreated`]) {
      return acc
    }

    if (formValues[`owner${idx}_isAuthorizedSigner`]) {
      acc.unshift({ id: formValues.authorizedSignerId })
      return acc
    }

    for (const key of Reflect.ownKeys(owner)) {
      if (key.startsWith('address')) {
        if (!owner.address) {
          owner.address = getEmptyAddress()
        }

        const addressPart = key.slice(7)
        const addressKey = addressPart[0].toLowerCase() + addressPart.slice(1)
        owner.address[addressKey] = formValues[`owner${idx}_${key}`]
        Reflect.deleteProperty(owner, key)
      } else if (key.match(/dob$/i)) {
        owner[key] = epochToDate(formValues[`owner${idx}_${key}`])
      } else if (key.match(/expiration$/i)) {
        const expirationEpoch = dateStringToEpoch(formValues[`owner${idx}_${key}`])
        owner[key] = epochToDate(expirationEpoch)
      } else {
        owner[key] = formValues[`owner${idx}_${key}`]
      }
    }

    if (owner._passportToggle) {
      if (owner.passportNumber === '') {
        Reflect.deleteProperty(owner, 'passportNumber')
      }
      owner.ssn = ''
    } else {
      if (owner.ssn === '') {
        Reflect.deleteProperty(owner, 'ssn')
        owner.passportExpiration = null
      } else {
        owner.passportExpiration = ''
      }
      owner.passportCountry = ''
      owner.passportNumber = ''
    }

    Reflect.deleteProperty(owner, 'ssnDisplay')
    Reflect.deleteProperty(owner, 'passportNumberDisplay')
    Reflect.deleteProperty(owner, 'dobDisplay')
    Reflect.deleteProperty(owner, 'isAuthorizedSigner')

    acc.push(owner)

    return acc
  }, [])
}

export const flattenObject = (obj) => {
  const flattened = {}
  if (obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key]))
      } else {
        flattened[key] = obj[key]
      }
    })
  }

  return flattened
}

const getPathValue = (path) => pathEq(['value'], path)

export const getPathObject = (path, obj) => find(getPathValue(path), obj)

export const addressObjectFlatten = (obj) => {
  const capitalize = (str) => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  }
  const flatten = {}
  const [key] = Object.keys(obj)
  const nested = obj[key]
  const keys = Object.keys(nested)
  for (const subKey of keys) {
    flatten[`${key}${capitalize(subKey)}`] = nested[subKey]
  }
  return flatten
}

export const formatBusinessInfoForInput = (creditApp) => {
  const businessInfoData = creditApp?.businessInfo ?? {}
  const physicalAddress = scrubAddress(businessInfoData?.physicalAddress)
  const mailingAddress = scrubAddress(businessInfoData?.mailingAddress)

  const flattenedPhysical = addressObjectFlatten({ physicalAddress })
  const flattenedMailing = addressObjectFlatten({ mailingAddress })

  const mailingPhysicalAddressesEqual = JSON.stringify(physicalAddress) === JSON.stringify(mailingAddress)
  return {
    ...businessInfoData,
    ...flattenedMailing,
    ...flattenedPhysical,
    mailingPhysicalAddressesEqual,
  }
}

export const stringDateToEpoch = (string) => {
  if (string) {
    return toJunoDate(string + 'T00:00:00', JUNO_DATE_TYPE_UNIX_TIME) * 1000
  }
  return null
}

export const removeKeyFromObj = (keyName, obj) => {
  let scrubbedObj = obj

  for (const key in scrubbedObj) {
    if (key === keyName) {
      delete scrubbedObj?.[keyName]
    } else if (typeof scrubbedObj[key] === 'object') {
      removeKeyFromObj(keyName, scrubbedObj[key])
    }
  }

  return scrubbedObj
}

export const removeNonNumericCharacters = (value) => {
  if (!value) {
    return value
  }
  return value.replace(/\D+/g, '')
}

export const scrubAddress = pipe(dissoc('__typename'))

export const formatMXConnectionsList = (connection) => {
  const mxToExternalBankKeysMap = {
    bankAccountNumberLastFour: 'accountNumberLastFour',
    bankRoutingNumber: 'routingNumber',
    connectionType: 'source',
    id: 'id',
    institutionName: 'bankName',
  }

  const flatConnections = connection?.bankAccounts.map((current) => {
    const commonKeys = Object.keys(connection).filter((k) => k in current)

    const commonValuesObj = commonKeys.reduce((acc, currentKey) => {
      const value = current[currentKey] ? current[currentKey] : connection[currentKey]
      return {
        ...acc,
        [currentKey]: value,
      }
    }, {})

    const flatObj = {
      ...connection,
      ...current,
      ...commonValuesObj,
      accountNumberLength:
        current.accountNumberLength && current.accountNumberLength > 4 ? current.accountNumberLength : 10,
    }
    delete flatObj.bankAccounts

    return Object.keys(flatObj).reduce((acc, current) => {
      const key = mxToExternalBankKeysMap[current] ? mxToExternalBankKeysMap[current] : current
      return {
        ...acc,
        [key]: flatObj[current],
      }
    }, {})
  })
  return flatConnections.map((connection) =>
    bankInfoWithMaskedNumber(
      {
        ...connection,
        source: connection.source === 'MX' ? 'MONEY_MOVER' : connection.source,
      },
      true,
    ),
  )
}

// determines if the application has the correction status
export const isRequestingCorrection = (creditApp) => creditApp?.status === CreditApplicationStatus.CORRECTION_REQUESTED

export const matchEmail = (email1, email2) => email1 && email2 && email1.toLowerCase() === email2.toLowerCase()
