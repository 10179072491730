import React from 'react'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { css } from '@emotion/core'
import { bool } from 'prop-types'
import { FormattedMessage } from '@divvy-web/i18n'
import { useNavigate } from 'react-router-dom'
import { launchIntercom } from '../../components/utils'
import { PATHNAME_CONSTS } from '../../resources/constants'

const bananaPeel = 'https://app.divvy.co/assets/illustrations/banana-peel'
const defaultImg = `${bananaPeel}@1x.png`
const mediumImg = `${bananaPeel}@2x.png`
const largeImg = `${bananaPeel}@3x.png`

const DuplicateLeadErrorPage = ({ isUsingCurrentlyLoggedInEmail }) => {
  const navigate = useNavigate()
  const handleClick = () =>
    isUsingCurrentlyLoggedInEmail ? navigate(PATHNAME_CONSTS.DASHBOARD_PATH) : launchIntercom()

  return (
    <div css={pageStyle}>
      <img
        alt='banana-peel'
        src={defaultImg}
        srcSet={`${mediumImg}, ${largeImg}`}
      />
      <h1
        css={header}
        data-testid='message-duplicate-lead'
      >
        <FormattedMessage
          defaultMessage='Whoops!'
          id='sputnik.DuplicateLeadErrorPage__xWgftU'
        />
      </h1>
      {isUsingCurrentlyLoggedInEmail ? (
        <FormattedMessage
          defaultMessage="It looks like you've already started a credit application with this email address. Please complete the application you've already started before starting a new one. You can access your incomplete application on the dashboard."
          id='sputnik.DuplicateLeadErrorPage__mUohI8'
        />
      ) : (
        <FormattedMessage
          defaultMessage="It looks like you've already started a credit application with this email address. You'll need to complete the application you've already started before starting a new one. Please contact our customer support team to continue."
          id='sputnik.DuplicateLeadErrorPage__CQW0+3'
        />
      )}
      <div css={statusButtonWrapperCss}>
        <BasicButton
          className='secondary-button'
          type={BASIC_BUTTON_TYPE_OUTLINED}
          onClick={handleClick}
        >
          {isUsingCurrentlyLoggedInEmail ? (
            <FormattedMessage
              defaultMessage='Go to Dashboard'
              id='sputnik.DuplicateLeadErrorPage__LxSJOb'
            />
          ) : (
            <FormattedMessage
              defaultMessage='Contact us'
              id='sputnik.DuplicateLeadErrorPage__WnQ/AZ'
            />
          )}
        </BasicButton>
      </div>
    </div>
  )
}

DuplicateLeadErrorPage.propTypes = {
  isUsingCurrentlyLoggedInEmail: bool,
}

const header = ({ type }) => css`
  ${type.triFontDesktopTitleLargeEmphasis};
  text-align: center;
  margin-top: calc(var(--tri-space-800) * -1);
`

const pageStyle = () => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-area: content;
`

const statusButtonWrapperCss = css`
  display: flex;
  padding: var(--tri-space-300) 0 0;

  .secondary-button {
    margin-left: var(--tri-space-400);
  }
`

export default DuplicateLeadErrorPage
