import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import useDidMount from '@divvy-web/hooks.usedidmount'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormContext } from '@divvy-web/skylab.form'
import Spinner from '@divvy-web/skylab.spinner'
import { TEXT_LINK_SMALL } from '@divvy-web/skylab.textlink'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { curry, pickBy } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { array, bool, func, node, number, oneOfType, string } from 'prop-types'
import { Navigate, createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCanary } from '@divvy-web/canary'
import { GetApplicationAppVersionDocument } from 'src/pages/gql/GetApplicationAppVersion.gql'
import UpdateSavedSections from '../../pages/gql/mutations/UpdateSavedSections.gql'
import { getTokenFromStorage, useAuth } from '../../auth'
import { getCreditAppIdFromToken, getTypeFromToken } from '../../auth/jwt'
import { FormButtons } from '../../components'
import useRemainingCorrections from '../../hooks/useRemainingCorrections'
import { initialValues } from '../../pages/FormPages/initialValues'
import { getBillUrl } from '../../utils/billUtils'
import {
  AUTHORIZED_SIGNER,
  CreditApplicationStatus,
  LEGAL_BUSINESS_NAME,
  TAX_ID,
  EDITABLE_STATUSES,
  BENEFICIAL_OWNER_0,
  BENEFICIAL_OWNER_1,
  BENEFICIAL_OWNER_2,
  BENEFICIAL_OWNER_3,
  MAJOR_ACCOUNT_CHANGE,
  PATHNAME_CONSTS,
  PageMutationKey,
} from '../../resources/constants'
import { RawCreditAppContext } from '../../utils/RawCreditAppContext'
import { getSectionHasSaved } from '../../utils/dashboardStatusUtils'
import { matchEmail } from '../../utils/dataUtils'
import { logInfo, logWarning } from '../../utils/loggerUtils'
import { getPageMutationQuery, getPageMutationVariables, handleMutationError } from '../../utils/mutationUtils'
import IncompleteErrorModal from '../IncompleteErrorModal'
import IncompleteInfoBanner from '../IncompleteInfoBanner'
import InviteCollaboratorsLink from '../InviteCollaboratorsLink'
import PageHeading from '../PageHeading/PageHeading'
import PageViewWrapper from '../PageViewWrapper/PageViewWrapper'
import TimeoutWarningModal from '../TimeoutWarningModal'
import { ADVANCED_ONBOARDING } from '../../constants'
import { getReturnFlowLocalStorage } from '../../utils/neoRedirectUtils'

const FormPageWrapper = ({
  bdcOrgId,
  children,
  clickedSaveAndExit,
  hidePrevious,
  isMigration,
  mutationKey,
  pageCount,
  pageIndex,
  pageName,
  pageSubTitle,
  pageTitle,
  pages,
  refetchFormValues,
  setClickedSaveAndExit,
  setIsSaving,
  setShouldShowIncompleteBanner,
  shouldShowIncompleteBanner,
}) => {
  const navigate = useNavigate()
  const showToast = useToast()
  const { email: loginEmail, requestApplicantToken } = useAuth()
  const { appId } = useParams()
  const location = useLocation()
  const { pathname, search, state } = location
  const isUpdateMode = !!state?.isUpdateMode
  const { status: rawStatus } = useContext(RawCreditAppContext)
  const { formHasChanged, formIsValid, formValues, getFormValue, validationErrors } = useContext(FormContext)
  const searchParams = search ?? ''
  const {
    achAuthorization,
    creditTerms,
    electronicCommunicationTerms,
    email,
    majorityHolders,
    status,
    creditReportConsent,
  } = formValues
  const requestingCorrection = status === CreditApplicationStatus.CORRECTION_REQUESTED
  const { completedCorrections, remainingCorrections, removeFromRemainingCorrections } = useRemainingCorrections(
    appId,
    getFormValue('sectionsToCorrect'),
    requestingCorrection,
  )
  const isReviewAndSignPage = pageName === PageMutationKey.PAGE_REVIEW_AND_SIGN
  const isCompanyOwnersPage = pageName === PageMutationKey.PAGE_COMPANY_OWNERS
  const isBusinessInfoPage = pageName === PageMutationKey.PAGE_BUSINESS_INFO
  const hasEditableStatus = !status || EDITABLE_STATUSES.includes(status)
  const isReviewEdit = isReviewAndSignPage && !!getFormValue('_isReviewEdit')
  const isFirstPage = pageIndex === 0

  const { data: appVersionData, loading: isLoadingAppVersionData } = useQuery(GetApplicationAppVersionDocument, {
    variables: { creditApplicationId: appId },
    skip: !appId,
  })

  // for state-based disclosures
  const appVersion = appVersionData?.creditApplication?.appVersion
  const isAppVersion2 = appVersion === 2
  const reviewAndSignTermsAreAccepted = achAuthorization && creditTerms && electronicCommunicationTerms
  const creditReportConsentAccepted = creditReportConsent
  const termsAreAccepted = isAppVersion2 ? creditReportConsentAccepted : reviewAndSignTermsAreAccepted

  const loggedInUserIsAuthorizedSigner = matchEmail(email, loginEmail)
  const nextRoute = pages[pageIndex + 1]?.route
  const previousRoute = pages[Math.max(pageIndex - 1, 0)]?.route
  const [currentSearchParams] = useSearchParams()
  const editingSubPage = !!currentSearchParams.get('owner')
  const [navigatingToNextRoute, setNavigatingToNextRoute] = useState(null)
  const [pageLoadedOnce, setPageLoadedOnce] = useState(() => false)
  const [originalStatus, setOriginalStatus] = useState(() => false)
  const [exitBtnClickedOnce, setExitBtnClickedOnce] = useState(false)
  const [nextBtnClickedOnce, setNextBtnClickedOnce] = useState(false)
  const [showIncompleteModal, setShowIncompleteModal] = useState(false)
  const submissionPendingUrl = window.sessionStorage.getItem('submissionPendingUrl')
  const isMajorAccountChange = getFormValue('recordType') === MAJOR_ACCOUNT_CHANGE
  const isAdvancedOnboardingReturnEnabled = useCanary('advanced-onboarding-return-flow')
  // Keep track of major account changes and what has been updated to determine when to enable the submit button
  const majorAccountChanges = getFormValue('_majorAccountChanges')
  const hasBusinessInfoMajorAccountChange =
    majorAccountChanges?.includes(LEGAL_BUSINESS_NAME) || majorAccountChanges?.includes(TAX_ID)
  const hasAuthorizedSignerMajorAccountChange = majorAccountChanges?.includes(AUTHORIZED_SIGNER)
  const hasCompanyOwnersMajorAccountChange =
    majorAccountChanges?.includes(BENEFICIAL_OWNER_0) ||
    majorAccountChanges?.includes(BENEFICIAL_OWNER_1) ||
    majorAccountChanges?.includes(BENEFICIAL_OWNER_2) ||
    majorAccountChanges?.includes(BENEFICIAL_OWNER_3)
  const hasPersonMajorAccountChange = hasAuthorizedSignerMajorAccountChange || hasCompanyOwnersMajorAccountChange

  const isBusinessInfoMacCompleted = hasBusinessInfoMajorAccountChange
    ? getFormValue('_savedSections')?.includes('BUSINESS_INFO')
    : true
  const isAuthorizedSignerMacCompleted = hasAuthorizedSignerMajorAccountChange
    ? getFormValue('_savedSections')?.includes('AUTH_SIGNER')
    : true
  const isCompanyOwnersMacCompleted = hasPersonMajorAccountChange
    ? getFormValue('_savedSections')?.includes('COMPANY_OWNERS')
    : true

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageName])

  // set the original status once it's loaded and keep it up to date
  useEffect(() => {
    if (rawStatus) {
      setOriginalStatus(rawStatus)
    }
  }, [rawStatus, originalStatus])

  // Data coming back from mutations can update form values resulting in a rerender which disables execution of navigate
  // This effect ensures navigation after all other cache/state updates
  useEffect(() => {
    if (navigatingToNextRoute) {
      setNavigatingToNextRoute(null)
      if (isUpdateMode) {
        showToast(
          TOAST_TYPE_SUCCESS,
          <FormattedMessage
            defaultMessage='Successfully updated {title}.'
            id='sputnik.FormPageWrapper__6zkkYj'
            values={{
              title: pageName === 'financeInfo' ? 'Financial Information' : 'Company Owners',
            }}
          />,
          {
            autoHideDelay: 5000,
            css: toastCss,
            dataTestId: 'section-submission-success',
          },
        )
      }
      navigate(navigatingToNextRoute)
    }
  }, [isUpdateMode, navigate, navigatingToNextRoute, pageName, showToast])

  useEffect(() => {
    if (submissionPendingUrl && submissionPendingUrl.includes(appId)) {
      navigate({ pathname: submissionPendingUrl })
    }
  }, [appId, hasEditableStatus, navigate, search, submissionPendingUrl])

  useEffect(() => {
    if (!hasEditableStatus && !pageLoadedOnce && (!submissionPendingUrl || !submissionPendingUrl.includes(appId))) {
      logWarning({
        attributes: {
          action: 'redirect',
          message: appId,
          result: `Redirecting to the dashboard since the credit application status is ${status}`,
        },
        eventName: 'PageLoad',
      })

      navigate({ pathname: PATHNAME_CONSTS.DASHBOARD_PATH, search }, { state })
    }
  }, [hasEditableStatus, navigate, state, search, appId, status, pageLoadedOnce, submissionPendingUrl])

  // After all useEffects run, we can set the pageLoadedOnce to true.
  useDidMount(() => {
    setPageLoadedOnce(true)
  })

  const maybeRerequestApplicantToken = async () => {
    const token = getTokenFromStorage()
    if (getTypeFromToken(token) !== 'applicant_token' || getCreditAppIdFromToken(token) !== appId) {
      await requestApplicantToken(appId, true)
    }
  }

  const getNextButtonText = () => {
    if (isUpdateMode)
      return (
        <FormattedMessage
          defaultMessage='Update'
          id='sputnik.FormPageWrapper__BWpuKl'
        />
      )
    if (!isReviewAndSignPage) return null
    if (loggedInUserIsAuthorizedSigner)
      return (
        <FormattedMessage
          defaultMessage='Submit'
          id='sputnik.FormPageWrapper__wSZR47'
        />
      )
    return (
      <FormattedMessage
        defaultMessage='Send to signer'
        id='sputnik.FormPageWrapper__shdH4T'
      />
    )
  }

  const getIncompleteBannerText = () => {
    if (isUpdateMode) return 'update'
    if (!isReviewAndSignPage) return 'next'
    if (loggedInUserIsAuthorizedSigner) return 'submit'
    return 'send to signer'
  }

  const gqlMutation = getPageMutationQuery(mutationKey, loggedInUserIsAuthorizedSigner, isMajorAccountChange)
  const gqlValues = isReviewAndSignPage ? initialValues({}, 'creditApplication') : initialValues({}, pageName)

  Object.keys(gqlValues).forEach((key) => (gqlValues[key] = formValues?.[key]))

  const gqlPageValues = { [mutationKey]: gqlValues }
  const gqlVariables = getPageMutationVariables(mutationKey, gqlPageValues, appId, loggedInUserIsAuthorizedSigner)
  const [nextPath, setNextPath] = useState('')

  const filledValues = pickBy((v, _k) => !!v && v !== '+1', gqlValues)
  const formatErrors = pickBy((_, k) => Object.keys(filledValues).includes(k), validationErrors)
  const hasFormatErrors = formatErrors && !!Object.keys(formatErrors).length

  const correctionSection = pages[pageIndex].correctionSection
  const correctionRequired = correctionSection && remainingCorrections.includes(correctionSection)
  const correctionCompleted = correctionSection && completedCorrections.includes(correctionSection)
  const correctionRequestedForOtherSections =
    !isReviewAndSignPage && requestingCorrection && !correctionRequired && !correctionCompleted
  const isBeingSigned = isReviewAndSignPage && loggedInUserIsAuthorizedSigner

  const handleReviewAndSignSubmission = useCallback(() => {
    navigate({
      pathname: `/app/${appId}/section/processing-application`,
      search: createSearchParams({
        isMigration,
        originalStatus,
        pollingForStatusSince: new Date().getTime(),
      }).toString(),
    })
  }, [appId, isMigration, navigate, originalStatus])

  const getNewNextPath = useCallback(() => {
    const processApplication = isReviewAndSignPage && !isMigration && loggedInUserIsAuthorizedSigner
    if (isUpdateMode) return `/app/${appId}/section/review-and-sign`
    if (processApplication) return `/app/${appId}/section/processing-application`
    if (isReviewAndSignPage) return `/app/${appId}/section/success`
    return `/app/${appId}/section/${nextRoute}`
  }, [appId, isMigration, isReviewAndSignPage, isUpdateMode, loggedInUserIsAuthorizedSigner, nextRoute])

  const onMutationCompleted = useCallback(() => {
    if (requestingCorrection && correctionRequired) {
      removeFromRemainingCorrections(correctionSection)
    }
    const newNextPath = nextPath && nextPath !== pathname ? nextPath : getNewNextPath()
    let result, eventName

    if (isBeingSigned) {
      result = 'Successfully submitted the credit app'
      eventName = 'SignCreditApp'
    } else if (isReviewAndSignPage && !loggedInUserIsAuthorizedSigner) {
      result = 'Successfully sent the credit app to signer'
      eventName = 'SendToSigner'
    } else {
      result = `Sending user to path: ${newNextPath}`
      eventName = 'PageSave'
    }

    logInfo({
      attributes: {
        action: 'Success',
        desiredCreditFix: true,
        message: `Credit App ID: ${appId}`,
        result: result,
      },
      eventName: eventName,
    })

    if (isBeingSigned) {
      handleReviewAndSignSubmission()
    } else {
      setNavigatingToNextRoute({ pathname: newNextPath, search: searchParams })
    }
  }, [
    appId,
    correctionRequired,
    correctionSection,
    getNewNextPath,
    handleReviewAndSignSubmission,
    isBeingSigned,
    isReviewAndSignPage,
    loggedInUserIsAuthorizedSigner,
    nextPath,
    pathname,
    removeFromRemainingCorrections,
    requestingCorrection,
    searchParams,
  ])

  const onMutationError = (error) => {
    const action = isReviewAndSignPage
      ? loggedInUserIsAuthorizedSigner
        ? 'SignCreditApp'
        : 'SendToSigner'
      : `${pageName}Update`
    const eventName = isReviewAndSignPage ? 'ReviewAndSignSubmit' : 'PageSave'

    handleMutationError({
      action,
      appId,
      error,
      eventName,
      navigateToDashboard,
      refetchFormValues,
      showErrorToast,
    })
  }

  const [updatePageMutation, { loading }] = useMutation(gqlMutation, {
    ...gqlVariables,
    onCompleted: onMutationCompleted,
    onError: onMutationError,
  })

  const shouldRouteToBdc =
    getReturnFlowLocalStorage() === ADVANCED_ONBOARDING && bdcOrgId && isAdvancedOnboardingReturnEnabled
  const billBaseUrl = getBillUrl(bdcOrgId)
  const goToBill = () => {
    logInfo({
      attributes: {
        action: 'goToBill',
        result: `Sending user to path: ${billBaseUrl}/neo/frame/setup`,
      },
      eventName: 'RedirectToBill',
    })
    window.location.href = `${billBaseUrl}/neo/frame/setup`
  }

  const [updateSavedSections, { loading: sectionsUpdateLoading }] = useMutation(UpdateSavedSections, {
    onCompleted: onMutationCompleted,
    onError: onMutationError,
    variables: { creditApplicationId: appId, step: 4 },
  })

  const showErrorToast = useCallback(
    (msg) =>
      showToast(TOAST_TYPE_DANGER, msg, {
        autoHideDelay: 5000,
        css: toastCss,
        dataTestId: 'page-submission-fail',
      }),
    [showToast],
  )

  const navigateToDashboard = useCallback(
    () => navigate({ pathname: PATHNAME_CONSTS.DASHBOARD_PATH, search }, { state }),
    [state, search, navigate],
  )

  const handleSaveAndExit = async () => {
    logInfo({
      attributes: {
        action: 'exit',
        message: `Credit App ID: ${appId}`,
        result: `Sending user to path: /dashboard`,
      },
      eventName: 'NavigationButtonClick',
    })
    if (shouldRouteToBdc) {
      goToBill()
    } else if (isReviewAndSignPage) {
      navigate({ pathname: PATHNAME_CONSTS.DASHBOARD_PATH, search: searchParams })
    } else if (isCompanyOwnersPage && majorityHolders === 'yes') {
      setNextPath(PATHNAME_CONSTS.DASHBOARD_PATH)
      updateSavedSections()
    } else {
      await maybeRerequestApplicantToken()
      setNextPath(PATHNAME_CONSTS.DASHBOARD_PATH)
      updatePageMutation({
        variables: isCompanyOwnersPage
          ? { beneficialOwners: [], hasBeneficialOwner: false }
          : { target: 'SAVE_AND_EXIT' },
      })
    }
  }

  useEffect(() => {
    if (!clickedSaveAndExit && formIsValid) setShouldShowIncompleteBanner(false)
    if (clickedSaveAndExit && !hasFormatErrors) setShouldShowIncompleteBanner(false)
  }, [clickedSaveAndExit, formIsValid, hasFormatErrors, setShouldShowIncompleteBanner])

  useEffect(() => {
    // warn the user that they are about to leave the application without saving
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      return (event.returnValue = '')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const handleInvalidNextClick = () => {
    setClickedSaveAndExit(false)
    setShouldShowIncompleteBanner(true)
    if (!nextBtnClickedOnce) {
      setNextBtnClickedOnce(true)
    } else {
      setShowIncompleteModal(true)
    }
  }

  const handleInvalidSaveClick = () => {
    setClickedSaveAndExit(true)
    setShouldShowIncompleteBanner(true)
    if (!exitBtnClickedOnce) {
      setExitBtnClickedOnce(true)
    } else {
      setShowIncompleteModal(true)
    }
  }

  const handleNextClick = async () => {
    const newNextPath = getNewNextPath()
    setNextPath(newNextPath)

    logInfo({
      attributes: {
        action: 'next',
        message: `Credit App ID: ${appId}`,
        result: `Sending user to path: ${newNextPath}`,
      },
      eventName: 'NavigationButtonClick',
    })

    await maybeRerequestApplicantToken()

    if (isCompanyOwnersPage) {
      if (majorityHolders === 'yes') {
        // Mutations are handled by the subpage if there are company owners
        updateSavedSections().then(() => setNavigatingToNextRoute({ pathname: newNextPath, search: searchParams }))
      } else {
        updatePageMutation({
          onCompleted: onMutationCompleted,
          onError: onMutationError,
          variables: { beneficialOwners: [], hasBeneficialOwner: false },
        })
      }
      return
    }

    updatePageMutation()
    setNextBtnClickedOnce(false)
    setExitBtnClickedOnce(false)
  }

  const handlePreviousClick = () => {
    const previousPath = isFirstPage ? PATHNAME_CONSTS.DASHBOARD_PATH : `/app/${appId}/section/${previousRoute}`

    logInfo({
      attributes: {
        action: 'previous',
        message: `Credit App ID: ${appId}`,
        result: `Sending user to path: ${previousPath}`,
      },
      eventName: 'NavigationButtonClick',
    })

    navigate({ pathname: previousPath, search: searchParams })
    setNextBtnClickedOnce(false)
    setExitBtnClickedOnce(false)
  }

  useDidMount(() => {
    logInfo({
      attributes: {
        message: `Credit App ID: ${appId}`,
        result: `${pageName} loaded`,
      },
      eventName: 'PageLoad',
    })

    if (correctionRequestedForOtherSections) {
      logInfo({
        attributes: {
          message: `Credit App ID: ${appId}`,
          result: `${pageName} redirecting to reviewAndSign since no corrections needed`,
        },
        eventName: 'PageRedirect',
      })

      navigate({ pathname: `/app/${appId}/section/review-and-sign`, search: searchParams })
    }
  })

  useEffect(() => {
    setIsSaving(loading || sectionsUpdateLoading)
  }, [loading, sectionsUpdateLoading, setIsSaving])

  const pageSubTitleView = isBusinessInfoPage ? pageSubTitle(getFormValue('legalBusinessName')) : pageSubTitle
  const dsaVersion = getFormValue('dsaVersion')

  if (
    !isReviewAndSignPage &&
    formIsValid &&
    !formHasChanged &&
    getSectionHasSaved(formValues, pageIndex) &&
    state?.autoRedirectIndex === pageIndex
  ) {
    const path = getNewNextPath()
    return (
      <Navigate
        replace
        state={{ ...state, autoRedirectIndex: state?.autoRedirectIndex + 1 }}
        to={path}
      />
    )
  }

  if (!hasEditableStatus && !pageLoadedOnce) return <Spinner fullPage />

  const disableSubmitBtnForMac =
    isReviewAndSignPage &&
    isMajorAccountChange &&
    (!isBusinessInfoMacCompleted || !isAuthorizedSignerMacCompleted || !isCompanyOwnersMacCompleted)

  return (
    <PageViewWrapper
      inviteCollaborators
      dsaVersion={dsaVersion}
      pageName={pageName}
    >
      <section css={(theme) => formPageStyles({ isReviewAndSignPage }, theme)}>
        <div className='header-wrapper'>
          <PageHeading
            isFormPage
            dsaVersion={dsaVersion}
            page={pageName}
            pageCount={pageCount}
            pageIndex={pageIndex + 1}
            pageSubTitle={pageSubTitleView}
            pageTitle={pageTitle}
          />
        </div>
        <div className='content-wrapper'>
          {shouldShowIncompleteBanner && (
            <IncompleteInfoBanner
              buttonOption={getIncompleteBannerText()}
              isReviewAndSignPage={isReviewAndSignPage}
              isSaving={clickedSaveAndExit}
            />
          )}
          {children}
          <TimeoutWarningModal pageName={mutationKey} />
          <IncompleteErrorModal
            setShowModal={setShowIncompleteModal}
            showModal={showIncompleteModal}
          />
        </div>
        <div className='signed-in-info'>
          <div className='invite-collaborators'>
            <div className='invite-collaborators-link-web'>
              <InviteCollaboratorsLink />
            </div>
          </div>
          <div className='signed-as'>
            <FormattedMessage
              defaultMessage='You are signed in as {loginEmail}'
              id='sputnik.FormPageWrapper__HbqUP7'
              values={{ loginEmail }}
            />
          </div>
        </div>
      </section>

      <FormButtons
        disableExit={loading || editingSubPage || isReviewEdit || sectionsUpdateLoading}
        disableNext={
          editingSubPage ||
          isReviewEdit ||
          loading ||
          sectionsUpdateLoading ||
          disableSubmitBtnForMac ||
          !!(isReviewAndSignPage && loggedInUserIsAuthorizedSigner && !termsAreAccepted)
        }
        disablePrevious={loading || editingSubPage || sectionsUpdateLoading}
        disableSaveOnInvalid={false}
        exitButtonText={
          isReviewAndSignPage ? (
            <FormattedMessage
              defaultMessage='Exit'
              id='sputnik.FormPageWrapper__E2sGaF'
            />
          ) : null
        }
        handleExitClick={!isReviewAndSignPage && hasFormatErrors ? handleInvalidSaveClick : handleSaveAndExit}
        handleNextClick={formIsValid ? handleNextClick : handleInvalidNextClick}
        handlePreviousClick={handlePreviousClick}
        hidePrevious={hidePrevious || isUpdateMode}
        isUpdateMode={isUpdateMode}
        nextButtonText={getNextButtonText()}
        pageName={pageName}
        showExitSpinner={(loading || sectionsUpdateLoading) && nextPath === PATHNAME_CONSTS.DASHBOARD_PATH}
        showNextSpinner={(loading || sectionsUpdateLoading) && nextPath !== PATHNAME_CONSTS.DASHBOARD_PATH}
      />
    </PageViewWrapper>
  )
}

FormPageWrapper.propTypes = {
  bdcOrgId: string,
  children: node.isRequired,
  clickedSaveAndExit: bool.isRequired,
  hidePrevious: bool.isRequired,
  isMigration: bool,
  mutationKey: string,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageName: string.isRequired,
  pageSubTitle: oneOfType([node, func]),
  pageTitle: node.isRequired,
  pages: array.isRequired,
  refetchFormValues: func.isRequired,
  setClickedSaveAndExit: func.isRequired,
  setIsSaving: func,
  setShouldShowIncompleteBanner: func.isRequired,
  shouldShowIncompleteBanner: bool.isRequired,
}

FormPageWrapper.defaultProps = {
  isMigration: false,
  mutationKey: '',
  pageSubTitle: '',
  setIsSaving: () => {},
}

const formPageStyles = curry(
  ({ isReviewAndSignPage }, { mq, type }) => css`
    padding-bottom: var(--tri-space-1100);

    .content-wrapper {
      padding: 0 var(--tri-space-600) var(--tri-space-500) var(--tri-space-1500);
      ${mq.xSmallMaxWidth({
        marginTop: isReviewAndSignPage ? 'var(--tri-space-1500)' : 'var(--tri-space-300)',
        padding: '0 var(--tri-space-400) var(--tri-space-500) var(--tri-space-300)',
      })}
    }

    .signed-in-info {
      ${mq.smallMinWidth({ display: 'none' })}
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--tri-space-100);
      align-items: center;
      padding: 0 var(--tri-space-400) var(--tri-space-600) var(--tri-space-300);
    }

    .signed-as {
      text-align: center;
      ${type.triFontDesktopBodySmall};
    }
  `,
)

const toastCss = ({ mq }) => css`
  width: 75%;

  ${mq.largeMaxWidth({ width: '100%' })}
`

export default FormPageWrapper
