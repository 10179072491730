import React from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { bool, string } from 'prop-types'
import { FormattedMessage } from '@divvy-web/i18n'
import { useAuth } from '../../auth'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { reviewsAndProTips } from '../../resources/constants'
import ImageWrapper from '../ImageWrapper'
import InviteCollaboratorsLink from '../InviteCollaboratorsLink'
import ReviewCard from './ReviewCard'
import TipCard from './TipCard'

const ReviewsSideSection = ({ dsaVersion, inviteCollaborators, pageName }) => {
  const makeTestId = useMakeTestId('review-side-section')
  const { email } = useAuth()
  const isSignin = pageName.toLowerCase() === 'signin'
  const isGoodFitPage = pageName.toLowerCase() === 'goodfit'
  const isDivvyProgramFitPage = pageName.toLowerCase() === 'divvyprogramfit'
  const isApplicationSubmitted = pageName.toLowerCase() === 'application-submitted'
  const isNotGoodFit = pageName.toLowerCase() === 'notgoodfit'
  const isReviewAndSign = pageName.toLowerCase() === 'reviewandsign'
  const shouldShowAuthorizedSignerProTip = !(dsaVersion === 'crb_business_only' || dsaVersion === 'wex_business_only')
  const showOneColorImages = !isApplicationSubmitted && !isDivvyProgramFitPage

  const { product, sideBarIcon } = useGetBillProduct(false)
  const reviewAndTips = reviewsAndProTips(pageName, shouldShowAuthorizedSignerProTip, product)
  const showSideSectionContent = () => {
    return reviewAndTips.map((reviewOrTip, i) => {
      if (reviewOrTip.signature) {
        return (
          <ReviewCard
            key={'review-card-' + i}
            headerText={reviewOrTip?.headerText}
            reviewImageName={reviewOrTip?.reviewImageName}
            signature={reviewOrTip?.signature}
            stars={reviewOrTip?.stars}
          />
        )
      }

      if (reviewOrTip.imageName) {
        return (
          <div
            key={'image-card-' + i}
            className='large-image'
          >
            <ImageWrapper
              dataTestId={reviewOrTip.imageName}
              imageName={reviewOrTip.imageName}
              isSvg={showOneColorImages}
            />
          </div>
        )
      }

      if (reviewOrTip.bodyText) {
        return (
          <TipCard
            key={'tip-card-' + i}
            reviewOrTip={reviewOrTip}
          />
        )
      }
    })
  }

  return (
    <div
      className='fs-unmask'
      css={sectionCss({
        isApplicationSubmitted,
        isDivvyProgramFitPage,
        isGoodFitPage,
        isNotGoodFit,
        isReviewAndSign,
        isSignin,
        showOneColorImages,
      })}
    >
      <div
        className='reviews-section-wrapper'
        data-testid={makeTestId()}
      >
        {sideBarIcon}
        <div className='reviews-and-tips'>{showSideSectionContent()}</div>
        {inviteCollaborators && (
          <div className='signed-in-info'>
            {isApplicationSubmitted || (
              <div className='invite-collaborators'>
                <InviteCollaboratorsLink />
              </div>
            )}
            <div
              className='signed-as fs-mask'
              data-testid={makeTestId('signed-as')}
            >
              <FormattedMessage
                defaultMessage='You are signed in as {email}'
                id='sputnik.inviteCollaboratorsUtils__sy+pv5'
                values={{ email }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ReviewsSideSection.defaultProps = {
  dsaVersion: '',
}

ReviewsSideSection.propTypes = {
  dsaVersion: string,
  inviteCollaborators: bool,
  pageName: string,
}

export default ReviewsSideSection

const showNewColorImageStyles = ({ isGoodFitPage, isNotGoodFit, isReviewAndSign, isSignin }, { mq }) => css`
  .large-image {
    display: flex;
    position: fixed;

    ${isSignin &&
    `
      top: 3vw;
      right: -18%;
      width: 56vw;
      height: 130%;
    `}

    ${isSignin && mq.mediumMaxWidth({ display: 'none' })};

    ${isNotGoodFit &&
    `
      bottom: -30%;
      right: -18%;
      width: 70vw;
      height: 130%;
    `}

    ${isGoodFitPage &&
    `
      top: 8vw;
      right: 0;
      width: 50vw;
      height: 130%;
    `}

    ${isReviewAndSign &&
    `
      top: 6vh;
      right: -7%;
      height: 80%;
      width: 40vw;
    `}

    img {
      width: 100%;
    }
  }
`

const sectionCss = curry(
  (
    {
      isApplicationSubmitted,
      isDivvyProgramFitPage,
      isGoodFitPage,
      isNotGoodFit,
      isReviewAndSign,
      isSignin,
      showOneColorImages,
    },
    theme,
  ) => css`
    height: 100%;

    .reviews-section-wrapper {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0 var(--tri-space-500);
    }

    .review-wrap > .review-image {
      margin-top: -16%;
      display: flex;
      justify-content: flex-end;
    }

    .review-header {
      ${theme.type.triFontDesktopBodySmall}
    }

    .review-signature {
      ${theme.type.triFontDesktopCaptionLarge}
      display: flex;
      flex-direction: column;
    }

    .tip-header {
      ${theme.type.triFontDesktopLabelMediumEmphasis}
    }

    .tip-body {
      ${theme.type.triFontDesktopCaptionLarge}
    }

    .review,
    .pro-tip {
      border-radius: 8px;
      padding: var(--tri-space-300) var(--tri-space-500);
      display: flex;
      flex-direction: column;
      gap: var(--tri-space-200);
      justify-content: space-between;
      min-height: fit-content;
      background-color: var(--tri-color-fill-primary);
    }

    .review-wrap,
    .pro-tip {
      min-width: 272px;
      max-width: 640px;
      width: 85%;
    }

    ${showOneColorImages
      ? showNewColorImageStyles({ isGoodFitPage, isNotGoodFit, isReviewAndSign, isSignin }, theme)
      : `
        .large-image {
          ${!isDivvyProgramFitPage && 'display: flex'};
          align-items: center;
          justify-content: center;
          margin-top: ${isNotGoodFit ? 'var(--tri-space-200)' : '0'};
          padding-bottom: ${isSignin || isApplicationSubmitted ? 'var(--tri-space-2000)' : '0'};
          padding-right: ${isApplicationSubmitted ? 'var(--tri-space-500)' : '0'};
        }

        .large-image > img,
        .review-image > img {
          max-width: ${isSignin || isApplicationSubmitted || isNotGoodFit || isDivvyProgramFitPage ? '560px' : '360px'};
          min-width: ${isApplicationSubmitted ? '280px' : '240px'};
          width: ${isSignin || isApplicationSubmitted || isNotGoodFit || isDivvyProgramFitPage ? '75%' : '60%'};
          ${isSignin && theme.mq.xxLargeMinWidth({ width: '90%' })}
        }
      `}

    .reviews-and-tips {
      display: flex;
      height: 90%;
      flex-direction: column;
      padding-top: ${isSignin || isApplicationSubmitted || isGoodFitPage ? '0' : 'var(--tri-space-1400);'};
      justify-content: ${isSignin || isApplicationSubmitted || isGoodFitPage ? 'center' : 'flex-start'};
      align-items: ${isGoodFitPage ? 'flex-end' : 'flex-start'};
      gap: ${isGoodFitPage ? '5%' : 'var(--tri-space-800)'};
      ${isDivvyProgramFitPage && 'width: 100%'};
      ${isGoodFitPage && theme.mq.mediumMaxWidth({ display: 'none' })}
      ${isNotGoodFit && 'align-self: center;'}
    }

    @media (max-height: 440px) {
      .large-image {
        ${(isSignin || isApplicationSubmitted) && 'padding-bottom: 0;'}
      }

      .reviews-and-tips {
        padding-top: var(--tri-space-500);
        padding-bottom: var(--tri-space-500);
      }
    }

    .signed-in-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--tri-space-100);
      align-items: center;
      align-self: center;
      padding-bottom: var(--tri-space-400);
    }

    .signed-in-info > .signed-as {
      text-align: center;
      ${theme.type.triFontDesktopBodySmall}
    }

    .invite-collaborators > button > span {
      color: var(--tri-color-interactive-foreground-action-hover);
    }

    [class='StarFilled Icon-svg'] {
      color: var(--tri-color-fill-warning);
    }
  `,
)
