import React from 'react'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { css } from '@emotion/core'
import { FormattedMessage } from '@divvy-web/i18n'
import { launchIntercom } from '../../components/utils'

const bananaPeel = 'https://app.divvy.co/assets/illustrations/banana-peel'
const defaultImg = `${bananaPeel}@1x.png`
const mediumImg = `${bananaPeel}@2x.png`
const largeImg = `${bananaPeel}@3x.png`

const SubmissionErrorPage = () => {
  return (
    <div css={pageStyle}>
      <img
        alt='banana-peel'
        className='banana-peel-img'
        src={defaultImg}
        srcSet={`${mediumImg}, ${largeImg}`}
      />
      <h1
        css={header}
        data-testid='message-submission-error'
      >
        <FormattedMessage
          defaultMessage='Whoops!'
          id='sputnik.SubmissionErrorPage__xWgftU'
        />
      </h1>
      <div className='subtitle'>
        <FormattedMessage
          defaultMessage='It looks like there may have been an issue submitting your application.'
          id='sputnik.SubmissionErrorPage__2/vBeS'
        />
      </div>
      <div css={statusButtonWrapperCss}>
        <BasicButton
          className='secondary-button'
          type={BASIC_BUTTON_TYPE_OUTLINED}
          onClick={launchIntercom}
        >
          <FormattedMessage
            defaultMessage='Contact Us'
            id='sputnik.SubmissionErrorPage__hZOGLS'
          />
        </BasicButton>
      </div>
    </div>
  )
}

const header = ({ type }) => css`
  ${type.triFontDesktopTitleLargeEmphasis};
  text-align: center;
  margin-top: calc(var(--tri-space-800) * -1);
`

const pageStyle = ({ mq }) => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-area: content;
  .banana-peel-img {
    ${mq.xxSmallMaxWidth({ width: '90%' })}
  }

  .subtitle {
    ${mq.xxSmallMaxWidth({ padding: '0 var(--tri-space-500)', textAlign: 'center' })}
  }
`

const statusButtonWrapperCss = css`
  display: flex;
  padding: var(--tri-space-300) 0 0;
`

export default SubmissionErrorPage
