import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useParams } from 'react-router-dom'
import { TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { FormattedMessage } from '@divvy-web/i18n'
import { CreditApplication, UnderwritingOffer } from '_gql'
import { useAcceptTilaAgreementMutation } from '../../pages/gql/mutations/AcceptTilaAgreement.gql'
import { logError, logInfo } from '../../utils/loggerUtils'
import AgreementLink from './AgreementLink'
import ApplicationTerms from './ApplicationTerms'
import AgreementModal from './AgreementModal'

interface ApplicationAgreementsProps {
  creditApplication: {
    dsaVersion: string
    tila: {
      content: string
      meta: {
        signedAt: string
      }
    }
    recordType: string
    financeInfo: {
      bankInfo: {
        accountNumberLastFour: string
      }
    }
    authorizedSigner: CreditApplication['authorizedSigner']
  }
  hasAgreedToTerms: boolean
  offerType: UnderwritingOffer
  decisionId: string
  hasAgreedToOfferSummary: boolean
  onCheckboxClick: () => void
}

const ApplicationAgreements = ({
  creditApplication,
  hasAgreedToTerms,
  hasAgreedToOfferSummary,
  offerType,
  decisionId,
  onCheckboxClick,
}: ApplicationAgreementsProps) => {
  const { appId } = useParams()
  const showToast = useToast() as any
  const [isShowingAgreementModal, setIsShowingAgreementModal] = useState(false)
  const { dsaVersion, tila, recordType, financeInfo } = creditApplication
  const bankAccountLastFour = financeInfo?.bankInfo?.accountNumberLastFour
  const needsTilaAgreement = !!tila
  const tilaContent = tila?.content

  const [acceptTilaAgreement, { loading: isAcceptingTilaAgreement }] = useAcceptTilaAgreementMutation({
    onCompleted: () => {
      setIsShowingAgreementModal(false)

      logInfo({
        attributes: {
          action: 'acceptTilaAgreement',
          result: 'accepts agreement terms for application',
        },
        eventName: 'applicationAgreements',
      })

      showToast(
        TOAST_TYPE_SUCCESS,
        <FormattedMessage
          defaultMessage='You have successfully agreed to your offer summary'
          id='sputnik.ApplicationAgreements__VFXi23'
        />,
      )
    },
    onError: (e) => {
      setIsShowingAgreementModal(false)

      logError({
        attributes: {
          action: 'acceptTilaAgreement',
          message: e?.message,
          result: 'Error accepting agreement terms for application',
        },
        eventName: 'applicationAgreements',
      })

      showToast(
        TOAST_TYPE_DANGER,
        <FormattedMessage
          defaultMessage='There was an error agreeing to your offer summary.'
          id='sputnik.ApplicationAgreements__9fJ3od'
        />,
      )
    },
  })

  const handleAgreeToOfferSummary = () => {
    acceptTilaAgreement({
      variables: {
        salesforceCreditId: appId as string,
        underwritingDecisionUuid: decisionId,
        underwritingOfferType: offerType,
      },
      refetchQueries: ['GetCreditApplicationTilaInfo'],
    })
  }

  return (
    <div css={applicationAgreementsCss}>
      {needsTilaAgreement && (
        <AgreementLink
          hasAgreedToOfferSummary={hasAgreedToOfferSummary}
          onClick={() => setIsShowingAgreementModal(true)}
        />
      )}
      <ApplicationTerms
        bankAccountLastFour={bankAccountLastFour}
        dsaVersion={dsaVersion}
        hasAgreedToOfferSummary={hasAgreedToOfferSummary}
        hasAgreedToTerms={hasAgreedToTerms}
        recordType={recordType}
        onCheckboxClick={onCheckboxClick}
      />
      <AgreementModal
        hasAgreedToOfferSummary={hasAgreedToOfferSummary}
        isAcceptingTilaAgreement={isAcceptingTilaAgreement}
        isShowing={isShowingAgreementModal}
        tilaContent={tilaContent}
        onAgreeClick={handleAgreeToOfferSummary}
        onCancel={() => setIsShowingAgreementModal(false)}
      />
    </div>
  )
}

const applicationAgreementsCss = css`
  display: flex;
  flex-direction: column;
  gap: var(--tri-space-450);
  margin-top: var(--tri-space-200);
  margin-bottom: var(--tri-space-450);
`

export default ApplicationAgreements
