export const RE_CAPTCHA_SITE_KEY = '6LdoewAVAAAAAPxgUde6OIWl8XiUezKV-jFyo-hL'
export const SOLE_PROP = 'SOLE_PROPRIETORSHIP'
export const NATURAL_PERSON = 'NATURAL_PERSON'

const entries = {
  BANK_OR_CREDIT_UNION: 'Bank/Bank holding co/credit union',
  CORPORATIONS: 'Corporations',
  GOVERNMENT_AGENCY: 'Federal/State/Local government agency or authority',
  INSURANCE_COMPANY: 'Insurance company',
  INVESTMENT_COMPANY: 'Investment company/adviser',
  LIMITED_LIABILITY_COMPANY: 'Limited liability companies',
  NATURAL_PERSON: 'Personal',
  NON_PROFITS: 'Non-Profits',
  NON_STATUTORY_TRUST: 'Non-Statutory trust',
  PARTNERSHIP: 'Partnerships',
  PUBLIC_ACCOUNTING_FIRM: 'Public accounting firm',
  PUBLIC_COMPANY: 'Public company and majority owned affiliate',
  SOLE_PROPRIETORSHIP: 'Sole proprietorship',
  UNINCORPORATED_ASSOCIATION: 'Unincorporated association',
}

export const entityTypeOptions = []

for (const [value, label] of Object.entries(entries)) {
  entityTypeOptions.push({ label, value })
}
