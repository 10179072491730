import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { css } from '@emotion/core'
import { bool, func, node, number, oneOfType, string } from 'prop-types'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// TODO: Break each status button into its own component and put where it's used.
const StatusButtons = ({
  appId,
  dataTestId,
  hidePrimary,
  hideSecondary,
  isMigration,
  onPrimaryClick,
  onSecondaryClick,
  primaryLoading,
  primaryPath,
  primaryText,
  secondaryLoading,
  secondaryPath,
  secondaryText,
}) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = search ?? ''
  const [getClassName, makeTestId] = useNameFormatter('StatusButtons', { dataTestId })

  const handleSecondaryClick = () => {
    onSecondaryClick()

    secondaryPath &&
      navigate({
        pathname: `/app/${appId}/section/${secondaryPath}`,
        search: searchParams,
      })
  }

  const handlePrimaryClick = () => {
    !!onPrimaryClick && onPrimaryClick()

    primaryPath &&
      navigate(
        {
          pathname: `/app/${appId}/section/${primaryPath}`,
          search: searchParams,
        },
        {
          state: {
            autoRedirectIndex: primaryPath === 'business-info' ? 0 : undefined,
          },
        },
      )
  }

  const primaryButton = (
    <BasicButton
      className='primary-button'
      dataTestId={makeTestId('primary')}
      disabled={primaryLoading}
      showSpinner={primaryLoading}
      onClick={handlePrimaryClick}
    >
      {primaryText}
    </BasicButton>
  )

  return (
    <div
      className={getClassName('')}
      css={statusButtonWrapperCss}
      data-testid={makeTestId('')}
    >
      {!hidePrimary &&
        (isMigration ? (
          <a
            href='https://app.divvy.co/'
            rel='noopener noreferrer'
            target='_blank'
          >
            {primaryButton}
          </a>
        ) : (
          primaryButton
        ))}
      {!hideSecondary && (
        <BasicButton
          className='secondary-button'
          dataTestId={makeTestId('secondary')}
          disabled={secondaryLoading}
          showSpinner={secondaryLoading}
          type={BASIC_BUTTON_TYPE_OUTLINED}
          onClick={handleSecondaryClick}
        >
          {secondaryText}
        </BasicButton>
      )}
    </div>
  )
}

const statusButtonWrapperCss = css`
  display: flex;
  padding: var(--tri-space-300) 0 0;
  min-width: 480px;

  .secondary-button {
    margin-left: var(--tri-space-400);
  }
`

StatusButtons.defaultProps = {
  appId: '',
  hidePrimary: false,
  hideSecondary: false,
  onPrimaryClick: () => {},
  onSecondaryClick: () => {},
  primaryPath: null,
  secondaryPath: null,
}

StatusButtons.propTypes = {
  appId: oneOfType([number, string]),
  dataTestId: string,
  hidePrimary: bool,
  hideSecondary: bool,
  isMigration: bool,
  onPrimaryClick: func,
  onSecondaryClick: func,
  primaryLoading: bool,
  primaryPath: string,
  primaryText: node,
  secondaryLoading: bool,
  secondaryPath: string,
  secondaryText: node,
}

export default StatusButtons
