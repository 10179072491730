import React from 'react'
import { css } from '@emotion/core'
import useGetBillProduct from '../../hooks/useGetBillProduct'

const NaturalPersonInfo = () => {
  const cardImg = 'https://app.divvy.co/assets/illustrations/card-shadow'
  const defaultImg = `${cardImg}@1x.png`
  const mediumImg = `${cardImg}@2x.png`
  const largeImg = `${cardImg}@3x.png`

  const { product } = useGetBillProduct()

  return (
    <div css={naturalPersonCss}>
      <img
        alt='creditCard'
        className='cardImg'
        src={defaultImg}
        srcSet={`${mediumImg}, ${largeImg}`}
      />
      <div className='title'>Not eligible</div>
      <div className='subTitle'>
        {`Only companies organized and registered in the United States (such as C-corps, S-corps, LLCs, or LLPs) may
        apply for a ${product} Account.`}
      </div>
    </div>
  )
}

const naturalPersonCss = (theme) => css`
  .cardImg {
    width: 200px;
  }
  .title {
    ${theme.type.triFontDesktopTitleSmallEmphasis}
    padding-bottom: var(--tri-space-100);
  }
  .subTitle {
    ${theme.type.triFontDesktopBodySmall}
  }
`

export default NaturalPersonInfo
