import React from 'react'
import PropTypes from 'prop-types'
import { CanaryProvider } from '@divvy-web/canary'
import canaryConfig from './canaryConfig'
import useGetLaunchDarklyFlags from './useGetLaunchDarklyFlags'

export const FeatureFlagContext = React.createContext({ setRawLaunchDarklyFlags: () => {} })

const FeatureFlagProvider = ({ children }) => {
  const [launchDarklyFlags, setRawLaunchDarklyFlags] = useGetLaunchDarklyFlags()

  const allFlags = [...canaryConfig, ...launchDarklyFlags]

  return (
    <FeatureFlagContext.Provider value={{ setRawLaunchDarklyFlags }}>
      <CanaryProvider
        key='divvy'
        config={allFlags}
      >
        {children}
      </CanaryProvider>
    </FeatureFlagContext.Provider>
  )
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
}

export default FeatureFlagProvider
