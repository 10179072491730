import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { object, bool } from 'prop-types'
import React from 'react'
import { DropdownInput } from '../../components'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import IndustryAutoComplete from '../../components/IndustryAutoComplete/IndustryAutoComplete'
import NaturalPersonInfo from './NaturalPersonInfo'
import { entityTypeOptions } from './signUpConstants'

const SignUpBusinessFields = ({ initialMacBusinessValues, isMac, isPersonal, isReview, readOnly }) => {
  const [getClassName, makeTestId] = useNameFormatter('SignUp-Form', '')

  const { entity, industry, naicsCode } = initialMacBusinessValues || {}

  return (
    <div
      className='field-row-entity fs-unmask'
      css={signUpStyles({ isPersonal })}
    >
      <div
        className='field-container'
        id='entity'
      >
        {isPersonal && <NaturalPersonInfo />}
        <FormElementWrapper>
          <DropdownInput
            isSearchable
            alwaysShowError={readOnly}
            className={getClassName('entity-type')}
            dataTestId={makeTestId('entityType')}
            disabled={isMac && !!entity}
            items={entityTypeOptions}
            label='Entity type'
            name='entityType'
            placeholder='Entity type'
            readOnly={readOnly}
          />
        </FormElementWrapper>
      </div>
      <div className='field-container'>
        <IndustryAutoComplete
          disabled={isMac && !!industry && !!naicsCode}
          isReview={isReview}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

const signUpStyles = curry(
  ({ isPersonal }, theme) => css`
    display: flex;
    gap: var(--tri-space-800);
    ${theme.mq.xSmallMaxWidth({ flexDirection: 'column', gap: 'var(--tri-space-200)' })}

    .field-container {
      display: ${isPersonal ? 'none' : 'block'};
    }

    #entity {
      display: ${isPersonal ? 'flex' : 'block'};
      flex-direction: column;
      gap: var(--tri-space-600);
    }
  `,
)

SignUpBusinessFields.defaultProps = {
  initialMacBusinessValues: null,
  isMac: false,
  isPersonal: false,
  isReview: false,
  readOnly: false,
}

SignUpBusinessFields.propTypes = {
  initialMacBusinessValues: object,
  isMac: bool,
  isPersonal: bool,
  isReview: bool,
  readOnly: bool,
}

export default SignUpBusinessFields
