import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ToastProvider } from '@divvy-web/skylab.toast'
import { BrowserRouter as Router } from 'react-router-dom'
import { node } from 'prop-types'
import { IntlProvider } from '@divvy-web/i18n'
import { GlobalToastStyle } from '../src/components/Page/PageGlobalStyles'
import ThemeProvider from './components/theme-provider'
import client from './apollo/ApolloClient'
import { AuthProvider } from './auth'
import FeatureFlagProvider from './canary/FeatureFlagProvider'

const AppProvider = ({ children }) => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <AuthProvider apolloClient={client}>
          <FeatureFlagProvider>
            <ThemeProvider>
              <IntlProvider
                locale='en'
                textComponent='span'
              >
                <GlobalToastStyle />
                <ToastProvider>{children}</ToastProvider>
              </IntlProvider>
            </ThemeProvider>
          </FeatureFlagProvider>
        </AuthProvider>
      </ApolloProvider>
    </Router>
  )
}

AppProvider.propTypes = {
  children: node,
}

export default AppProvider
