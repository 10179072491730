import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import React from 'react'
import TextLink, { TEXT_LINK_REGULAR } from '@divvy-web/skylab.textlink'
import CheckboxInput from '@divvy-web/skylab.checkboxinput'
import { termsMapper } from '../../../pages/ReviewAndSign/utils'
import { URL } from '../../../resources/constants'

interface ApplicationTermsProps {
  bankAccountLastFour: string
  dsaVersion: string
  hasAgreedToTerms: boolean
  hasAgreedToOfferSummary: boolean
  recordType: string
  onCheckboxClick: (e: React.ChangeEvent) => void
}

const ApplicationTerms = ({
  bankAccountLastFour,
  dsaVersion,
  hasAgreedToTerms,
  recordType,
  hasAgreedToOfferSummary,
  onCheckboxClick,
}: ApplicationTermsProps) => {
  const [getClassName, makeTestId] = useNameFormatter('ApplicationTerms')
  const { BILL_PRIVACY_NOTICE, BILL_TERMS_AND_CONDITIONS } = URL
  const termLink = `${termsMapper[recordType][dsaVersion]}`

  return (
    <div
      css={termsCss}
      data-testId={makeTestId('')}
    >
      <CheckboxInput
        checked={hasAgreedToTerms}
        dataTestId={makeTestId('checkbox')}
        disabled={!hasAgreedToOfferSummary}
        isSmall={false}
        label={
          <FormattedMessage
            defaultMessage='I have read and agree to the following:'
            id='sputnik.ApplicationTerms__RNICJi'
          />
        }
        name='applicationAgreementTerms'
        onChange={onCheckboxClick}
      />
      <div className={getClassName('list')}>
        <ul>
          <li>
            <TextLink
              className={getClassName('creditTerms-link')}
              dataTestId={makeTestId('creditTerms-link')}
              href={termLink}
              size={TEXT_LINK_REGULAR}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Credit terms'
                id='sputnik.ApplicationTerms__bz/EmN'
              />
            </TextLink>
          </li>
          <li>
            <TextLink
              className={getClassName('terms-and-conditions-link')}
              dataTestId={makeTestId('terms-and-conditions-link')}
              href={BILL_TERMS_AND_CONDITIONS}
              size={TEXT_LINK_REGULAR}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Terms & conditions'
                id='sputnik.ApplicationTerms__bPyOFk'
              />
            </TextLink>
          </li>
          <li>
            <TextLink
              className={getClassName('privacy-policy-link')}
              dataTestId={makeTestId('privacy-policy-link')}
              href={BILL_PRIVACY_NOTICE}
              size={TEXT_LINK_REGULAR}
              target='_blank'
            >
              <FormattedMessage
                defaultMessage='Privacy policy'
                id='sputnik.ApplicationTerms__cPwv2c'
              />
            </TextLink>
          </li>
          <li>
            <FormattedMessage
              defaultMessage='<textlink>ACH authorization</textlink> (account ending in {bankAccountLastFour})'
              id='sputnik.ApplicationTerms__BQ+Tma'
              values={{
                textlink: (chunks) => (
                  <TextLink
                    className={getClassName('ach-auth-link')}
                    dataTestId={makeTestId('ach-auth-link')}
                    href={termLink}
                    size={TEXT_LINK_REGULAR}
                    target='_blank'
                  >
                    {chunks}
                  </TextLink>
                ),
                bankAccountLastFour,
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

const termsCss = ({ type }) => css`
  max-width: 100%;
  .ApplicationTerms-list {
    ul {
      margin-left: var(--tri-space-650);
    }
  }
  ${type.triFontDesktopBodyMedium}
  color: var(--tri-color-text-primary)

  .ApplicationTerm-list {
    margin-top: var(--tri-space-150);
    ${type.triFontDesktopBodySmall}
  }
`
export default ApplicationTerms
