import React from 'react'
import { useGetClassName } from '@divvy-web/hooks.usenameformatter'
import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { bool, func, node, number, oneOfType, string } from 'prop-types'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { reviewsAndProTips } from '../../resources/constants'
import ImageWrapper from '../ImageWrapper'
import TipCard from '../ReviewsSideSection/TipCard'
import { replaceBreakableSpaces } from '../utils'

const PageHeading = ({
  dsaVersion,
  headingStyles,
  isFormPage,
  page,
  pageCount,
  pageIndex,
  pageSubTitle,
  pageTitle,
}) => {
  const getClassName = useGetClassName(page + '-page-heading')
  const isDivvyProgramFitPage = page.toLowerCase() === 'divvyprogramfit'
  const shouldShowAuthorizedSignerProTip = !(dsaVersion === 'crb_business_only' || dsaVersion === 'wex_business_only')
  const { product } = useGetBillProduct()
  const protips = reviewsAndProTips(page, shouldShowAuthorizedSignerProTip, product).filter(
    (tip) => tip && !tip.imageName && !tip.signature,
  )

  return (
    <div className={getClassName('')}>
      <header
        className='heading-wrapper fs-unmask'
        css={headingStyles || defaultHeadingStyles({ isDivvyProgramFitPage })}
      >
        <div className={getClassName('header')}>
          <div
            className={getClassName('title')}
            data-testid='page-title'
          >
            {pageTitle}
          </div>
          {pageSubTitle && <div className={getClassName('subtitle')}>{pageSubTitle}</div>}
        </div>
        {isFormPage && (
          <div className={getClassName('index')}>{replaceBreakableSpaces(pageIndex + ' / ' + pageCount)}</div>
        )}
      </header>
      {protips.length > 0 && (
        <div
          className={getClassName('pro-tips-mobile')}
          css={protipsStyles({ isDivvyProgramFitPage })}
        >
          {protips.map((tip, i) => {
            return (
              <TipCard
                key={'tip-card-' + i}
                reviewOrTip={tip}
              />
            )
          })}
        </div>
      )}

      {page === 'application-submitted' && (
        <div
          className='application-submitted-image'
          css={reviewImageStyles}
        >
          <ImageWrapper imageName='mobile-upload-status-success' />
        </div>
      )}
    </div>
  )
}

PageHeading.propTypes = {
  dsaVersion: string,
  headingStyles: func,
  isFormPage: bool,
  page: string,
  pageCount: number,
  pageIndex: number,
  pageSubTitle: node.isRequired,
  pageTitle: oneOfType([node, string]).isRequired,
}

PageHeading.defaultProps = {
  dsaVersion: '',
  headingStyles: null,
  isFormPage: false,
  pageSubTitle: '',
}

const defaultHeadingStyles = curry(
  ({ isDivvyProgramFitPage }, { mq, type }) => css`
    display: flex;
    padding: var(--tri-space-1000) var(--tri-space-600) 0 var(--tri-space-1500);
    justify-content: space-between;

    div[class$='-page-heading-header'] {
      ${isDivvyProgramFitPage && 'width: 83%'}
    }

    ${mq.xSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-accent-primary)',
      padding: 'var(--tri-space-300) var(--tri-space-400) var(--tri-space-300) var(--tri-space-300)',
    })}
    div[class$='-page-heading-title'] {
      ${type.triFontDesktopTitleLargeEmphasis};
      margin-bottom: var(--tri-space-100);

      ${mq.xSmallMaxWidth(css`
        ${type.triFontDesktopTitleMediumEmphasis};
        margin-bottom: 0;
      `)}
    }

    div[class$='-page-heading-subtitle'] {
      margin-bottom: ${isDivvyProgramFitPage ? 'var(--tri-space-400)' : 'var(--tri-space-600)'};
      ${type.triFontDesktopTitleSmall};

      ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)}
      ${mq.xSmallMaxWidth({
        marginBottom: '0',
        marginTop: 'var(--tri-space-100)',
      })};
    }

    div[class$='-page-heading-index'] {
      ${mq.xSmallMaxWidth({ minWidth: 'fit-content' })}
    }
  `,
)

const protipsStyles = curry(
  ({ isDivvyProgramFitPage }, { mq, type }) => css`
    display: ${isDivvyProgramFitPage ? 'none' : 'flex'};
    margin-top: var(--tri-space-100);
    padding: var(--tri-space-300) var(--tri-space-350);
    background-color: var(--tri-color-fill-tertiary);
    flex-direction: column;
    gap: var(--tri-space-400);

    ${mq.smallMinWidth({ display: 'none' })};
    .tip-header {
      ${type.triFontDesktopLabelSmallEmphasis}
    }

    .tip-body {
      ${type.triFontDesktopCaptionLarge}
    }
  `,
)

const reviewImageStyles = ({ mq }) => css`
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: var(--tri-space-600);
  img {
    width: 160px;
  }

  ${mq.smallMinWidth({ display: 'none' })};
  ${mq.xSmallMaxWidth({ marginTop: 'var(--tri-space-500)' })};
`

export default PageHeading
