import React, { useState } from 'react'
import { bool } from 'prop-types'
import { FormattedMessage } from '@divvy-web/i18n'
import useOnClickOutside from '@divvy-web/hooks.useonclickoutside'
import BasicButton from '@divvy-web/skylab.basicbutton'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import { mobileButtonStyles, buttonStyles } from './customerAssistanceButtonStyles'

const chatAssistance = 'https://app.divvy.co/assets/illustrations/application-assistance'
const largeImg = `${chatAssistance}@3x.png`
const mediumImg = `${chatAssistance}@2x.png`
const defaultImg = `${chatAssistance}@1x.png`

const CustomerAssistanceButton = ({ shouldUseSpecialMobileStyles }) => {
  const { isMobile } = useDeviceDetect()
  const [isExpanded, setIsExpanded] = useState(false)
  const onClick = (e) => {
    setIsExpanded(!isExpanded)
    // Need to manually focus because Safari may not focus on button clicks
    e.target.focus()
  }

  const refOutsideClick = useOnClickOutside(() => {
    setIsExpanded(false)
  })

  return (
    <div
      ref={refOutsideClick}
      className='customer-assistance-wrapper'
      css={
        isMobile ? mobileButtonStyles({ shouldUseSpecialMobileStyles }) : buttonStyles({ shouldUseSpecialMobileStyles })
      }
    >
      <BasicButton
        className={`${isExpanded ? 'assistance-button-expanded' : ''} customer-assistance-button`}
        onClick={onClick}
      >
        <a
          className={`${isExpanded ? 'assistance-button-expanded' : ''} assistance-link`}
          href='https://help.bill.com/direct/s/?ref=marketing'
          rel='noopener noreferrer'
          target='_blank'
        >
          <div className='button-content-wrapper'>
            <div className='assistance-icon'>
              <img
                alt='Assistance button'
                className='chat-assistance-img'
                src={defaultImg}
                srcSet={`${mediumImg}, ${largeImg}`}
              />
            </div>
            <div className={`${isExpanded ? 'assistance-button-expanded' : ''} button-text`}>
              <FormattedMessage
                defaultMessage='Need assistance?'
                id='sputnik.CustomerAssistanceButton__oKsmJc'
              />
            </div>
          </div>
        </a>
      </BasicButton>
    </div>
  )
}

CustomerAssistanceButton.defaultProps = { shouldUseSpecialMobileStyles: false }

CustomerAssistanceButton.propTypes = { shouldUseSpecialMobileStyles: bool }

export default CustomerAssistanceButton
