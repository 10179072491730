import React, { useState } from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import { css } from '@emotion/core'
import { FormattedMessage } from '@divvy-web/i18n'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import ListItem from '@divvy-web/skylab.listitem'
import SearchInput from '@divvy-web/skylab.searchinput'

const ExistingAccountDrawer = ({ accounts, selectedAccount, setSelectedAccount }) => {
  const makeTestId = useMakeTestId('ExistingAccountDrawer')
  const [searchInput, setSearchInput] = useState('')
  const filteredCompanies = accounts?.filter((company) =>
    company?.name?.toLowerCase()?.includes(searchInput?.toLowerCase()),
  )

  const handleSelectAccountClick = (account) => {
    if (selectedAccount && selectedAccount === account) {
      setSelectedAccount('')
      return
    }

    setSelectedAccount(account)
  }

  return (
    <div css={pageStyles}>
      <div css={textContainerStyles}>
        <div css={textStyles}>
          <FormattedMessage
            defaultMessage='It looks like we found one or more accounts associated with this email address.'
            id='sputnik.ExistingAccountDrawer__HJCZ2p'
          />
        </div>
        <div css={textStyles}>
          <FormattedMessage
            defaultMessage='Continue with one of the following accounts, or start a new one.'
            id='sputnik.ExistingAccountDrawer__oUruAP'
          />
        </div>
        <div css={searchInputStyles}>
          <SearchInput
            initialValue={searchInput}
            onChange={setSearchInput}
          />
        </div>
      </div>
      {filteredCompanies?.map((company) => {
        const { id, name } = company

        return (
          <ListItem
            key={id}
            css={mappedAccountsStyles}
            dataTestId={makeTestId(`listitem-${id}`)}
            isSelected={selectedAccount === id}
            label={name}
            value={id}
            onClick={handleSelectAccountClick}
          />
        )
      })}
    </div>
  )
}

const mappedAccountsStyles = css`
  box-shadow: inset 0px -1px 0px var(--tri-color-text-secondary-inverse);
  height: 80px;
  display: flex;
  align-items: center;

  .ListItem-label {
    margin-left: var(--tri-space-200);
  }
  .ListItem-selected {
    margin-right: var(--tri-space-200);
  }
`
const searchInputStyles = css`
  width: 65%;
`
const pageStyles = css`
  margin-top: 0;
`
const textStyles = css`
  margin-bottom: var(--tri-space-400);
`
const textContainerStyles = css`
  margin: var(--tri-space-400);
`

ExistingAccountDrawer.propTypes = {
  accounts: arrayOf(
    shape({
      id: string,
      name: string,
    }),
  ),
  selectedAccount: string,
  setSelectedAccount: func,
}

export default ExistingAccountDrawer
