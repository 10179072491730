const safeParseJson = (string) => {
  try {
    return JSON.parse(string)
  } catch {
    return {}
  }
}

const safeParseString = (string) => {
  try {
    return string?.toString()
  } catch {
    return null
  }
}

const getJsonObjectFromLocalStorage = (key) => {
  const encodedObject = window.localStorage.getItem(key) || '{}'
  return safeParseJson(encodedObject)
}

const getFirstTouchValues = () => {
  return getJsonObjectFromLocalStorage('utm_values_ft')
}

const getLastTouchValues = () => {
  return getJsonObjectFromLocalStorage('utm_values_lt')
}

const getClickIds = () => {
  return getJsonObjectFromLocalStorage('bdc_click_ids')
}

const getPact = () => {
  return safeParseString(window.localStorage.getItem('bdc_pact'))
}

const getPreviousPage = () => {
  return window.sessionStorage.getItem('bdc_previous_page')
}

const getIsFirstTouchSession = () => {
  return !!window.sessionStorage.getItem('is_first_touch')
}

const removeQueryParams = (url) => {
  if (typeof url !== 'string') return null
  return url.replace(/\?{1}.*/, '')
}

export const getMarketoValues = () => {
  const firstTouchValues = getFirstTouchValues()
  const lastTouchValues = getLastTouchValues()
  const clickIds = getClickIds()
  const previousPage = getPreviousPage()
  const isFirstTouch = getIsFirstTouchSession()

  const values = {
    fbclid: clickIds.fbclid,
    ftAffiliateId: firstTouchValues.affiliateId,
    ftChannelId: firstTouchValues.channelId,
    ftCurrentUrl: isFirstTouch ? removeQueryParams(window.location.href) : null,
    ftDevice: firstTouchValues.device,
    ftLandingPage: removeQueryParams(firstTouchValues.landingPage),
    ftOfferId: firstTouchValues.offerId,
    ftPlatformId: firstTouchValues.platformId,
    ftPpcPlacement: firstTouchValues.ppcPlacement,
    ftPreviousPage: isFirstTouch ? removeQueryParams(previousPage) : null,
    ftReferrer: removeQueryParams(firstTouchValues.referrer),
    ftTimestamp: firstTouchValues.timestamp,
    ftUtmCampaign: firstTouchValues.utmCampaign,
    ftUtmContent: firstTouchValues.utmContent,
    ftUtmMedium: firstTouchValues.utmMedium,
    ftUtmSource: firstTouchValues.utmSource,
    ftUtmTerm: firstTouchValues.utmTerm,
    gclid: clickIds.gclid,
    isSelfServe: true,
    liclid: clickIds.liclid,
    ltAffiliateId: lastTouchValues.affiliateId,
    ltChannelId: lastTouchValues.channelId,
    ltCurrentUrl: removeQueryParams(window.location.href),
    ltDevice: lastTouchValues.device,
    ltLandingPage: removeQueryParams(lastTouchValues.landingPage),
    ltOfferId: '7014x000000eWUtAAM', // This is static for sputnik
    ltPlatformId: lastTouchValues.platformId,
    ltPpcPlacement: lastTouchValues.ppcPlacement,
    ltPreviousPage: removeQueryParams(previousPage),
    ltReferrer: removeQueryParams(lastTouchValues.referrer),
    ltTimestamp: lastTouchValues.timestamp,
    ltUtmCampaign: lastTouchValues.utmCampaign,
    ltUtmContent: lastTouchValues.utmContent,
    ltUtmMedium: lastTouchValues.utmMedium,
    ltUtmSource: lastTouchValues.utmSource,
    ltUtmTerm: lastTouchValues.utmTerm,
    msclkid: clickIds.msclkid,
    pact: getPact(),
  }

  return Object.fromEntries(Object.entries(values).filter(([_, value]) => value !== null && value !== undefined))
}
