import React, { useContext } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormCheckboxInput, FormContext } from '@divvy-web/skylab.form'
import TextLink from '@divvy-web/skylab.textlink'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { URL } from '../../resources/constants'
import { termsMapper } from './utils'

const ReviewTerms = () => {
  const { formValues, setFormValue } = useContext(FormContext)
  const { achAuthorization, creditTerms, dsaVersion = '', electronicCommunicationTerms, recordType = '' } = formValues
  const { BILL_PRIVACY_NOTICE, BILL_TERMS_AND_CONDITIONS, BILL_REWARDS_TERMS } = URL
  const { product } = useGetBillProduct()

  const termLink = `${termsMapper[recordType][dsaVersion]}`

  const handleTermsClick = (e) => {
    const { name } = e.target
    setFormValue(name, !!e?.target?.checked)
  }

  return (
    <div className='fs-unmask'>
      <div>
        <FormCheckboxInput
          checkboxLabel={
            <FormattedMessage
              defaultMessage='I have read and agree to {product} {termsAndConditions}, {privacyPolicy}, and {rewardTerms}.'
              id='sputnik.ReviewTerms__mq3wfz'
              values={{
                product,
                privacyPolicy: (
                  <span className='privacy-notice-link'>
                    <TextLink
                      dataTestId='privacy-policy'
                      href={BILL_PRIVACY_NOTICE}
                      target='_blank'
                    >
                      <FormattedMessage
                        defaultMessage='Privacy Policy'
                        id='sputnik.ReviewTerms__vx0nkZ'
                      />
                    </TextLink>
                  </span>
                ),
                termsAndConditions: (
                  <span className='terms-link'>
                    <TextLink
                      dataTestId='terms-and-conditions'
                      href={BILL_TERMS_AND_CONDITIONS}
                      target='_blank'
                    >
                      <FormattedMessage
                        defaultMessage='Terms & Conditions'
                        id='sputnik.ReviewTerms__ySSFAT'
                      />
                    </TextLink>
                  </span>
                ),
                rewardTerms: (
                  <span className='reward-terms-link'>
                    <TextLink
                      dataTestId='reward-terms'
                      href={BILL_REWARDS_TERMS}
                      target='_blank'
                    >
                      <FormattedMessage
                        defaultMessage='Reward Terms'
                        id='sputnik.ReviewTerms__W+OYdH'
                      />
                    </TextLink>
                  </span>
                ),
              }}
            />
          }
          checked={!!creditTerms}
          dataTestId='terms-agreed'
          errorCaption=''
          name='creditTerms'
          onChange={handleTermsClick}
        />
      </div>
      <div>
        <FormCheckboxInput
          checkboxLabel={
            <FormattedMessage
              defaultMessage='I have read and agree to {creditTerms} including receiving communication electronically.'
              id='sputnik.ReviewTerms__raY0A8'
              values={{
                creditTerms: (
                  <span className='credit-terms'>
                    <TextLink
                      dataTestId='credit-terms'
                      href={termLink}
                      target='_blank'
                    >
                      <FormattedMessage
                        defaultMessage='Credit Terms'
                        id='sputnik.ReviewTerms__YM/Kw3'
                      />
                    </TextLink>
                  </span>
                ),
              }}
            />
          }
          checked={!!electronicCommunicationTerms}
          dataTestId='electronic-communication-agreed'
          errorCaption=''
          name='electronicCommunicationTerms'
          onChange={handleTermsClick}
        />
      </div>
      <div>
        <FormCheckboxInput
          checkboxLabel={
            <FormattedMessage
              defaultMessage='I have read and agree to the {achAuthorization}.'
              id='sputnik.ReviewTerms__deHP8i'
              values={{
                achAuthorization: (
                  <span className='terms-link'>
                    <TextLink
                      dataTestId='ach-authorization'
                      href={termLink}
                      target='_blank'
                    >
                      <FormattedMessage
                        defaultMessage='ACH authorization'
                        id='sputnik.ReviewTerms__BQ+Tma'
                      />
                    </TextLink>
                  </span>
                ),
              }}
            />
          }
          checked={!!achAuthorization}
          dataTestId='ach-authorization-agreed'
          errorCaption=''
          name='achAuthorization'
          onChange={handleTermsClick}
        />
      </div>
    </div>
  )
}

export default ReviewTerms
