import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT, BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { FormCheckboxInput, FormContext, FormTextInput } from '@divvy-web/skylab.form'
import { css } from '@emotion/core'
import { bool, func, number, string } from 'prop-types'
import { useParams } from 'react-router-dom'
import { MaskedFormInput } from '../../components'
import { AddressFormFields } from '../../components/AddressForm'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import FormEmailInput from '../../components/FormInputs/FormEmailInput'
import PhoneNumberInput from '../../components/FormInputs/PhoneNumberInput'
import PassportInfo from '../../components/PassportInfo'
import { getUSStates, normalizeSmartQuotesAndEmdashes } from '../../components/utils'
import useRemainingCorrections from '../../hooks/useRemainingCorrections'
import { useUpdateCompanyOwners } from '../../hooks/useUpdateCompanyOwners'
import GetCountryCodes from '../gql/GetCountryCodes.gql'
import { CreditApplicationStatus } from '../../resources/constants'
import { countriesMapper } from '../../utils/countryUtils'
import { logInfo } from '../../utils/loggerUtils'
import { getShouldAutoScroll } from '../../utils/scrollEventUtils'
import { formStyles } from '../styles/applicationPageSharedStyles'
import { regularPages } from '../FormPages/pages'

const CompanyOwnerForm = ({ ownerIndex, readOnly, setSelectedOwner }) => {
  const makeTestId = useMakeTestId(`owners-form-`)
  const { formIsValid, getFormValue, resetForm, setFormValue } = useContext(FormContext)
  const { data } = useQuery(GetCountryCodes)
  const { appId } = useParams()
  const usStatesOptions = getUSStates()
  const countryOptions = countriesMapper(data?.countryCodes)
  const { loading, updateCompanyOwners } = useUpdateCompanyOwners()
  const currentOwnerPrefix = `owner${ownerIndex}_`
  const isAuthorizedSigner = getFormValue(currentOwnerPrefix + 'isAuthorizedSigner')
  const shouldRenderReadOnly = readOnly || isAuthorizedSigner
  const isUsingPassport = !!getFormValue(currentOwnerPrefix + '_passportToggle')
  const [isSaving, setIsSaving] = useState(false)
  const actionButtonsBottomRef = useRef(null)
  const formBottomRef = useRef(null)
  const correctionSection = regularPages().find((page) => page.name === 'companyOwners').correctionSection
  const requestingCorrection = getFormValue('status') === CreditApplicationStatus.CORRECTION_REQUESTED
  const shouldAutoScroll = getShouldAutoScroll()
  const { remainingCorrections, removeFromRemainingCorrections } = useRemainingCorrections(
    appId,
    getFormValue('sectionsToCorrect'),
    requestingCorrection,
  )
  useEffect(() => {
    if (isSaving) {
      setIsSaving(false)
      updateCompanyOwners().then((res) => {
        if (requestingCorrection && remainingCorrections.includes(correctionSection)) {
          removeFromRemainingCorrections(correctionSection)
        }

        if (res?.data) {
          setSelectedOwner(null)
          logInfo({
            attributes: {
              action: 'Success',
              message: `Credit App ID: ${appId}`,
            },
            eventName: 'UpdateCompanyOwners',
          })
        }
      })
    }
  }, [
    isSaving,
    updateCompanyOwners,
    setSelectedOwner,
    appId,
    remainingCorrections,
    removeFromRemainingCorrections,
    requestingCorrection,
    correctionSection,
  ])

  const toggleSsn = () => {
    setFormValue(currentOwnerPrefix + '_passportToggle', !isUsingPassport)
    if (isUsingPassport) {
      setFormValue(currentOwnerPrefix + 'passportNumber', '')
      setFormValue(currentOwnerPrefix + 'passportNumberDisplay', '')
      setFormValue(currentOwnerPrefix + 'passportCountry', '')
      setFormValue(currentOwnerPrefix + 'passportExpiration', '')
    } else {
      setFormValue(currentOwnerPrefix + 'ssnDisplay', '')
      setFormValue(currentOwnerPrefix + 'ssn', '')
    }
  }

  const handleOwnerSave = (_) => {
    setFormValue(currentOwnerPrefix + 'isCreated', true)
    setIsSaving(true)
  }

  const handleOwnerCancelEdit = (_) => {
    resetForm()
    setSelectedOwner(null)
  }

  useEffect(() => {
    shouldAutoScroll &&
      actionButtonsBottomRef?.current?.scrollIntoView &&
      actionButtonsBottomRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [actionButtonsBottomRef, shouldAutoScroll])

  useEffect(() => {
    shouldAutoScroll &&
      isUsingPassport &&
      actionButtonsBottomRef?.current?.scrollIntoView &&
      actionButtonsBottomRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [formBottomRef, isUsingPassport, shouldAutoScroll])

  const styleOverrides = shouldRenderReadOnly ? formStyles({ isReview: true, readOnly: true }) : undefined

  return (
    <div
      className='company-owner-form-wrapper'
      css={styleOverrides}
    >
      <div className='company-owners-read-only owner-form-field-wrapper'>
        <div className='field-row fs-unmask company-owners-read-only'>
          <div className='field-container company-owners-read-only'>
            <FormElementWrapper>
              <FormTextInput
                autoComplete='none'
                className='fs-mask'
                dataTestId={makeTestId('firstName')}
                formFieldClassName='authorized-signer-first-name'
                label={
                  <FormattedMessage
                    defaultMessage='First name'
                    id='sputnik.CompanyOwnerForm__pONqz8'
                  />
                }
                maxLength={30}
                name={`${currentOwnerPrefix}firstName`}
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='First name'
                    id='sputnik.CompanyOwnerForm__pONqz8'
                  />
                }
                readOnly={shouldRenderReadOnly}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container company-owners-read-only'>
            <FormElementWrapper>
              <FormTextInput
                autoComplete='none'
                className='fs-mask'
                dataTestId={makeTestId('lastName')}
                formFieldClassName='authorized-signer-last-name'
                label={
                  <FormattedMessage
                    defaultMessage='Last name'
                    id='sputnik.CompanyOwnerForm__txUL0F'
                  />
                }
                maxLength={30}
                name={`${currentOwnerPrefix}lastName`}
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Last name'
                    id='sputnik.CompanyOwnerForm__txUL0F'
                  />
                }
                readOnly={shouldRenderReadOnly}
              />
            </FormElementWrapper>
          </div>
        </div>
        <AddressFormFields
          allowNonUsAddress
          companyOwnersClass='company-owners-read-only'
          countryOptions={countryOptions}
          label={
            <FormattedMessage
              defaultMessage='Physical address'
              id='sputnik.CompanyOwnerForm__7+DRh0'
            />
          }
          labelLine2={
            <FormattedMessage
              defaultMessage='Physical address line 2 '
              id='sputnik.CompanyOwnerForm__eR7Y3K'
            />
          }
          pageName={`${currentOwnerPrefix}address`}
          placeholder={
            <FormattedMessage
              defaultMessage='Enter street address'
              id='sputnik.CompanyOwnerForm__gU60u1'
            />
          }
          readOnly={shouldRenderReadOnly}
          stateOptions={usStatesOptions}
        />
        <div className='field-row company-owners-read-only'>
          <div className='field-container company-owners-read-only'>
            <PhoneNumberInput
              inputId={`${currentOwnerPrefix}phoneNumber`}
              label={
                <FormattedMessage
                  defaultMessage='Mobile phone number'
                  id='sputnik.CompanyOwnerForm__L0ijz8'
                />
              }
              readOnly={shouldRenderReadOnly}
            />
          </div>
          <div className='field-container fs-unmask company-owners-read-only'>
            <FormEmailInput
              autoComplete='on'
              className='authorized-signer-email fs-mask'
              dataTestId='authorized-signer-form-workEmail'
              label={
                <FormattedMessage
                  defaultMessage='Work email'
                  id='sputnik.CompanyOwnerForm__HHRIuR'
                />
              }
              name={`${currentOwnerPrefix}email`}
              placeholder={
                <FormattedMessage
                  defaultMessage='Work email'
                  id='sputnik.CompanyOwnerForm__HHRIuR'
                />
              }
              readOnly={shouldRenderReadOnly}
              type='email'
            />
          </div>
        </div>
        <div className='field-row'>
          <div className='field-container fs-unmask'>
            <MaskedFormInput
              isDOB
              className='company-owner-dob fs-mask'
              dataTestId={`${currentOwnerPrefix}dob`}
              inputMode='numeric'
              label={
                <FormattedMessage
                  defaultMessage='Date of birth'
                  id='sputnik.CompanyOwnerForm__GFTdXw'
                />
              }
              name={`${currentOwnerPrefix}dobDisplay`}
              pathName='beneficialOwners'
              placeholder={
                <FormattedMessage
                  defaultMessage='Date of birth'
                  id='sputnik.CompanyOwnerForm__GFTdXw'
                />
              }
              readOnly={shouldRenderReadOnly}
            />
          </div>
          <div className='field-container fs-unmask company-owners-read-only'>
            {!isUsingPassport && (
              <MaskedFormInput
                className='authorized-signer-ssn fs-mask'
                dataTestId='authorized-signer-form-ssn'
                inputMode='numeric'
                label={
                  <FormattedMessage
                    defaultMessage='Social security number'
                    id='sputnik.CompanyOwnerForm__GQ9iTd'
                  />
                }
                maxLength={11}
                name={`${currentOwnerPrefix}ssnDisplay`}
                pathName='beneficialOwners'
                placeholder='Social security number'
                readOnly={shouldRenderReadOnly}
              />
            )}
            {shouldRenderReadOnly || (
              <IdTypeToggle
                isUsingPassport={isUsingPassport}
                ownerPrefix={currentOwnerPrefix}
                toggleSsn={toggleSsn}
              />
            )}
          </div>
        </div>
        {isUsingPassport && (
          <PassportInfo
            companyOwnersClass='company-owners-read-only'
            dataTestId='authorized-signer-form'
            namePrefix={currentOwnerPrefix}
            pathName='beneficialOwners'
            readOnly={shouldRenderReadOnly}
          />
        )}
        {readOnly || (
          <div
            className='fs-unmask'
            css={ownerSaveButtonContainerStyle}
          >
            <BasicButton
              dataTestId='cancel-edit-owner'
              disabled={isSaving || loading}
              type={BASIC_BUTTON_TYPE_FLAT}
              onClick={handleOwnerCancelEdit}
            >
              {shouldRenderReadOnly ? (
                <FormattedMessage
                  defaultMessage='Go back'
                  id='sputnik.CompanyOwnerForm__orvpWh'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Cancel'
                  id='sputnik.CompanyOwnerForm__47FYwb'
                />
              )}
            </BasicButton>
            {shouldRenderReadOnly || (
              <BasicButton
                dataTestId='save-owner'
                disabled={!formIsValid || isSaving || loading}
                showSpinner={isSaving || loading}
                type={BASIC_BUTTON_TYPE_OUTLINED}
                onClick={handleOwnerSave}
              >
                <FormattedMessage
                  defaultMessage='Save'
                  id='sputnik.CompanyOwnerForm__jvo0vs'
                />
              </BasicButton>
            )}
            <div ref={actionButtonsBottomRef} />
          </div>
        )}
      </div>
      <div ref={formBottomRef} />
    </div>
  )
}

CompanyOwnerForm.defaultProps = {
  readOnly: false,
}

CompanyOwnerForm.propTypes = {
  ownerIndex: number,
  readOnly: bool,
  setSelectedOwner: func,
}

const IdTypeToggle = ({ isUsingPassport, ownerPrefix, toggleSsn }) => (
  <FormCheckboxInput
    isSmall
    autoComplete='none'
    checkboxLabel={
      <FormattedMessage
        defaultMessage='Non US citizen and does not have SSN.'
        id='sputnik.CompanyOwnerForm__h5FGaJ'
      />
    }
    checked={isUsingPassport}
    className='passport-toggle'
    dataTestId='authorized-signer-form-passportToggle'
    name={`${ownerPrefix}_passportToggle`}
    onChange={toggleSsn}
  />
)

IdTypeToggle.propTypes = {
  isUsingPassport: bool,
  ownerPrefix: string.isRequired,
  toggleSsn: func.isRequired,
}

const ownerSaveButtonContainerStyle = css`
  display: flex;
  gap: var(--tri-space-700);
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: var(--tri-space-600);
`

export default CompanyOwnerForm
