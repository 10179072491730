import { matchEmail } from '../../utils/dataUtils'
import {
  cityValidation,
  dobValidation,
  emailValidation,
  illegalCharactersForHumanNameMessage,
  legalCharactersForHumanNamePattern,
  passportCountryValidation,
  passportExpirationValidation,
  passportNumberValidation,
  phoneNumberValidation,
  postalCodeWithForeignAddressValidation,
  ssnValidation,
  stateValidation,
  streetValidation,
} from '../../utils/validationUtils'

const authSignerEmailValidation = (value, allAttributes) => {
  const isOwnerDuplicate = ['owner0', 'owner1', 'owner2', 'owner3']
    .filter((k) => !allAttributes?.[k + '_isAuthorizedSigner'])
    .some((k) => !!value && matchEmail(allAttributes?.[k + '_email'], value))
  if (isOwnerDuplicate) {
    return { format: { message: 'Same email is associated with a company owner.', pattern: '.' } }
  }

  return emailValidation(value)
}

const preferredFullNameValidation = (attributes) => {
  const authFirstName = attributes['firstName']
  const authLastName = attributes['lastName']
  const authNameLength = authFirstName?.length + authLastName?.length
  if (authNameLength > 21) {
    return {
      format: {
        message: illegalCharactersForHumanNameMessage,
        pattern: legalCharactersForHumanNamePattern,
      },
      length: {
        maximum: 21,
        message: 'Full name may not have more than 21 characters',
      },
      presence: {
        message: 'Please enter name for the admin',
      },
    }
  } else {
    return {}
  }
}

export default {
  addressCity: cityValidation(),
  addressCountryCode: {
    presence: {
      message: 'Please select a country',
    },
  },
  addressPostalCode: postalCodeWithForeignAddressValidation(),
  addressState: stateValidation(),
  addressStreet: streetValidation(),
  addressStreetAdditional: streetValidation(false),
  dobDisplay: (_, attributes) => dobValidation(attributes),
  email: authSignerEmailValidation,
  firstName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'First name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a first name',
    },
  },
  lastName: {
    format: {
      message: illegalCharactersForHumanNameMessage,
      pattern: legalCharactersForHumanNamePattern,
    },
    length: {
      maximum: 30,
      message: 'Last name may not have more than 30 characters',
    },
    presence: {
      message: 'Please enter a last name',
    },
  },
  passportCountry: (_, attributes) => passportCountryValidation(attributes),
  passportExpiration: (_, attributes) => passportExpirationValidation(attributes),
  passportNumberDisplay: (_, attributes) => passportNumberValidation(attributes),
  phoneNumber: phoneNumberValidation,
  preferredFullName: (_, attributes) => preferredFullNameValidation(attributes),
  ssnDisplay: (_, attributes) => ssnValidation(attributes),
  title: {
    format: {
      message: 'Please enter a valid title',
      pattern: "^[a-zA-Z.,' /:&-\\d\\(\\)\\\\]*$",
    },
    presence: {
      message: 'Please enter a title',
    },
  },
}
