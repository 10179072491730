import { isValidPhoneNumber } from 'libphonenumber-js'
import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { getUSStates } from '../components/utils'
import { validateSsn } from './ssnValidation'
import { validateTaxId } from './taxIdValidation'

const routingNumberRegex = /^[0-9]*$/
const noEmailAllowedRegex = '^[^@]*$'

const getRequiredValidation = (validationMessage = '') => {
  return {
    presence: {
      message: validationMessage,
    },
  }
}

export const prependPrefix = (key, prefix = '') => {
  if (!key) return ''
  if (prefix && !prefix.endsWith('_')) return prefix + key[0].toUpperCase() + key.slice(1)
  return prefix + key
}

export const subtractYears = (date, years) => {
  return new Date(date.setFullYear(date.getFullYear() - years))
}

// prevents fraudsters from injecting links into
// mail that gets sent to customers...
export const legalCharactersForBusinessNamePattern = "^[a-zA-Z0-9-/&,.' ]*$"
export const illegalCharactersForBusinessNameMessage = "Only the following special characters are allowed: , . / & - '"

// this reflects the restrictions placed on us by Wex and Marqeta (with Perfect Plastic)
export const legalCharactersForPreferredNamePattern = '^[a-zA-Z0-9-/&,. ]*$'
export const illegalCharactersForPreferredNameMessage = 'Only the following special characters are allowed: , . / & -'

/**
 * @name emailValidation
 * @param {string} value The value entered into the input field
 * @return {Object} Validation rules object
 */
export const emailValidation = (value) => {
  if (value?.endsWith('.con')) {
    const possiblyCorrectedEmail = value.slice(0, -1) + 'm'
    return {
      type: {
        message: `Invalid email. Did you mean ${possiblyCorrectedEmail}?`,
        type: () => false,
      },
    }
  }

  const validationMessage = 'Please enter a valid email'

  return {
    ...getRequiredValidation(validationMessage),
    email: {
      message: validationMessage,
    },
  }
}

export const dobValidation = (attributes, prefix = '') => {
  const dobUnmaskedPattern = '^[0-9]{2}/[0-9]{2}/[0-9]{4}]$'
  const currentDobEpoch = attributes?.[prefix + 'dob']
  const latestAllowedDob = subtractYears(new Date(), 18)

  // Since the date cannot be parsed correctly when it includes '*',
  // need to force validation to have an error if DOB is not over 18 years old
  if (currentDobEpoch && currentDobEpoch > latestAllowedDob.getTime()) {
    return {
      format: {
        message: 'Must be over 18 years old',
        pattern: dobUnmaskedPattern,
      },
    }
  }

  return {
    length: {
      is: 10,
      message: 'Please enter a valid date of birth',
    },
    presence: {
      message: 'Please enter a valid date of birth',
    },
  }
}

export const ssnValidation = (attributes, prefix = '') => {
  const passportToggle = attributes?.[prefix + '_passportToggle']
  if (!passportToggle) {
    const ssn = attributes?.[prefix + 'ssn']
    const ssnDisplay = attributes?.[prefix + 'ssnDisplay']
    return validateSsn(ssn, ssnDisplay)
  }
}

export const legalCharactersForHumanNamePattern = '^[a-zA-Z ]*$'
export const illegalCharactersForHumanNameMessage = 'Special characters are not allowed'

export const passportNumberValidation = (attributes, prefix = '') => {
  const pattern = '^[A-Za-z0-9*]*[0-9][A-Za-z0-9*]*$'
  const passportToggle = attributes?.[prefix + '_passportToggle']
  const validationMessage = 'Please enter a valid passport number'

  if (passportToggle) {
    return {
      format: {
        message: validationMessage,
        pattern,
      },
      length: {
        message: validationMessage,
        minimum: 4,
      },
    }
  }
}

export const passportCountryValidation = (attributes, prefix = '') => {
  const passportToggle = attributes?.[prefix + '_passportToggle']
  if (passportToggle) {
    const validationMessage = 'Please select a country'
    return getRequiredValidation(validationMessage)
  }
}

export const passportExpirationValidation = (attributes, prefix = '') => {
  const passportToggle = attributes?.[prefix + '_passportToggle']
  if (passportToggle) {
    const validationMessage = 'Enter an active passport'
    return {
      ...getRequiredValidation(validationMessage),
      datetime: {
        earliest: new Date(),
        message: validationMessage,
      },
    }
  }
}

const US_STATES = getUSStates()
const US_STATE_CODES = US_STATES.map((state) => state.value)

export const stateValidation =
  ({ prefix = '' } = {}) =>
  (_, attributes) => {
    if (attributes?.[prefix + 'addressCountryCode'] === 'US') {
      return {
        ...getRequiredValidation('Please select a state'),
        type: {
          message: 'Please select a state',
          type: (value) => !!value && US_STATE_CODES.includes(value),
        },
      }
    }

    return getRequiredValidation('Please enter a state or province')
  }

export const cityValidation = (isRequired = true) => {
  return {
    ...(isRequired && getRequiredValidation('Please enter a city')),
    length: {
      maximum: 39,
      tooLong: 'City name is too long',
    },
  }
}

export const streetValidation = (isRequired = true, rejectPoBox = true) => {
  return {
    ...(isRequired && getRequiredValidation('Please enter a valid street')),
    format: {
      message: 'Address cannot be an email',
      pattern: noEmailAllowedRegex,
    },
    length: {
      maximum: 99,
      tooLong: 'Address is too long',
    },
    street: {
      isPoBox: 'Address cannot be a PO Box',
      rejectPoBox: rejectPoBox,
    },
  }
}

/* Validation for businessInfoForm that excludes foreign address validation because business has to be US based */
export const postalCodeValidation =
  ({ prefix = '' } = {}) =>
  (_, attributes) => {
    const validationMessage = (
      <FormattedMessage
        defaultMessage='Please enter a valid zip code'
        id='sputnik.validationUtils__X0CRoi'
      />
    )

    return {
      ...getRequiredValidation(validationMessage),
      format: {
        message: validationMessage,
        pattern: '^\\d{5}(?:[-/—]\\d{4})?$',
      },
      length: {
        minimum: 5,
        tooShort: (
          <FormattedMessage
            defaultMessage='Zip code must be at least 5 digits'
            id='sputnik.validationUtils__hQ2cpM'
          />
        ),
      },
    }
  }

/* Validation for authorizedSigner and companyOwner forms that includes foreign address validation */
export const postalCodeWithForeignAddressValidation =
  ({ prefix = '' } = {}) =>
  (_, attributes) => {
    const countryCode = attributes?.[prependPrefix('addressCountryCode', prefix)]
    const isForeignAddress = countryCode !== 'US'

    if (!isForeignAddress) {
      return postalCodeValidation({ prefix })(_, attributes)
    }

    const validationMessage = (
      <FormattedMessage
        defaultMessage='Please enter a valid postal code'
        id='sputnik.validationUtils__EJ2K7'
      />
    )

    return {
      ...getRequiredValidation(validationMessage),
      format: {
        message: validationMessage,
        pattern: '^[\\dA-Za-z -]*$',
      },
      length: {
        minimum: 5,
        tooShort: (
          <FormattedMessage
            defaultMessage='Postal code must be at least 5 characters'
            id='sputnik.validationUtils__+ucGb4'
          />
        ),
      },
    }
  }

export const websiteValidation = () => {
  const formatValidationMessage = 'Please enter a valid website'

  const formatValidation = {
    // ValidateJS library's built-in check for URLs do not allow omitting the protocol so we need to use our own pattern
    format: {
      flags: 'imu',
      message: formatValidationMessage,
      pattern:
        '^((((http|https):\\/{2})*(([0-9a-z_-]+\\.)+([a-zA-Z]{2,})((\\/([~0-9a-zA-Z#\\+%@\\.\\/_-]+))?)?))\\b)?\\/?$',
    },
  }

  return formatValidation
}

export const businessInfoTaxIdValidation = (attributes = {}) => {
  const isSoleProprietorship = attributes?.entityType === 'SOLE_PROPRIETORSHIP'
  const { taxId, taxIdDisplay } = attributes
  return validateTaxId(taxId, taxIdDisplay, isSoleProprietorship)
}

export const routingNumberValidation =
  (isRequired = true) =>
  (value, attributes) => {
    const validationMessage = 'Please enter a valid routing number'
    const duplicateValidationMessage = 'Routing number cannot be the same as account number'

    const routingAccountMatchValidation =
      value && value === attributes?.accountNumber
        ? {
            type: {
              message: duplicateValidationMessage,
              type: () => false,
            },
          }
        : {}

    if (!isRequired) {
      return {
        ...routingAccountMatchValidation,
        format: {
          message: validationMessage,
          pattern: routingNumberRegex,
        },
        length: {
          is: 9,
          wrongLength: validationMessage,
        },
      }
    }

    return {
      ...getRequiredValidation(validationMessage),
      ...routingAccountMatchValidation,
      format: {
        message: validationMessage,
        pattern: routingNumberRegex,
      },
      length: {
        is: 9,
        wrongLength: validationMessage,
      },
    }
  }

export const accountNumberValidation = () => (value, attributes) => {
  const duplicateValidationMessage = 'Account number cannot be the same as routing number'

  const routingAccountMatchValidation =
    attributes?.accountNumber === attributes?.routingNumber
      ? {
          type: {
            message: duplicateValidationMessage,
            type: () => false,
          },
        }
      : {}

  return {
    ...routingAccountMatchValidation,
    length: {
      message: 'Account number should be at least 4 digits long',
      minimum: 4,
    },
  }
}

const phoneNumberErrorMessage = 'Please enter a valid phone number'

export const phoneNumberValidation = {
  presence: {
    message: phoneNumberErrorMessage,
  },
  type: {
    message: phoneNumberErrorMessage,
    type: (val) => isValidPhoneNumber(val || ''),
  },
}
