import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import React, { useContext } from 'react'
import { FormCheckboxInput, FormContext } from '@divvy-web/skylab.form'
import TextLink, { TEXT_LINK_REGULAR } from '@divvy-web/skylab.textlink'
// import { URL } from '../../../resources/constants'

const FCRATerms = () => {
  const [getClassName, makeTestId] = useNameFormatter('FCRATerms')
  const { formValues, setFormValue } = useContext(FormContext) as any
  const { creditReportConsent } = formValues as { creditReportConsent: boolean }
  //COM-7942: implement new link for credit reporting consent
  // const { CREDIT_REPORT_CONSENT } = URL
  const handleTermsClick = (e) => {
    setFormValue('creditReportConsent', !!e?.target?.checked)
  }

  return (
    <div css={termsCss}>
      <FormCheckboxInput
        checkboxLabel={
          <FormattedMessage
            defaultMessage='I have read and agree to the following:'
            id='sputnik.FCRATerms__RNICJi'
          />
        }
        checked={!!creditReportConsent}
        control={null}
        dataTestId={makeTestId('checkbox')}
        formDataTestId={null}
        name='creditReportConsent'
        value={creditReportConsent}
        onChange={handleTermsClick}
      />
      <div className={getClassName('list')}>
        <ul>
          <li>
            <TextLink
              className={getClassName('credit-reporting-link')}
              dataTestId={makeTestId('credit-reporting-link')}
              disabled={false}
              // COM-7942: get real href
              href={'www.divvy.co/credit-reporting-consent'}
              isDark={false}
              size={TEXT_LINK_REGULAR}
              target='_blank'
              useDataTestId={makeTestId('credit-reporting-link')}
              onClick={() => {}}
            >
              <FormattedMessage
                defaultMessage='Credit reporting consent'
                id='sputnik.FCRATerms__b1sY5l'
              />
            </TextLink>
          </li>
          <li>
            <FormattedMessage
              defaultMessage='Authorization to Obtain Credit Report. You authorize us to obtain a credit report from one or more credit
            reporting agencies. We may use your credit report for authentication, to make credit decisions, and for
            relation purpose.'
              id='sputnik.FCRATerms__aVmjRN'
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage='I certify to the best of my knowledge that the information provided above is complete and correct.'
              id='sputnik.FCRATerms__kfpX/1'
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

const termsCss = ({ type }) => css`
  max-width: 100%;
  .FCRATerms-list {
    ul {
      margin-left: var(--tri-space-650);
    }
  }
  ${type.triFontDesktopBodySmall}
  color: var(--tri-color-text-primary)
`
export default FCRATerms
