import { css } from '@emotion/core'

export const offerTileCss = ({ mq, type }) => css`
  .OfferTile {
    &-content-hasClick {
      height: 546px;
      max-width: 385px;
      min-width: 342px;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 0 var(--tri-space-350) 0;

      ${mq.xSmallMaxWidth(css`
        height: var(--tri-space-1600);
        width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 var(--tri-space-100) 0;
      `)}
    }

    &-image-and-text-wrapper {
      height: 100%;

      ${mq.xSmallMaxWidth(css`
        display: flex;
        align-items: center;
        text-align: left;
      `)}
    }

    &-text-wrapper {
      ${mq.xSmallMaxWidth(css`
        flex-direction: column;
      `)}
    }

    &-image {
      max-width: var(--tri-space-1900);

      ${mq.xSmallMaxWidth(css`
        max-width: var(--tri-space-800);
      `)}
    }

    &-desktop-title {
      ${type.triFontDesktopTitleMediumEmphasis}
      padding-top: var(--tri-space-350);

      ${mq.xSmallMaxWidth(css`
        display: none;
      `)}
    }

    &-offer-tile-approved-amount {
      ${type.triFontDesktopTitleMediumEmphasis};
      padding-top: var(--tri-space-250);

      ${mq.xSmallMaxWidth(type.triFontDesktopTitleSmallEmphasis)}
      ${mq.xSmallMaxWidth(css`
        padding-top: var(--tri-space-250);
      `)}
    }

    &-mobile-title {
      ${type.triFontDesktopBodySmall}
      color: var(--tri-color-text-tertiary);

      ${mq.xSmallMinWidth(css`
        display: none;
      `)}
    }

    &-top-caption {
      text-align: left;
      padding: var(--tri-space-350) 0 0;

      ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)}
      ${mq.xSmallMaxWidth(css`
        padding: unset;
      `)}
    }

    &-bottom-caption {
      text-align: left;
      padding-top: var(--tri-space-250);
    }

    &-footer {
      margin-bottom: var(--tri-space-700);
      ${mq.xSmallMaxWidth(css`
        margin: unset;
      `)}
    }

    &-review-offer-button {
      ${mq.xSmallMaxWidth(css`
        display: none;
      `)}
    }
  }
`
